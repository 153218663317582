import React from 'react';
import { useState } from 'react';
import Button from '../Button/Button';
import TextInput from '../TextInput/TextInput';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNewEmail } from '../../../functions/setNewEmail';
import { globalTranslate } from '../../../functions/languages';

const ResetEmail = () => {
  const [isLoad, setIsLoad] = useState(false);
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [resetData, setResetData] = useState({
    current_password: "",
    new_email: '',
  });

  const currentLang = useSelector(state => state.lang.currentLang)

  const user = useSelector(state => state.user)
  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (success) {
        setTimeout(() => {
            if (user.is_manager) {
                navigate('/cabinet/my_profile/')
            } else {
                navigate('/cabinet/my_registrations/')
            }
        }, 5000);
    }

  }, [success])

  const setOldPassword = (data) => setResetData({...resetData, current_password: data})
  const setNewEmailAdres = (data) => setResetData({...resetData, new_email: data})

  const resetPass = () => {
    setIsLoad(true)
    setError();
    setNewEmail({current_password: resetData.current_password, new_email: resetData.new_email}, setIsLoad, setError, setResetData, setSuccess, dispatch)
  }

  return (
    <div style={isLoad ? {opacity: 0.3} : {}}>
        
        <h3>{success ? success : globalTranslate(currentLang, 'Изменить электронную почту')}</h3>
        {success && <p>{globalTranslate(currentLang, "Мы вернем вас в личный кабинет через 5 секунд...")}</p>}
        {error && error.map((item, index) => <p key={index} style={{color: 'red'}}>{globalTranslate(currentLang, item)}</p>)}
        {!success && (
            <>
            <p>{globalTranslate(currentLang, "Ваш текущий адрес")}: {user.email}</p>
        <TextInput labelText={'Введите новый адрес электронной почты'} onChange={setNewEmailAdres} />
          <TextInput type={'isPass'} labelText={'Введите пароль'} onChange={setOldPassword} />

        <div style={{marginBottom: 40}}>
            <Button disabled={isLoad} onClick={resetPass}>{isLoad ? globalTranslate(currentLang, 'Изменяем...') : globalTranslate(currentLang, 'Изменить почту')}</Button>
        </div>
            </>
        )}
    </div>
  )
}

export default ResetEmail;
