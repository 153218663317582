import React, { useState, useEffect } from "react";

import styles from "./../RegPage/RegPage.module.scss";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../elements/Button/Button";
import TextInput from "../../elements/TextInput/TextInput";
import Box from "../../elements/Box/Box";
import { useDispatch, useSelector } from 'react-redux';
import login from '../../../functions/login';
import { getCompanyRegistrations } from '../../../functions/getCompanyRegistrations';
import { setErrorAction } from "../../../redux/Reducers/errorsReducer";
import { globalTranslate } from "../../../functions/languages";

const LoginQuestion = () => {

  const currentLang = useSelector(state => state.lang.currentLang)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const error = useSelector(state => state.error.error_text);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOpenRegistration, setOpenRegistration] = useState(false)

  const typeOfRegistration = useSelector(state => state.registrationType.types)

  useEffect(() => {
    if (!typeOfRegistration) return;
    for (let key in typeOfRegistration) {
      if ((key === 'reg_allow_group' || key === 'reg_allow_person') && typeOfRegistration[key]) setOpenRegistration(true)
    }
  }, [typeOfRegistration])

  const emailChangeHandler = (value) => setEmail(value);
  const passwordChangeHandler = (value) => setPassword(value);

  const isAuth = useSelector(state => state.isAuth)

  const makeLogin = () => {
    dispatch(setErrorAction(''))
    dispatch(login({email, password}, navigate))
  }

  useEffect(() => {
    if (!isAuth) return;
    getCompanyRegistrations(dispatch);
  }, [isAuth])

  return (
    <div className={styles.regModal}>
      <h3>{globalTranslate(currentLang, 'Вход')}</h3>
      {error && <h5>{error}</h5> }
      <Box width={'100%'}>
        <TextInput labelText={"Электронная почта"} onChange={emailChangeHandler} />
      </Box>
      <Box width={'100%'}>
        <TextInput
          labelText={"Введите пароль"}
          type="isPass"
          onChange={passwordChangeHandler}
        />
      </Box>
      <Box mt={60}>
        <Button onClick={makeLogin}>{globalTranslate(currentLang, 'Войти')}</Button>
        {isOpenRegistration && <p className={styles.redirectQuestion}>{globalTranslate(currentLang, 'Еще не зарегистрированы?')} <Link to='/registration'>{globalTranslate(currentLang, 'Зарегистрироваться')}</Link> </p>}
        {/* <p className={styles.redirectQuestion}>Еще не зарегистрированы? <Link to='/registration'>Зарегистрироваться</Link> </p> */}
        <p className={styles.redirectQuestion}>{globalTranslate(currentLang, 'Забыли пароль?')} <Link to="/reset_password">{globalTranslate(currentLang, 'Восстановить')}</Link></p>
      </Box>
    </div>
  );
};

export default LoginQuestion;
