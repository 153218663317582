import { BASE_URL_API } from '../BASE_URL.js';
import { getRegDocsAction } from '../redux/Reducers/otherDocsReducer.js';

const getRegDocs = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'base/registration/');
        const data = await response.json();

        dispatch(getRegDocsAction(data));
    }
}

export default getRegDocs;