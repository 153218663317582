import React, { useState } from "react";
import Container from "../Container/Container";
import PageTemplate from "../../PageTemplate/PageTemplate";
import CabinetNav from "../CabinetNav/CabinetNav";
import ResetPassword from "../ResetPassword/ResetPassword";
import ResetEmail from "../ResetEmail/ResetEmail";
import { useSelector } from 'react-redux';
import { globalTranslate } from "../../../functions/languages";


const CabinetProfileSettings = () => {
  const currentLang = useSelector(state => state.lang.currentLang)
  

  return (
    <PageTemplate>
      <Container>
        <CabinetNav />
        <h3>{globalTranslate(currentLang, "Настройки")}</h3>
        <ResetEmail />
        <ResetPassword />
      </Container>
    </PageTemplate>
  );
};

export default CabinetProfileSettings;
