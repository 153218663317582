import React from "react";
import PageTemplate from "../../PageTemplate/PageTemplate";
import Container from "../../elements/Container/Container";
import RegForm from "./RegForm";

const RegPage = () => {
  return (
    <PageTemplate isMainPage={false}>
      <Container>
        <RegForm />
      </Container>
    </PageTemplate>
  );
};

export default RegPage;
