import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactCrop from "react-image-crop";
import AvatarEditor from "react-avatar-editor";
import UserAvatar from "../../../image/icons/user.png";

import styles from "./UploadInput.module.scss";
import "react-image-crop/src/ReactCrop.scss";
import Button from "../Button/Button";
import Box from "../Box/Box";

import loading from "../../../image/icons/load.gif";
import edit from "../../../image/icons/edit.svg";

import deleteIcon from "../../../image/icons/delete.svg";

import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { BASE_URL } from "../../../BASE_URL";
import { globalTranslate } from "../../../functions/languages";

const UploadInput = ({ onChange, isValue, errorValidate, isDisabled }) => {
  const [file, setFile] = useState("");
  const [previewImageSrc, setPreviewImageSrc] = useState("");
  const [scalePhoto, setScalePhoto] = useState(1);
  const [scalePhotoInPercent, setScalePhotoInPercent] = useState(0);
  const [isShowModal, setIsShowModal] = useState(false);
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);
  const [zoomTwice, setZoomTwice] = useState(false)

  const currentLang = useSelector(state => state.lang.currentLang)

  const previewPhoto = (file) => {
    if (!file) return;
    setLoad(true);
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setPreviewImageSrc(reader.result);
      setLoad(false);
    };
  };

  useEffect(() => {
    if (Object.keys(isValue).length < 1) {
      setFile();
    } else {
      setPreviewImageSrc(BASE_URL + isValue)
    }
  }, [isValue])

  const changeScale = (e) => {
    const value = e.target.valueAsNumber;
    setScalePhotoInPercent(value);
    setScalePhoto(1 + value / 100);
  };

  // const zoomTwiceHandler = (e) => {
  //   setZoomTwice(e.target.checked)
  //   changeScale({target: {valueAsNumber: zoomSlider.current.valueAsNumber}})
  // }

  const closeModal = () => {
    setIsShowModal(false);
    setError("");
    setScalePhotoInPercent(0);
    setScalePhoto(1);
  };

  const deletePhoto = (e) => {
    if (isDisabled) return;
    e.preventDefault();
    onChange({})
    setFile();
    uploadField.current.value = "";
    setPreviewImageSrc();
    closeModal()
    setZoomTwice(false)
  }

  const editPhoto = (e) => {
    e.preventDefault();
    setIsShowModal(true)
  }
  // const zoomSlider = useRef()
  const editorRef = useRef();
  const uploadField = useRef();

  const saveImage = async () => {
    const canvas = editorRef.current.getImage().toDataURL();
    fetch(canvas)
      .then((res) => res.blob())
      .then((blob) => {
        let canvasToFile = new File([blob], file.name, { type: file.type });
        if (canvasToFile.size > 10000000) {
          setError(
            "Размер файла больше 10 Mb. Попробуйте оптимизировать это изображение или выбрать другое"
          );
        } else if (canvasToFile.name.length >= 100) {
          setError(
            "Имя файла превышает 100 символов. Попробуйте переименовать изображение или выбрать другое"
          );
        } else {
          setPreviewImageSrc(window.URL.createObjectURL(blob));
          setError("");
          setFile(canvasToFile)
          onChange(canvasToFile);
        }
      });
  };

  useEffect(() => {
    if (!file) return;
    previewPhoto(file);
    if (!isShowModal) {
      setIsShowModal(true);
    } else {
      setIsShowModal(false);
      setError("");
      setScalePhotoInPercent(0);
      setScalePhoto(1);
    }
  }, [file]);

  return (
    <div className={styles.UploadInputWrapper}>
      <label className={styles.uploadLabel} htmlFor="avatar" style={errorValidate ? {backgroundColor: '#FFEDED'} : {}}>
        {previewImageSrc ? (
          load ? (
            <img src={loading} style={{width: 400, height: 'auto'}} />
          ) : (
            <>
                <div className={styles.preview} style={{backgroundImage: 'url(' + previewImageSrc + ')'}}></div>
                {/* <img className={styles.preview} src={previewImageSrc} alt="" /> */}
                <div className={styles.deleteBox} onClick={deletePhoto}>
                    <img src={deleteIcon} />
                </div>
                {/* <div className={styles.editBox} onClick={editPhoto}>
                    <img src={edit} />
                </div> */}
            </>
          )
        ) : (
          <>
            <img src={UserAvatar} alt="avatar" />
            <span>{globalTranslate(currentLang, 'Загрузить фото')}</span>
          </>
        )}
      </label>
      <input
        className={styles.avatar}
        type="file"
        name="uploadFoto"
        id="avatar"
        disabled={isDisabled}
        onClick={(e) => file && e.preventDefault()}
        onChange={(event) => setFile(event.target.files[0])}
        ref={uploadField}
      />
      <div className={styles.warnings}>
        <span>
          {globalTranslate(currentLang, 'Минимальная ширина: 1 000 px')} <br />
          {globalTranslate(currentLang, 'Максимальные размер: 10 Mb')} <br />
          {globalTranslate(currentLang, 'Максимальная длина названия: 100 символов')} <br />
          {globalTranslate(currentLang, 'Нажмите на фото, чтобы загрузить новое')} <br />
        </span>
        <span>
        {globalTranslate(currentLang, 'Пожалуйста загрузите фото, которое:')} <br />
        - {globalTranslate(currentLang, 'снято меньше года назад;')}
          <br />
          - {globalTranslate(currentLang, 'вертикальное;')}
          <br />
          - {globalTranslate(currentLang, 'фото лица занимает 70-80% площади снимка')}
          <br />
        </span>
      </div>

      {isShowModal && (
        <div className={styles.cropContainer} style={{overflowY: 'scroll'}}>
          <div className={styles.cropWrapper}>
            {load ? (
              <img src={loading} style={{maxWidth: 500, maxHeight: 400}} />
            ) : (
              <>
                <AvatarEditor
                  ref={editorRef}
                  image={previewImageSrc}
                  width={window.innerWidth < 1000 ? 192 : 350}
                  height={window.innerWidth < 1000 ? 250 : 450}
                  border={50}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={scalePhoto}
                  rotate={0}
                  setIsShowModal={true}
                />
                <div className={styles.cropOptions}>
                  <div className={styles.warnings}>
                    <span>
                    {globalTranslate(currentLang, 'Минимальная ширина: 1 000 px')}  <br />
                    {globalTranslate(currentLang, 'Максимальные размер: 10 Mb')} <br />
                    {globalTranslate(currentLang, 'Максимальная длина названия: 100 символов')} <br />
                    {globalTranslate(currentLang, 'Нажмите на фото, чтобы загрузить новое ')}<br />
                    </span>
                    <span>
                    {globalTranslate(currentLang, 'Пожалуйста загрузите фото, которое:')} <br />
                    - {globalTranslate(currentLang, 'снято меньше года назад;')}
                      <br />
                      - {globalTranslate(currentLang, 'вертикальное;')} 
                      <br />
                      - {globalTranslate(currentLang, 'фото лица занимает 70-80% площади снимка')} 
                      <br />
                    </span>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{globalTranslate(currentLang, 'Увеличение')}: {scalePhotoInPercent}%</span>
                    <input
                      type="range"
                      defaultValue={0}
                      onChange={(e) => changeScale(e)}
                    />
                    {/* <label htmlFor="zoomTwice">
                    <input type="checkbox" name="zoomTwice" id="zoomTwice" checked={zoomTwice} onChange={zoomTwiceHandler} />
                      Двукратное увеличение
                    </label> */}
                    {error && (
                      <div style={{ maxWidth: 318 }}>
                        <span className={styles.error}>{globalTranslate(currentLang, 'Ошибка')}: {error}</span>
                      </div>
                    )}
                  </div>
                  <div className={styles.btn_group}>
                    <Button backColor={'red'} onClick={deletePhoto}>{globalTranslate(currentLang, 'Удалить')}</Button>
                    <Button onClick={saveImage}>{globalTranslate(currentLang, 'Сохранить')}</Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadInput;
