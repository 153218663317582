import React from "react";

import styles from "./PageTemplate.module.scss";

import { ReactComponent as Logo } from "./../../image/2024/new-logo_color_mobile.svg";
import { ReactComponent as LogoEn } from "./../../image/2024/logo_en_color.svg";
import Container from "../elements/Container/Container";
import { useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import globe from "../../image/icons/globe_blue.png";
import ContentContainer from "../elements/Container/ContentContainer";
import { getCurrentLang, setNewLang } from "../../functions/languages";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setNewLangAction } from "../../redux/Reducers/langReducer";

const NonTransparentHeader = ({ getHeight }) => {
  const headerRef = useRef();
  const [currentLang, setCurrentLang] = useState();
  const [isMobile, setMobile] = useState(false);
  const dispatch = useDispatch()

  const setNewLangClickHandler = (lang) => {
    setNewLang(lang)
    setCurrentLang(lang)
    dispatch(setNewLangAction(lang))
  }

  const resize = () => {
    if (window.innerWidth > 1000) setMobile(false)
    if (window.innerWidth <= 1000) setMobile(true)
  }

  useEffect(() => {
    resize()
    window.addEventListener('resize', resize)

    return () => window.removeEventListener('resize', resize)
  }, [])

  const currentLangRedux = useSelector(state => state.lang.currentLang)

  useEffect(() => {
    setCurrentLang(currentLangRedux)
  }, [currentLangRedux])

  useEffect(() => {
    getHeight(headerRef.current.clientHeight);
  }, [headerRef]);

  

  return (
    <div className={styles.NonTransparentHeader} id={"header"} ref={headerRef}>
      <Container>
        <ContentContainer>
          <div className={styles.contentWrapp}>
            <Link to={"/main"}>
              {currentLang === 'ru' ? <Logo width={282} height={90} /> : <LogoEn width={282} height={90} /> }
              
            </Link>
            {!isMobile && 
              <div className={styles.chooseLang}>
                <span style={currentLang === 'ru' ? {color: '#047ec2'} : {color: ''}} onClick={() => setNewLangClickHandler('ru')}>РУС</span>
                <span style={currentLang === 'en' ? {color: '#047ec2'} : {color: ''}} onClick={() => setNewLangClickHandler('en')}>ENG</span>
                <img src={globe} alt="" />
              </div>
            }
          </div>
        </ContentContainer>
      </Container>
    </div>
  );
};

export default NonTransparentHeader;
