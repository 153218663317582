import { BASE_URL_API } from '../BASE_URL.js';
import { setProgramFilesAction } from '../redux/Reducers/programFilesReducer.js';

const getProgramFiles = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'programs/additional-files/');
        const data = await response.json();

        dispatch(setProgramFilesAction(data));
    }
}

export default getProgramFiles;