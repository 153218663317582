import React from 'react';
import { useState } from 'react';
import Button from '../Button/Button';
import TextInput from '../TextInput/TextInput';
import { setNewPassword } from '../../../functions/setNewPassword';
import { useEffect } from 'react';

import loadGif from '../../../image/icons/button_load.gif';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { globalTranslate } from '../../../functions/languages';

const ResetPassword = () => {
  const [isLoad, setIsLoad] = useState(false);
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [resetData, setResetData] = useState({
    current_password: "",
    new_password: '',
    confirmNewPass: "",
  });
  const currentLang = useSelector(state => state.lang.currentLang)


  const is_manager = useSelector(state => state.user.is_manager)
  const navigate = useNavigate()

  useEffect(() => {
    if (success) {
        setTimeout(() => {
            if (is_manager) {
                navigate('/cabinet/my_profile/')
            } else {
                navigate('/cabinet/my_registrations/')
            }
        }, 5000);
    }

  }, [success])

  const setOldPassword = (data) => setResetData({...resetData, current_password: data})
  const setNewPass = (data) => setResetData({...resetData, new_password: data})
  const confirmNewPassword = (data) => setResetData({...resetData, confirmNewPass: data})

  const resetPass = () => {
    if (resetData.new_password !== resetData.confirmNewPass) {
        setError(['Новый пароль не совпадает с полем подтверждения пароля'])
        return;
    }
    setIsLoad(true)
    setError();
    setNewPassword({current_password: resetData.current_password, new_password: resetData.new_password}, setIsLoad, setError, setResetData, setSuccess)
  }

  return (
    <div style={isLoad ? {opacity: 0.3} : {}}>
        <h3>{success ? success : globalTranslate(currentLang, 'Изменить пароль')}</h3>
        {success && <p>{globalTranslate(currentLang, "Мы вернем вас в личный кабинет через 5 секунд...")}</p>}
        {error && error.map((item, index) => <p key={index} style={{color: 'red'}}>{item}</p>)}
        {!success && (
            <>
              <TextInput labelText={'Старый пароль'} onChange={setOldPassword} />
              <TextInput labelText={'Новый пароль'} type={'isPass'} onChange={setNewPass} />
              <TextInput labelText={'Подтверждение нового пароля'} type={'isPass'} onChange={confirmNewPassword} />

        <div style={{marginBottom: 40}}>
            <Button disabled={isLoad} onClick={resetPass}>{isLoad ? globalTranslate(currentLang, 'Изменяем...') : globalTranslate(currentLang, 'Изменить пароль')}</Button>
        </div>
            </>
        )}
    </div>
  )
}

export default ResetPassword;
