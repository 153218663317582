import React from "react";
import Container from "../../elements/Container/Container";
import PageTemplate from "../../PageTemplate/PageTemplate";

const ContractPage = () => {
  return (
    <PageTemplate>
      <Container>
        <h3>Договор оферты</h3>
      </Container>
    </PageTemplate>
  );
};

export default ContractPage;
