const initialState = {
    reg_smi_text: '',
    reg_smi_text_en: '',
    reg_smi_policy: '',
    reg_smi_policy_en: '',
    privacy_policy: '',
    privacy_policy_en: '',
    mention_policy: '',
    mention_policy_en: '',
    participant_packages: '',
    participant_packages_en: '',
    offer_pdf: false,
  };
  
  const GET_REG_DOCUMENTS = 'GET_REG_DOCUMENTS';
  
  export const otherDocsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_REG_DOCUMENTS:
        return {
          reg_smi_text: action.payload.reg_smi_text,
          reg_smi_text_en: action.payload.reg_smi_text_en,

          reg_smi_policy: action.payload.reg_smi_policy,
          reg_smi_policy_en: action.payload.reg_smi_policy_en,

          privacy_policy: action.payload.privacy_policy,
          privacy_policy_en: action.payload.privacy_policy_en,
          
          mention_policy: action.payload.mention_policy_en,
          mention_policy_en: action.payload.mention_policy,

          participant_packages: action.payload.participant_packages,
          participant_packages_en: action.payload.participant_packages_en,

          offer_pdf: action.payload.offer_pdf,
        };
      default:
        return state;
    }
  };
  
  export const getRegDocsAction = (payload) => ({ type: GET_REG_DOCUMENTS, payload});