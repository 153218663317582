const initialState = {
    partners: false,
  };
  
  const SET_PARTNERS = 'SET_PARTNERS';
  
  export const partnersReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PARTNERS:
        return {...state,
          partners: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const setPartnersAction = (payload) => ({ type: SET_PARTNERS, payload});