import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowDown from '../../../image/icons/arrow-down.png';
import { setNewTypeOfRegistrationAction } from '../../../redux/Reducers/regInfoReducers';

import styles from './RegPage.module.scss';
import getRegistraionType from '../../../functions/getRegistraionType';

const ChooseTypeReg = ({ clearErrorList }) => {
    const dispatch = useDispatch()

    const [isTypeListOpen, setIsTypeListOpen] = useState(true);
    const [activeType, setActiveType] = useState();
    const [typeOfParticipation, setTypeOfParticipation] = useState([
      // {name: 'free', text: 'По приглашению'},
      // {name: 'onln', text: 'On-line участие'},
      // // {name: 'paid', text: 'Платное участие'},
      // // {name: 'smi', text: 'Аккредитация СМИ'}
    ]);

    const currentLang = useSelector(state => state.lang.currentLang)

    const currentTypeOfRegistration = useSelector(
      (state) => state.regInfo.registration_type
    );

    useEffect(() => {
      dispatch(getRegistraionType())
    }, [dispatch, currentLang])

    const typeOfRegistration = useSelector(state => state.registrationType.types)

    useEffect(() => {
      if (typeOfParticipation.length < 1) return; 
      if (currentTypeOfRegistration === '') dispatch(setNewTypeOfRegistrationAction(typeOfParticipation[0].name))
      setActiveType(typeOfParticipation.filter(item => item.name === currentTypeOfRegistration)[0])
    }, [currentTypeOfRegistration, typeOfParticipation])

    useEffect(() => {
      const isArrayHasFunc = (object) => {
        let flag = false;
        typeOfParticipation.forEach((item) => {
          if (item.name === object.name) flag = true;
        });
    
        return !flag;
      };
  
      let arr = []
      if (typeOfRegistration.reg_allow_free) arr.push({name: 'free', text: 'Участие в гастрономической программе', text_en: "Gastronomic program"});
      if (typeOfRegistration.reg_allow_online) arr.push({name: 'onln', text: 'On-line участие', text_en: "Online participation"});
      if (typeOfRegistration.reg_allow_paid) arr.push({name: 'paid', text: 'Платное участие', text_en: "Paid participation"});
      if (typeOfRegistration.reg_allow_smi) arr.push({name: 'smi', text: 'Аккредитация СМИ', text_en: "Media accreditation"});
      
      if (currentLang === 'en') {
        arr = arr.filter(item => item.name !== 'paid')
      }
      
      if (arr.length > 0) setTypeOfParticipation(arr)
    }, [typeOfRegistration, currentLang])


  
    const activeTypeClickHandler = () => setIsTypeListOpen(!isTypeListOpen);
    const nonActiveTypeClickHandler = (event) => {
        setIsTypeListOpen(!isTypeListOpen);
        setActiveType({name: event.target.dataset.type, text: event.target.innerHTML, text_en: event.target.innerHTML});
        dispatch(setNewTypeOfRegistrationAction(event.target.dataset.type))
        clearErrorList()
    }
    
  return (
    <div className={styles.typeOfRegWrapper}>
      <div className={styles.activeLink} onClick={activeTypeClickHandler} style={!isTypeListOpen ? {borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px'} : {borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}>
        <p>{ activeType && (currentLang === 'ru' ? activeType.text : activeType.text_en)}</p>
        <img src={ArrowDown} alt="" style={isTypeListOpen ? {transform: 'rotate(0deg)'} : {transform: 'rotate(180deg)'}} />
      </div>
      <div className={styles.noneActiveLink} style={isTypeListOpen ? {display: 'none'} : {display: 'flex'}}>
        {typeOfParticipation.length > 0 && activeType && typeOfParticipation
            .filter((item) => item.name !== activeType.name)
            .map((item, index) => 
                <p key={index} data-type={item.name} onClick={nonActiveTypeClickHandler}>{ currentLang === 'ru' ? typeOfParticipation.find(type => type.name === item.name).text : typeOfParticipation.find(type => type.name === item.name).text_en }</p>
            )}
      </div>
    </div>
  )
}

export default ChooseTypeReg
