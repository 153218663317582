const initialState = {
    hotels: false,
  };
  
  const GET_HOTELS = 'GET_HOTELS';
  
  export const hotelsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_HOTELS:
        return {
          hotels: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getHotelsAction = (payload) => ({ type: GET_HOTELS, payload});