import { BASE_URL_API } from '../BASE_URL.js';
import { getCitiesAction } from '../redux/Reducers/locationsReducers.js';

const getCities = (cityId) => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'geo/cities/' + cityId);
        const data = await response.json();

        dispatch(getCitiesAction(data));
    }
}

export default getCities;