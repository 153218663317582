const initialState = {
  countAlbums: 0,
  isNext: false,
  isPrev: false,
  albumsList: [],
};

const GET_ALBUMS = "GET_ALBUMS";

export const photosReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALBUMS:
      return {
        countAlbums: action.payload.count,
        isNext: action.payload.next === null ? false : true,
        isPrev: action.payload.previous === null ? false : true,
        albumsList: action.payload.results,
      };
    default:
      return state;
  }
};

export const getAlbumsAction = (payload) => ({ type: GET_ALBUMS, payload });
