import React, { useState, useEffect, useRef } from "react";

import ArrowDown from "../../../image/icons/arrow-down.png";

import styles from "./SelectInput.module.scss";
import Box from "../Box/Box";
import { useSelector } from 'react-redux';
import { globalTranslate } from "../../../functions/languages";

const SelectWithoutLabel = ({ labelText, options, twoKeys, onChange, isValue, error, isDisabled }) => {
  const [activeValue, setActiveValue] = useState({ name: "", name_en: "", id: "" });
  const [isScaleLabel, setIsScaleLabel] = useState(false);
  const [arrayOfOptions, setArrayOfOptions] = useState();
  const [searchField, setSearchField] = useState("");
  const [isSelectActive, setIsSelectActive] = useState(false);

  const currentLang = useSelector(state => state.lang.currentLang)


  useEffect(() => {
    
    if (options.length < 1) return;
    if (activeValue.name === "") setActiveValue({name: options[0].title, name_en: options[0].title_en, id: options[0].id})
    if (!isValue) return
    const result = options.filter(item => item.id == isValue)[0]

    if (!result) return;

    const obj = {name: result.title, name_en: result.title_en, id: result.id}

    setActiveValue(obj)
  }, [isValue, options])


  const selectClickHandler = () => {
    if (isDisabled) return
    setIsSelectActive(!isSelectActive)
  };

  const itemClickHandler = (event) => {
    onChange(twoKeys ? { id: event.target.id, name: event.target.innerHTML } : event.target.innerHTML );
    setActiveValue({ name: event.target.innerHTML, name_en: event.target.innerHTML, id: event.target.id });
    setIsSelectActive(false);
  };

  const rootEl = useRef();

  useEffect(() => {
    if (searchField === "") {
      setArrayOfOptions(options);
    } else {
      setArrayOfOptions(
        arrayOfOptions.filter((item) =>
          item.name.toLowerCase().includes(searchField.toLowerCase())
        )
      );
    }
  }, [searchField]);

  useEffect(() => {
    if (!options) return;
    const arr = []
    options.map(item => arr.push({name: item.title, name_en: item.title_en, id: item.id}))
    setArrayOfOptions(arr);
  }, [options]);

  useEffect(() => {
    if (isSelectActive) {
      const onClick = e => {
        if (!rootEl.current.contains(e.target)) {
          setIsSelectActive(false);
          if (activeValue.name === '') {
            setIsScaleLabel(false); 
          }
        };
      }
      document.addEventListener('click', onClick);
      return () => document.removeEventListener('click', onClick);
    }
  }, [isSelectActive]);

  useEffect(() => {
    if (!activeValue) return;
    if (activeValue.name !== "") {
      setIsScaleLabel(true);
    }
  }, [activeValue]);
  return (
    <Box maxW={700}>
      <div className={styles.selectDefault} ref={rootEl} style={error ? {backgroundColor: '#FFEDED'} : {}}>
        <div className={styles.clickableElement} onClick={selectClickHandler}>
          <img
            src={ArrowDown}
            style={
              isSelectActive
                ? { transform: "rotate(180deg)" }
                : { transform: "rotate(0deg)" }
            }
            alt=""
          />
        </div>
        <p className={styles.activeItem}  style={ isDisabled ? {color: '#545454', cursor: 'default'} : {}}>{currentLang === 'ru' ? activeValue.name : activeValue.name_en}</p>
        <ul
          className={styles.selectList}
          style={isSelectActive ? { display: "block" } : { display: "none" }}
        >
          {/* <div className={styles.searchWrapper}>
          </div>  */}
          {arrayOfOptions && arrayOfOptions.map((item, index) => (
            <li
              key={index}
              id={twoKeys ? item.id : item}
              onClick={itemClickHandler}
            >
              {twoKeys ? currentLang === 'ru' ? item.name : item.name_en : item}
            </li>
          ))}
        </ul>
      </div>
    </Box>
  );
};

export default SelectWithoutLabel;
