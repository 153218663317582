import { BASE_URL_API } from "../BASE_URL.js";
import { setCurrentUserAction } from "../redux/Reducers/currentUserReducer.js";
import { setErrorAction } from "../redux/Reducers/errorsReducer.js";
import { isAuth } from "./isAuth";

const registration = (userData, navigate) => {
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
const csrftoken = getCookie('csrftoken');
  return async (dispatch) => {
    const token = window.localStorage.token;
    const response = await fetch(BASE_URL_API + "accounts/register/", {
      headers: { 
        "Content-Type": "application/json; charset=UTF-8",
        'X-CSRFToken': csrftoken,
    },
      body: JSON.stringify(userData),
      method: "POST",
    });

    if (response.status === 201) {
      const data = await response.json();
      window.localStorage.setItem("token", data.token);
      await dispatch(setCurrentUserAction(data));
      await isAuth(dispatch);

      navigate("/cabinet/my_registrations/");
    }
    if (response.status === 400) {
      const data = await response.json();
      if (data.email) {
        if (data.email[0]) {
          dispatch(setErrorAction(data.email[0]))
        }
      }
      if (data.password) {
        dispatch(setErrorAction('Похоже, вы забыли ввести пароль'))
      }
    }
  };
};

export default registration;
