const initialState = {
    spheres: false,
  };
  
  const GET_SPHERES = 'GET_SPHERES';
  
  export const companySpheresReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SPHERES:
        return {
            spheres: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const setSpheresAction = (payload) => ({ type: GET_SPHERES, payload});