import React from "react";
import Container from "../../elements/Container/Container";
import PageTemplate from "../../PageTemplate/PageTemplate";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import getCurrentPage from "../../../functions/getCurrentPage";
import { useState } from "react";

import { ReactComponent as ArrowBack } from "../../../image/icons/arrow-right.svg";

import styles from "./VariablePage.module.scss";
import defaultStyles from "./VariablePage.scss";
import ContentContainer from "../../elements/Container/ContentContainer";
import Hotels from "../../elements/Hotels/Hotels";
import { globalTranslate } from "../../../functions/languages";

const VariablePage = () => {
  const [page, setPage] = useState();
  const [pageChilds, setPageChilds] = useState();
  const [menuPosition, setMenuPosition] = useState(300);
  const [menuTopPosition, setMenuTopPosition] = useState(100);
  const pageRedux = useSelector((state) => state.currentPage.page);
  const pages = useSelector((state) => state.pages.pages);
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const sidebar = document.getElementById("sidebar");
  const currentPageMarker = document.getElementById("currentPageMarker");

  const currentLang = useSelector((state) => state.lang.currentLang);

  useEffect(() => {
    if (!sidebar) return;
    setMenuPosition(sidebar.clientWidth);
  }, [sidebar]);

  useEffect(() => {
    if (!currentPageMarker) return;
    setMenuTopPosition(currentPageMarker.getBoundingClientRect().top - 20);
    // setMenuPosition(sidebar.clientWidth)
  }, [currentPageMarker]);

  useEffect(() => {
    setPage();
    setPageChilds();
    dispatch(getCurrentPage(params.variable_link, navigate));
  }, [dispatch, params]);

  useEffect(() => {
    if (!pageRedux) return;
    setPage(pageRedux);
  }, [pageRedux]);

  useEffect(() => {
    if (!pages) return;
    if (!page) return;
    const currentPage = pages.filter((item) => item.id === page.id);
    if (currentPage.length > 0) {
      const sorted = currentPage[0].children.sort((a, b) =>
        a.order_index > b.order_index ? 1 : -1
      );
      setPageChilds(sorted);
    }
  }, [pages, page]);

  const createMarkup = (text) => {
    return { __html: text };
  };

  return (
    <PageTemplate>
      <Container>
        <ContentContainer>
          {page && (
            <>
              <h3>
                {currentLang === "ru" ? page.page.title : page.page.title_en}
              </h3>
              {pageChilds && pageChilds.length > 0 ? (
                <ul
                  className={styles.variableNav}
                  id="variableMenu"
                  style={
                    window.innerWidth > 1200
                      ? { right: menuPosition + 20, top: menuTopPosition }
                      : {}
                  }
                >
                  {pageChilds.map((item, index) => (
                    <li key={index}>
                      <Link to={"/pages/" + item.link}>
                        {currentLang === "ru" ? item.title : item.title_en}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className={styles.linkStyles}>
                  <a
                    onClick={() =>
                      navigate(
                        pathname.includes("pages/hotels") ? "/pages/about" : -1
                      )
                    }
                  >
                    {globalTranslate(currentLang, "Назад")}
                  </a>
                  <ArrowBack />
                </div>
              )}

              {params && params.variable_link === "hotels" && <Hotels />}
              {params && params.variable_link !== "hotels" && (
                <div
                  className={styles.content}
                  dangerouslySetInnerHTML={createMarkup(
                    currentLang === "ru"
                      ? page.page.content
                      : page.page.content_en
                  )}
                ></div>
              )}
            </>
          )}
        </ContentContainer>
      </Container>
    </PageTemplate>
  );
};

export default VariablePage;
