import React, { useState, useEffect } from "react";

import styles from "./TextInput.module.scss";
import Box from '../Box/Box';
import { ReactTransliterate } from "react-transliterate";
import { translit } from '../../../functions/translit';
import { useSelector } from "react-redux";
import { globalTranslate } from "../../../functions/languages";

const TranslitTextInput = ({
  onChange,
  labelText,
  isValue,
  isDisabled,
  disabled,
  dateValue,
  type,
  onBlur,
  error,
}) => {
  const [isInputActive, setIsInputActive] = useState(false);
  const [value, setValue] = useState(isValue ? isValue : "");

  const currentLang = useSelector(state => state.lang.currentLang)


  const onChangeHandler = (event) => {
    if (isDisabled) return;

    setValue(event.target.value);
    onChange(event.target.value);
  };

  const inputOnFocusHandler = () => {
    if (!value && !isDisabled) setIsInputActive(false);
    if (!value && isDisabled) setIsInputActive(true);
    onBlur && onBlur();
  };

  useEffect(() => {
    setValue(translit(isValue))
  }, [isValue])

  useEffect(() => {
    onChange(translit(value));
  }, [value])

  useEffect(() => {
    if (isDisabled) {
      !value && setIsInputActive(true);
      return;
    }
    onChange(value);
  }, [value]);


  useEffect(() => {
    if (value !== '') {
      setIsInputActive(true)
    } else {
      setIsInputActive(false)
    }
  }, [value])

  return (
    <Box maxW={700}>
      <label
        className={styles.labelWrapper}
        onClick={() => setIsInputActive(true)}
        onFocus={() => setIsInputActive(true)}
      >
        <span className={isInputActive ? styles.activeLabel : styles.label} style={ disabled && {color: '#8F8F8F'}}>
          {globalTranslate(currentLang, labelText)}
        </span>
        <input
          type={type ? type : "text"}
          disabled={isDisabled}
          value={value}
          onChange={onChangeHandler}
          style={error ? {backgroundColor: '#FFEDED'} : {}}
          onFocus={onBlur && (() => onBlur())}
          onBlur={inputOnFocusHandler}
        />
        {/* {withErrorNote && <div className={styles.error}> <p>{ errorText }</p> </div>} */}
      </label>
    </Box>
  );
};

export default TranslitTextInput;
