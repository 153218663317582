
import { BASE_URL_API } from '../BASE_URL.js';
export const setNewPassword = async (data, isLoad, isError, clear, success) => {

    function getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    }
    const csrftoken = getCookie("csrftoken");

    const response = await fetch(BASE_URL_API + 'auth/users/set_password/', {
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            "X-CSRFToken": csrftoken,
            Authorization: "Token " + window.localStorage.token,
        },
        body: JSON.stringify(data),
        method: 'POST'
    })

    if (response.status === 204) {
        isLoad(false)
        isError()
        success("Пароль успешно изменен")
        clear({
            current_password: "",
            new_password: '',
            confirmNewPass: "",
        })
    }
    if (response.status === 400) {
        const json = await response.json()
        const errorsList = []
        for (let key in json) {
            const typeError = key === 'current_password' ? 'Старый пароль' : 'Новый пароль'
            errorsList.push(typeError + '. ' + json[key][0])
        }
        isError(errorsList)
        isLoad(false)
    }   
    
}