import { BASE_URL_API } from '../BASE_URL.js';

const setUserDocument = async (data, isReady, getNewDocs) => {
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
          const cookies = document.cookie.split(";");
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
            }
          }
        }
        return cookieValue;
      }
      const csrftoken = getCookie("csrftoken");

      const formData = new FormData()

      for (let key in data) {
        formData.append(key, data[key]);
      }
        
        const response = await fetch(BASE_URL_API + 'accounts/documents/', {
            headers: {
                "X-CSRFToken": csrftoken,
                Authorization: "Token " + window.localStorage.token,
            },
            body: formData,
            method: 'POST'
        });
        if (response.status === 201) {
          isReady();
          getNewDocs(true);
        }
}

export default setUserDocument;