import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageTemplate from "../../PageTemplate/PageTemplate";
import LastMedia from "./MainPageComponents/LastMedia/LastMedia";
import LastNews from "./MainPageComponents/LastNews/LastNews";

import PartnerImageGuber from "../../../image/partnersLogo/guber.svg";
import PartnerImageGuberEn from "../../../image/partnersLogo/guber_en.svg";
import PartnerImageGoverment from "../../../image/partnersLogo/goverment.svg";
import PartnerImageGovermentEn from "../../../image/partnersLogo/goverment_en.svg";
import PartnerImageEnsib from "../../../image/partnersLogo/ensib.svg";
import PartnerImageEnsibEn from "../../../image/partnersLogo/ensib_en.svg";
import PartnerImageSFU from "../../../image/partnersLogo/sfu.svg";

import styles from "./MainPage.module.scss";
import Timer from "./MainPageComponents/Timer";
import Container from "../../elements/Container/Container";
import { getNewsMainPage } from "../../../functions/getNews";
import WelcomeSlider from "../../elements/WelcomeSlider/WelcomeSlider";

import TransparentHeader from "../../PageTemplate/TransparentHeader";
import { getFirstAlbums } from "../../../functions/getPhotos";
import ContentContainer from "../../elements/Container/ContentContainer";

import MainMap from "../../../image/main-map.svg";

const MainPage = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [plasePosition, setPlacePosition] = useState(0);
  const [date, setDate] = useState({
    date: "",
    date_en: "",
    month: "",
    month_en: "",
  });
  const [screenWidth, setScreenWidth] = useState(true);
  const [isTimeIsGone, setTimeIsGone] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(100);
  const [titlePosition, setTitlePosition] = useState(0);

  const welcomeWordsRedux = useSelector(
    (state) => state.welcomeWords.welcomeWords
  );

  const setPartnersWidth = () => {
    let blockWidth = 200;
    if (window.innerWidth > 1000) blockWidth = 245;
    if (window.innerWidth > 1100) blockWidth = 350;
    if (window.innerWidth > 1310) blockWidth = 580;

    return blockWidth;
  };

  const resize = () => {
    window.innerWidth >= 1000 && setScreenWidth(true);
    window.innerWidth < 1000 && setScreenWidth(false);
  };

  useEffect(() => {
    resize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  const indexConstance = useSelector((state) => state.indexConstance.index);
  const currentLang = useSelector((state) => state.lang.currentLang);

  const dateOfKEF = Date.parse(indexConstance.countdown);

  const timeTo = dateOfKEF - Date.now();

  useEffect(() => {
    if (!indexConstance) return;
    let arr;
    let arr_en;
    if (indexConstance.date) {
      arr = indexConstance.date.split(" ");
    }
    if (indexConstance.date_en) {
      arr_en = indexConstance.date_en.split(" ");
    }

    setDate({
      date: arr && arr.length > 0 ? arr[0] : "",
      month: arr && arr.length > 0 ? arr[1] : "",
      date_en: arr_en && arr_en.length > 0 ? arr_en[0] : "",
      month_en: arr_en && arr_en.length > 1 ? arr_en[1] : "",
    });
  }, [indexConstance]);

  useEffect(() => {
    if (timeTo < 0) {
      setTimeIsGone(true);
    }
    const interval = setInterval(() => {
      setDays((timeTo - (timeTo % 86400000)) / 86400000);
      setHours(
        ((timeTo % 86400000) - ((timeTo % 86400000) % 3600000)) / 3600000
      );
      setMinutes(
        (((timeTo % 86400000) % 3600000) -
          (((timeTo % 86400000) % 3600000) % 60000)) /
          60000
      );
      setSeconds(
        ((((timeTo % 86400000) % 3600000) % 60000) -
          ((((timeTo % 86400000) % 3600000) % 60000) % 1000)) /
          1000
      );
    }, 1000);

    if (window.innerWidth > 1000) {
      setPlacePosition(document.getElementById("sidebar").clientWidth);
    } else {
      setPlacePosition(document.getElementById("sidebar").clientWidth);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timeTo]);

  // useEffect(() => {
  //   const title = document.querySelector(".headerAnchor");
  //   let mobileOffset = isMobile ? 140 : 50;
  //   if (window.innerWidth <= 1000) mobileOffset = 40;
  //   if (window.innerWidth <= 786) mobileOffset = 140;
  //   if (window.innerWidth > 1000) mobileOffset = 50;

  //   setTitlePosition(title.offsetTop + mobileOffset);
  // }, []);

  const getHeaderHeight = (elem) => {
    setHeaderHeight(elem);
  };
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getNewsMainPage(currentLang === 'en'));
  // }, [currentLang])

  useEffect(() => {
    // dispatch(getNewsMainPage(currentLang === 'en'));
    dispatch(getFirstAlbums());
    // dispatch(getPhotoGalleries())
  }, [dispatch]);

  return (
    <PageTemplate
      isMainPage={true}
      children={
        <>
          <div className={styles.firstScreen}>
            <Container>
              <TransparentHeader getHeight={getHeaderHeight} />
            </Container>

            <Container height={true}>
              <div className={styles.firstScreenContent}>
                {screenWidth ? (
                  // DESKTOP
                  <>
                    <div
                      className={styles.placeWrapper}
                      style={{ right: plasePosition, top: 100 }}
                    >
                      <span className={styles.date}>
                        {currentLang === "ru"
                          ? date.date && date.date
                          : date.date_en && date.date_en}
                      </span>
                      <div>
                        <span className={styles.month}>
                          {currentLang === "ru"
                            ? date.month && date.month
                            : date.month_en && date.month_en}
                        </span>
                        <span className={styles.place}>
                          {currentLang === "ru"
                            ? indexConstance.place
                            : indexConstance.place_en}
                        </span>
                      </div>
                    </div>
                    <div className={styles.headerAndMap}>
                      <h1 className="headerAnchor">
                        {currentLang === "ru"
                          ? indexConstance.title
                          : indexConstance.title_en}
                      </h1>
                      <img src={MainMap} />
                    </div>
                    <div></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                      }}
                    >
                      <Timer
                        isUnVisible={isTimeIsGone}
                        days={days}
                        hours={hours}
                        minutes={minutes}
                        seconds={seconds}
                      />
                      <div className={styles.partners}>
                        {currentLang === "ru" ? (
                          <img src={PartnerImageGuber} alt="" />
                        ) : (
                          <img src={PartnerImageGuberEn} alt="" />
                        )}
                        {currentLang === "ru" ? (
                          <img src={PartnerImageGoverment} alt="" />
                        ) : (
                          <img src={PartnerImageGovermentEn} alt="" />
                        )}
                        {currentLang === "ru" ? (
                          <img src={PartnerImageEnsib} alt="" />
                        ) : (
                          <img
                            src={PartnerImageEnsibEn}
                            alt=""
                            style={{ maxWidth: 160 }}
                          />
                        )}
                        <img src={PartnerImageSFU} alt="" />
                      </div>
                    </div>
                  </>
                ) : (
                  // MOBILE
                  <>
                    <div className={styles.mobileFirstScreenContent}>
                      <h1 className="headerAnchor">
                        {currentLang === "ru"
                          ? indexConstance.title
                          : indexConstance.title_en}
                      </h1>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: -40,
                        }}
                      >
                        <div className={styles.placeWrapper}>
                          <div>
                            <span className={styles.date}>
                              {currentLang === "ru"
                                ? date.date && date.date
                                : date.date_en && date.date_en}
                            </span>
                            <span className={styles.month}>
                              {currentLang === "ru"
                                ? date.month && date.month
                                : date.month_en && date.month_en}
                            </span>
                          </div>

                          <div>
                            <span className={styles.place}>
                              {currentLang === "ru"
                                ? indexConstance.place
                                : indexConstance.place_en}
                            </span>
                          </div>
                        </div>
                        <Timer
                          isUnVisible={isTimeIsGone}
                          days={days}
                          hours={hours}
                          minutes={minutes}
                          seconds={seconds}
                        />
                      </div>
                    </div>

                    <div
                      style={
                        window.innerWidth > 1000
                          ? {
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }
                          : window.innerWidth < 768
                          ? {
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "start",
                              height: "100%",
                            }
                          : {
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "start",
                            }
                      }
                    >
                      <div className={styles.partners}>
                        {currentLang === "ru" ? (
                          <img src={PartnerImageGuber} alt="" />
                        ) : (
                          <img src={PartnerImageGuberEn} alt="" />
                        )}
                        {currentLang === "ru" ? (
                          <img src={PartnerImageGoverment} alt="" />
                        ) : (
                          <img src={PartnerImageGovermentEn} alt="" />
                        )}
                        {currentLang === "ru" ? (
                          <img src={PartnerImageEnsib} alt="" />
                        ) : (
                          <img
                            src={PartnerImageEnsibEn}
                            alt=""
                            style={{ maxWidth: 160 }}
                          />
                        )}
                        <img src={PartnerImageSFU} alt="" />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Container>
          </div>
          <Container height={true}>
            <ContentContainer>
              {welcomeWordsRedux && welcomeWordsRedux.length > 0 && (
                <WelcomeSlider />
              )}
              <LastNews />
              <LastMedia />
            </ContentContainer>
          </Container>
        </>
      }
    />
  );
};

export default MainPage;
