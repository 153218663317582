import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Container from "../../elements/Container/Container";
import ContentContainer from "../../elements/Container/ContentContainer";
import PageTemplate from "../../PageTemplate/PageTemplate";

import styles from './WelcomeWords.module.scss'
import { useParams } from 'react-router-dom';
import ReadMoreLink from "../../elements/ReadMoreLink/ReadMoreLink";
import { globalTranslate } from "../../../functions/languages";

const WelcomeWords = () => {
  const [localWord, setLocalWord] = useState();

  const params = useParams();
  const currentLang = useSelector((state) => state.lang.currentLang)

  const words = useSelector((state) => state.welcomeWords.welcomeWords);

  useEffect(() => {
    if (!words || !words.length || words.length < 1) return;
    const obj = words.filter(item => item.id == params.id)[0]
    setLocalWord(obj)
  }, [words]);

  const createMarkup = (text) => {
    return { __html: text };
  };

  return (
    <PageTemplate>
      <Container>
        <ContentContainer>
          <div className={styles.wrapper}>
            {localWord && (window.innerWidth > 1200 ? (
                <div className={styles.item}>
                <div className={styles.img}>
                    <img src={localWord.photo} alt={localWord.fio} />
                </div>
                <div className={styles.text}>
                    <h4>{currentLang === 'ru' ? localWord.title : localWord.title_en}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(currentLang === 'ru' ? localWord.max_text : localWord.max_text_en)}></div>
                </div>
            </div>
            ) : (
                <div className={styles.item}>
                    <h4>{localWord.title}</h4>
                    <div className={styles.img}>
                        <img src={localWord.photo} alt={localWord.fio} />
                    </div>
                    <div dangerouslySetInnerHTML={createMarkup(localWord.max_text)}></div>

                    {/* <div className={styles.text}>
                        
                    </div> */}
                </div>
            ))}
            <div style={window.innerWidth > 1200 ? {width: 'max-content', margin: '20px 0 40px 0', alignSelf: 'end'} : {margin: '20px 0 40px 0'}}>
                <ReadMoreLink text={globalTranslate(currentLang, 'На главную')} link={"/main"} />
            </div>
          </div>
        </ContentContainer>
      </Container>
    </PageTemplate>
  );
};

export default WelcomeWords;
