import React, { useEffect, useState } from "react";
import styles from "./ChangeCategorySelect.module.scss";
import { useSelector } from 'react-redux';

const ChangeCategorySelect = ({ onChange, options, isValue }) => {
  const [activeItem, setActiveItem] = useState({
    title: "Выберите категорию",
    id: null,
  });
  const [isShowList, setShowList] = useState(false);

  const categoryRedux = useSelector(state => state.categoryList.category)

  useEffect(() => {
    if (!categoryRedux) return;
    if (isValue === null) {
        setActiveItem({title: "Выберите категорию",id: null})
    } else {
        const currentCategory = categoryRedux.filter(item => item.id === isValue)[0]
        setActiveItem({title: currentCategory.name, id: currentCategory.id})
    }
  }, [categoryRedux, isValue])

  const itemClickHandler = (item) => {
    setActiveItem({title: item.title, id: item.id})
    onChange({title: item.title, id: item.id})
    setShowList(false)
  }

  return (
    <div className={styles.select}>
      <p onClick={() => setShowList(!isShowList)}>{activeItem.title}</p>
      {isShowList && (
        <ul>
          <li onClick={() => itemClickHandler({title: "Выберите категорию", id: null})}>Без категории</li>
          {options &&
            options.map((item, index) => <li key={index} onClick={() => itemClickHandler(item)}>{item.title}</li>)}
        </ul>
      )}
    </div>
  );
};

export default ChangeCategorySelect;
