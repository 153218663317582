import { BASE_URL_API } from '../BASE_URL.js';
import { setcategoryOfParticipantsAction } from '../redux/Reducers/categoryOfParticipantsReducer.js';

const getCategoryOfParticipants = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'registrations/participant-categories/', {
            headers: {
            //   Authorization: "Token " + window.localStorage.token,
            },
        });
        const data = await response.json();

        dispatch(setcategoryOfParticipantsAction(data));
    }
}

export default getCategoryOfParticipants;