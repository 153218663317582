import React, { useState } from "react";

import styles from "../GalleryPage.module.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import cameraIcon from "../../../../image/icons/camera.svg";
import { useNavigate } from 'react-router-dom';

import loader from '../../../../image/load.gif';
import { globalTranslate } from "../../../../functions/languages";

const PhotosItem = ({ item, width, onClick }) => {
  const [currentGallery, setCurrentGallery] = useState();
  const galleryName = useSelector((state) => state.photos.gallery);

  const currentLang = useSelector(state => state.lang.currentLang)

  const navigate = useNavigate()

  useEffect(() => {
    if (galleryName && galleryName.results.length > 0) {
      const currentGalleryName = currentLang === 'ru' ? galleryName.results.filter(
        (point) => point.id === item.gallery
      )[0].title : galleryName.results.filter(
        (point) => point.id === item.gallery
      )[0].title_en;
      setCurrentGallery(currentGalleryName);
    }
  }, [galleryName, item, currentLang]);

  return (
    <div className={styles.item} style={{width: width}} onClick={() => navigate('/gallery/photos/' + item.slug)}>
       {item.photos.length > 0 ? (
        <>
        <div className={styles.imgWrap}>
        <img src={item.photos[0].thumbnail !== null ? item.photos[0].thumbnail : item.photos[0].file} alt={item.title} />
        </div>
        </>
       ) : (
        <span>Нет фото</span>
       )}
       {currentGallery && (
        <div className={styles.control}>
          <p>{currentGallery}</p>
          <div className={styles.icon}>
            <img src={cameraIcon} alt="video" />
            <span>{item.photos.length}</span>
          </div>
        </div>
      )} 
      <p className={styles.title}>{currentLang === 'ru' ? item.title : item.title_en}</p>
    </div>
  );
};

export default PhotosItem;