import { BASE_URL_API } from '../BASE_URL.js';

const deleteUser = async (id, setLoad, isReadyToGetNew) => {
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
          const cookies = document.cookie.split(";");
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
            }
          }
        }
        return cookieValue;
      }
      const csrftoken = getCookie("csrftoken");
        
        const respone = await fetch(BASE_URL_API + 'registrations/registrations/' + id + '/', {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken,
                Authorization: "Token " + window.localStorage.token,
            },
            method: 'DELETE'
        });
        if (respone.status === 200) {
          isReadyToGetNew(true)
        }
        setLoad(false)
}

export default deleteUser;