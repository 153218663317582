import React from 'react'
import { useState } from 'react'
import Container from '../../elements/Container/Container'
import ContentContainer from '../../elements/Container/ContentContainer'
import PageTemplate from '../../PageTemplate/PageTemplate'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react'

import styles from './AllPhotos.module.scss';
import getPhotoGalleries from '../../../functions/getPhotoGalleries';
import PhotosItem from '../GalleryPage/Photos/PhotosItem';
import { useRef } from 'react'
import ReadMoreLink from '../../elements/ReadMoreLink/ReadMoreLink'
import { globalTranslate } from '../../../functions/languages'

const AllPhotos = () => {
  const [allPhotos, setAllPhotos] = useState()
  const [allGalleriesNames, setGalleriesNames] = useState()
  const [currentDay, setCurrentDay] = useState()
  const [itemWidth, setItemWidth] = useState(250)

  const currentLang = useSelector(state => state.lang.currentLang);

  const photos = useSelector(state => state.photos.gallery)
  const dispatch = useDispatch()

  const container = useRef()

  const resize = () => {
    let itemsInStroke = 4
    if (window.innerWidth > 1700) itemsInStroke = 4
    if (window.innerWidth <= 1700) itemsInStroke = 3
    if (window.innerWidth < 1300) itemsInStroke = 2
    if (window.innerWidth < 768) itemsInStroke = 1
    
    const width = container.current.clientWidth / itemsInStroke - 25

    setItemWidth(width)
  }

  window.addEventListener('resize', resize)

  useEffect(() => {
    resize()
  }, [])

  useEffect(() => {
    dispatch(getPhotoGalleries())
  }, [dispatch])

  useEffect(() => {
    if (!photos) return;
    const names = []
    photos.results.forEach(item => names.includes(item.year) === false && names.push(item.year))
    names.sort((a,b) => { 
      return b - a
    })
    setGalleriesNames(names)
    setCurrentDay(names[0])
  }, [photos])

  useEffect(() => {
    if (!currentDay) return;
    const array = photos.results.filter(item => item.year === currentDay)
    const arrayAlbums = []
    array.forEach(item => {
      item.albums.forEach(point => arrayAlbums.push(point))
    })
    setAllPhotos(arrayAlbums)
  }, [currentDay])

  return (
    <PageTemplate>
        <Container>
            <ContentContainer>
                <h3>{globalTranslate(currentLang, 'Все фотографии')}</h3>
                <div style={{margin: '40px 0'}}>
                  <ReadMoreLink text={globalTranslate(currentLang, "Галерея")} link={'/gallery'} />
                </div>
                <div className={styles.daysWrapper} ref={container}>
                    {allGalleriesNames && allGalleriesNames.map((item, index) => <p key={index} style={currentDay === item ? {fontFamily: 'Gotham Pro Bold', color: '#047EC2'} : {}} onClick={() => setCurrentDay(item)}>{item}</p>)}
                </div>
                <div className={styles.photosWrapper}>
                    {allPhotos && allPhotos.map((item, index) => <PhotosItem key={index} item={item} width={itemWidth} />)}
                </div>
                <div style={{margin: '40px 0'}}>
                  <ReadMoreLink text={globalTranslate(currentLang, "Галерея")} link={'/gallery'} />
                </div>
            </ContentContainer>
        </Container>
    </PageTemplate>
  )
}

export default AllPhotos