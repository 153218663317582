const initialState = {
    contacts: [],
  };
  
  const GET_CONTACTS = 'GET_CONTACTS';
  
  export const contactsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CONTACTS:
        return {
          contacts: action.payload.results,
        };
      default:
        return state;
    }
  };
  
  export const getContactsAction = (payload) => ({ type: GET_CONTACTS, payload});