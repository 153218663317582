import React, { useEffect } from "react";
import styles from "./Pagination.module.scss";
import { useSelector } from "react-redux";
import { useState } from "react";

import classnames from "classnames";

import { ReactComponent as RightArrowIcon } from "../../../image/icons/arrow-right-pagination.svg";
import { globalTranslate } from "../../../functions/languages";

const Pagination = ({ clickPage, activePage, clickNext, clickPrev }) => {
  const [paginations, setPaginations] = useState();
  const news = useSelector((state) => state.news);
  const currentLang = useSelector(state => state.lang.currentLang)

  useEffect(() => {
    if (!news || news.countNews == 0) {
      setPaginations()
      return
    };
    let paginationArray = [];
    const newsPerPage = Math.ceil(news.countNews / 7);

    for (let i = 1; i <= newsPerPage; i++) {
      paginationArray.push(i.toString());
    }

    setPaginations(paginationArray);
  }, [news, news.countNews]);

  return (
    <div className={styles.pagination}>
      {paginations && paginations.length > 1 && activePage > 1 && (
        <div className={classnames([styles.prev])} onClick={clickPrev}>
          <RightArrowIcon /> {globalTranslate(currentLang, 'Назад')}
        </div>
      )}
      {paginations &&
        paginations.map((item, index) => {
          if (activePage > 0 && activePage < 4) {
            if (index + 1 == 1 || index + 1 == 2 || index + 1 == 3 || index + 1 == 4 || index + 1 == 5) {
              return (
                <div
                  key={index}
                  className={
                    index + 1 == activePage
                      ? styles.activePaginationItem
                      : styles.paginationItem
                  }
                  onClick={() => clickPage(item)}
                >
                  {item}
                </div>
              );
            }
          } 
          if (activePage >= 4) {
            if (index + 1 == activePage - 2 || index + 1 == activePage - 1 || index + 1 == activePage || index + 1 == Number(activePage) + 1 || index + 1 == Number(activePage) + 2) {
              return (
                <div
                  key={index}
                  className={
                    index + 1 == activePage
                      ? styles.activePaginationItem
                      : styles.paginationItem
                  }
                  onClick={() => clickPage(item)}
                >
                  {item}
                </div>
              );
            }
          }
        })}
      {paginations &&
        paginations.length > 1 &&
        activePage < paginations.length && (
          <div className={classnames([styles.next])} onClick={clickNext}>
            {globalTranslate(currentLang, 'Вперед')} <RightArrowIcon />
          </div>
        )}
    </div>
  );
};

export default Pagination;
