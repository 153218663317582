import { BASE_URL_API } from '../BASE_URL.js';
import { getCurrentPageAction } from '../redux/Reducers/currentPageReducer.js';

const getCurrentPage = (link, navigate) => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'pages/' + link + '/');
        const data = await response.json();

        if (response.status === 404) {
            navigate('/*')
        } else {
            dispatch(getCurrentPageAction(data));
        }
        
    }
}

export default getCurrentPage;