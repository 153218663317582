const initialState = {
  registration_type: "",
  invite_signer: "",
  participant_category: "",
  leg_type: "",
  last_name: "",
  last_name_en: "",
  first_name: "",
  first_name_en: "",
  middle_name: "",
  middle_name_en: "",
  country: "",
  region: "",
  city: "",
  person_inn: "",
  passport_series: "",
  passport_number: "",
  passport_issuer: "",
  passport_date: "",
  birthday_date: "",
  birthday_address: "",
  registration_address: "",
  registration_date: "",
  phone: "",
  photo: {},
  company_organization_form: 1,
  company_name: "",
  company_position: "",
  company_position_en: "",
  company_sphere: "",
  company_inn: "",
  company_kpp: "",
  company_bik: "",
  company_ogrn: "",
  company_bank_account: "",
  company_mail_address: "",
  company_leg_address: "",
  contact_person_fio: "",
  contact_person_phone: "",
  is_agree_info: false,
  is_confirm_data: false,
  is_agree_policy: false,
  is_agree_offer: false,
  arrival_type: "",
  arrival_transport_number: "",
  arrival_date: "",
  arrival_time: "",
  departure_type: "",
  departure_transport_number: "",
  departure_date: "",
  departure_time: "",
  stay_address: "",
  additional_comment: "",
  is_contact_via_manager: "",
};

const SET_NEW_TYPE_OF_REGISTRATION = "SET_NEW_TYPE_OF_REGISTRATION";
const SET_PERSONAL_DATA = "SET_PERSONAL_DATA";
const SET_PASS_DATA = "SET_PASS_DATA";
const SET_CONTACT_DATA = "SET_CONTACT_DATA";
const SET_COMPANY_DATA = "SET_COMPANY_DATA";
const SET_AGREEMENTS = "SET_AGREEMENTS";
const SET_ARRIVAL = "SET_ARRIVAL";
const RESET_DATA = "RESET_DATA";

export const RegInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERSONAL_DATA:
      return {
        ...state,
        leg_type: action.payload.leg_type,
        last_name: action.payload.last_name,
        last_name_en: action.payload.last_name_en,
        first_name: action.payload.first_name,
        first_name_en: action.payload.first_name_en,
        middle_name: action.payload.middle_name,
        middle_name_en: action.payload.middle_name_en,
        country: action.payload.country,
        region: action.payload.region,
        city: action.payload.city,
        invite_signer: action.payload.invite_signer,
        participant_category: action.payload.participant_category,
        person_inn: action.payload.person_inn,
      };
    case SET_PASS_DATA:
      return {
        ...state,
        passport_series: action.payload.passport_series,
        passport_number: action.payload.passport_number,
        passport_issuer: action.payload.passport_issuer,
        passport_date: action.payload.passport_date,
        birthday_date: action.payload.birthday_date,
        birthday_address: action.payload.birthday_address,
        registration_address: action.payload.registration_address,
        registration_date: action.payload.registration_date,
      };
    case SET_CONTACT_DATA:
      return {
        ...state,
        phone: action.payload.phone,
        photo: action.payload.photo,
        is_contact_via_manager: action.payload.is_contact_via_manager,
        contact_person_fio: action.payload.contact_person_fio,
        contact_person_phone: action.payload.contact_person_phone,
      };
    case SET_COMPANY_DATA:
      return {
        ...state,
        company_name: action.payload.company_name,
        company_position: action.payload.company_position,
        company_position_en: action.payload.company_position_en,
        company_sphere: action.payload.company_sphere,
        company_inn: action.payload.company_inn,
        company_kpp: action.payload.company_kpp,
        company_bik: action.payload.company_bik,
        company_ogrn: action.payload.company_ogrn,
        company_bank_account: action.payload.company_bank_account,
        company_mail_address: action.payload.company_mail_address,
        company_leg_address: action.payload.company_leg_address,
        company_organization_form: action.payload.company_organization_form,
      };
    case SET_NEW_TYPE_OF_REGISTRATION:
      return { ...state, registration_type: action.payload };
    case SET_AGREEMENTS:
      return {
        ...state,
        is_agree_info: action.payload.is_agree_info,
        is_confirm_data: action.payload.is_confirm_data,
        is_agree_policy: action.payload.is_agree_policy,
        is_agree_offer: action.payload.is_agree_offer,
      };
    case SET_ARRIVAL:
      return {
        ...state,
        arrival_type: action.payload.arrival_type,
        arrival_transport_number: action.payload.arrival_transport_number,
        arrival_date: action.payload.arrival_date,
        arrival_time: action.payload.arrival_time,
        departure_type: action.payload.departure_type,
        departure_transport_number: action.payload.departure_transport_number,
        departure_date: action.payload.departure_date,
        departure_time: action.payload.departure_time,
        stay_address: action.payload.stay_address,
        additional_comment: action.payload.additional_comment,
      };
    case RESET_DATA:
      return {
        registration_type: "",
        invite_signer: "",
        participant_category: "",
        leg_type: "",
        last_name: "",
        last_name_en: "",
        first_name: "",
        first_name_en: "",
        middle_name: "",
        middle_name_en: "",
        country: "",
        region: "",
        city: "",
        person_inn: "",
        passport_series: "",
        passport_number: "",
        passport_issuer: "",
        passport_date: "",
        birthday_date: "",
        birthday_address: "",
        registration_address: "",
        registration_date: "",
        phone: "",
        photo: {},
        company_organization_form: 1,
        company_name: "",
        company_position: "",
        company_position_en: "",
        company_sphere: "",
        company_inn: "",
        company_kpp: "",
        company_bik: "",
        company_ogrn: "",
        company_bank_account: "",
        company_mail_address: "",
        company_leg_address: "",
        contact_person_fio: "",
        contact_person_phone: "",
        is_agree_info: false,
        is_confirm_data: false,
        is_agree_policy: false,
        is_agree_offer: false,
        arrival_type: "",
        arrival_transport_number: "",
        arrival_date: "",
        arrival_time: "",
        departure_type: "",
        departure_transport_number: "",
        departure_date: "",
        departure_time: "",
        stay_address: "",
        additional_comment: "",
        is_contact_via_manager: "",
      };
    default:
      return state;
  }
};

export const setPersonalRegDataAction = (payload) => ({
  type: SET_PERSONAL_DATA,
  payload,
});
export const setPassRegDataAction = (payload) => ({
  type: SET_PASS_DATA,
  payload,
});
export const setContactRegDataAction = (payload) => ({
  type: SET_CONTACT_DATA,
  payload,
});
export const setCompanyRegDataAction = (payload) => ({
  type: SET_COMPANY_DATA,
  payload,
});
export const setAgreementsRegDataAction = (payload) => ({
  type: SET_AGREEMENTS,
  payload,
});
export const setArrivalDataAction = (payload) => ({
  type: SET_ARRIVAL,
  payload,
});
export const resetRegDataAction = () => ({ type: RESET_DATA });
export const setNewTypeOfRegistrationAction = (payload) => ({
  type: SET_NEW_TYPE_OF_REGISTRATION,
  payload,
});
