import { BASE_URL_API } from '../BASE_URL.js';
import { getDocumentsAction } from '../redux/Reducers/documentsReducer.js';

const getDocuments = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'accounts/documents/', {
            headers: {
                Authorization: "Token " + window.localStorage.token,
            }
        });
        const data = await response.json();

        dispatch(getDocumentsAction(data));
    }
}

export default getDocuments;