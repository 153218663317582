import React, { useState } from "react";
import Container from "../Container/Container";
import PageTemplate from "../../PageTemplate/PageTemplate";
import CabinetNav from "../CabinetNav/CabinetNav";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { ReactComponent as DeleteDocIcon } from "../../../image/icons/delete-doc.svg";
import { ReactComponent as AddDocIcon } from "../../../image/icons/add-doc.svg";

import styles from "./CabinetProfile.module.scss";
import { getExtension } from "../../../functions/getExtension";
import deleteDocument from "../../../functions/deleteDocument";
import getDocuments from "../../../functions/getDocuments";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import Button from '../Button/Button';
import setUserDocument from '../../../functions/setUserDocument';
import { globalTranslate } from "../../../functions/languages";

const CabinetProfileDocs = () => {
  const [docs, setDocs] = useState();
  const [isLoad, setIsLoad] = useState(false);
  const [getNewDocs, setGetNewDocs] = useState(false);
  const [isShowModal, setShowModal] = useState(false);
  const [isFileLoad, setIsFileLoad] = useState(false);
  const [docName, setDocName] = useState();
  const [docFile, setDocFile] = useState();
  const docsRedux = useSelector((state) => state.docs.documents);
  const dispatch = useDispatch();

  const isPaid = useSelector(state => state.registrations.currentRegistration[0].registration_type)
  const offer = useSelector(state => state.regDocs.offer_pdf)

  const currentLang = useSelector(state => state.lang.currentLang)

  const deleteDoc = (id, is_admin) => {
    setIsLoad(true)
    if (is_admin) {
      setShowModal(true);
      return;
    }
    deleteDocument(id, setIsLoad, setGetNewDocs);
    setShowModal(false);
  };

  const badgesLink = 'https://kef2023.ru/media/program/%D0%94%D0%BE%D0%B2%D0%B5%D1%80%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D1%8C.docx'

  useEffect(() => {
    dispatch(getDocuments())
  }, [dispatch])

  useEffect(() => {
    if (getNewDocs) dispatch(getDocuments());
    setGetNewDocs(false);
    setTimeout(() => {
      setIsLoad(false)
    }, 1000)
  }, [getNewDocs]);

  useEffect(() => {
    setDocs(docsRedux);
  }, [docsRedux]);

  const cancelLoad = () => {
    setDocFile()
    setDocName()
    setIsFileLoad(false)
    setShowModal(false)
    setIsLoad(false)
  }

  const sendFile = () => {
    if (docFile && docFile.size > 0 && docName && docName.length > 1) {
      setIsLoad(true)
      setUserDocument({title: docName, file: docFile}, cancelLoad, setGetNewDocs)
    }
  }

  const loadingFile = (event) => {
    setDocFile(event.target.files[0]);
    setIsFileLoad(true);
  };

  return (
    <PageTemplate>
      <Container>
        <CabinetNav />
        <h3>{globalTranslate(currentLang, "Документы")}</h3>
        {isPaid !== 'paid' ? (
          <p>{globalTranslate(currentLang, "Здесь отображаются документы и файлы, которые отправит администратор сайта.")}</p>
        ) : (
          <label htmlFor="loadFile">
          <p className={styles.button}>
            <AddDocIcon />
            {isFileLoad ? (
              <input type="text" autoFocus autoComplete="off" placeholder="Введите название" value={docName} onChange={(e) => setDocName(e.target.value)} />
            ) : (
              "Добавить документ"
            )}
          </p>
        </label>
        )}
        <div className={styles.buttonGroup}>
        {isFileLoad && (
          <>
          {!isLoad && (
            <div style={{marginRight: 20}}>
              <Button backColor={'red'} onClick={cancelLoad}>Отменить</Button>
            </div>)
          }
            <Button disabled={isLoad ? true : false} onClick={sendFile}>{isLoad ? 'Отправляем...' : 'Отправить'}</Button>
          </>
        )}
        </div>
        
        <input
          autoComplete="off"
          id="loadFile"
          type="file"
          className={styles.loadFile}
          onChange={loadingFile}
        />
        <ul className={styles.docsWrap}>
          {docs &&
            docs.map((item, index) => (
              <div key={index}>
              <li
                className={styles.docsItem}
                style={isLoad ? { opacity: 0.3 } : {}}
              >
                <div className={styles.withBackground}>
                  <div className={styles.decor}></div>
                  <div className={styles.text}>
                    <a href={item.file}>
                      {item.title + getExtension(item.file)}
                    </a>
                    <span>
                      {item.is_author_admin
                        ? globalTranslate(currentLang, "Документ загружен администратором")
                        : globalTranslate(currentLang, "Документ загружен пользователем")}
                    </span>
                  </div>
                </div>
                <div
                  className={styles.delete}
                  onClick={() => deleteDoc(item.id, item.is_author_admin)}
                >
                  <DeleteDocIcon />
                  {!window.innerWidth > 1000 && <span>{isLoad ? globalTranslate(currentLang, "Удаляем...") : globalTranslate(currentLang, "Удалить")}</span>}
                </div>
                
              </li>
              {
                isShowModal && (
                  <ModalConfirm
                    text={globalTranslate(currentLang, "Этот документ загружен администратором. За его удаление администрация сайта ответственности не несет. Все равно удалить?")}
                    actionSuccess={() => deleteDoc(item.id, false)}
                    actionCancel={cancelLoad}
                  />
                )
              }
              </div>
            ))}
<div>
            <li
              className={styles.docsItem}
              style={isLoad ? { opacity: 0.3 } : {}}
            >
              <div className={styles.withBackground}>
                <div className={styles.decor}></div>
                <div className={styles.text}>
                  <a href={badgesLink} target={'_blank'}>
                    {'Доверенность на получение аккредитационных бейджей.doc'}
                  </a>
                  <span>{globalTranslate(currentLang, "Документ загружен администратором")}
                  </span>
                </div>
              </div>
              {/* <div
                className={styles.delete}
                onClick={() => deleteDoc(item.id, item.is_author_admin)}
              >
                <DeleteDocIcon />
                {!window.innerWidth > 1000 && <span>{isLoad ? "Удаляем..." : "Удалить"}</span>}
              </div> */}
              
            </li>
            </div>

          {isPaid === 'paid' && offer && (
            <div>
            <li
              className={styles.docsItem}
              style={isLoad ? { opacity: 0.3 } : {}}
            >
              <div className={styles.withBackground}>
                <div className={styles.decor}></div>
                <div className={styles.text}>
                  <a href={offer} target={'_blank'}>
                    {'Договор-оферта' + getExtension(offer)}
                  </a>
                  <span>{globalTranslate(currentLang, "Документ загружен администратором")}
                  </span>
                </div>
              </div>
              {/* <div
                className={styles.delete}
                onClick={() => deleteDoc(item.id, item.is_author_admin)}
              >
                <DeleteDocIcon />
                {!window.innerWidth > 1000 && <span>{isLoad ? "Удаляем..." : "Удалить"}</span>}
              </div> */}
              
            </li>
            </div>
          )}
        </ul>
      </Container>
    </PageTemplate>
  );
};

export default CabinetProfileDocs;
