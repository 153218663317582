import React from 'react';

const Box = ({
    mt = 0,
    mb = 0,
    ml = 0,
    mr = 0,
    margin = 0,
    pt = 0,
    pb = 0,
    pl = 0,
    pr = 0,
    padding = 0,
    children,
    display,
    width,
    maxW,
    minW,
    maxH,
    minH,
    height,
    flexDirection,
    justifyContent,
    alignItems,
    background,
    position,
    radius,


}) => {
  return (
    <div style={{
        display: display,
        marginTop: mt + 'px',
        marginBottom: mb + 'px',
        marginLeft: ml + 'px',
        marginRight: mr + 'px',
        margin: margin + 'px',
        paddingTop: pt + 'px',
        paddingBottom: pb + 'px',
        paddingLeft: pl + 'px',
        paddingRight: pr + 'px',
        padding: padding + 'px',
        width: width,
        maxWidth: maxW + 'px',
        minWidth: minW + 'px',
        height: height + 'px',
        maxHeight: maxH + 'px',
        minHeight: minH + 'px',
        flexDirection: flexDirection,
        justifyContent: justifyContent,
        alignItems: alignItems,
        background: background,
        position: position,
        radius: radius,
        backgroundColor: background,
    }}>
        {children}
    </div>
  )
}

export default Box;