import { BASE_URL_API } from '../BASE_URL.js';
import { getCurrentNewsAction, getMainPageNewsAction, getNewsAction, getNewsBySearchAction } from '../redux/Reducers/newsReducers.js';

export const getNewsMainPage = (en) => {
    return async (dispatch) => {
        const response = await fetch(en ? BASE_URL_API + 'news/?limit=8&offset=0&en=true' : BASE_URL_API + 'news/?limit=8&offset=0');
        const data = await response.json();

        dispatch(getMainPageNewsAction(data));
    }
}

export const getNews = (number, en) => {
    return async (dispatch) => {
        const limit = 7
        const offset = (number - 1) * limit;
        const parameters = number
          ? en ? BASE_URL_API + "news/?limit=7&offset=" + offset + '&en=true' : BASE_URL_API + "news/?limit=7&offset=" + offset
          : en ? BASE_URL_API + "news/?limit=7&offset=0" + '&en=true' : BASE_URL_API + "news/?limit=7&offset=0";
        const response = await fetch(parameters);
        const data = await response.json();

        dispatch(getNewsAction(data));
    }
}

export const getNewsById = (slug, navigate, page) => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'news/' + slug);
        const data = await response.json();

        if (response.status === 200) {
            dispatch(getCurrentNewsAction(data));
            navigate && navigate('/news/' + page + '/' + slug)
        }

    }
}

export const getNewsBySearch = (number, search_string, en) => {
    return async (dispatch) => {
        const limit = 7
        const offset = (number - 1) * limit;
        const parameters = number
          ? en ? BASE_URL_API + "news/search/" + search_string + "/?limit=7&offset=" + offset + '&en=true' : BASE_URL_API + "news/search/" + search_string + "/?limit=7&offset=" + offset
          : en ? BASE_URL_API + "news/search/" + search_string + "/?limit=7&offset=0" + '&en=true' : BASE_URL_API + "news/search/" + search_string + "/?limit=7&offset=0";
        const response = await fetch(parameters);
        const data = await response.json();

        dispatch(getNewsBySearchAction(data));
    }
}