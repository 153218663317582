import { BASE_URL_API } from '../BASE_URL.js';
import { getInvitersAction } from '../redux/Reducers/inviteReducer.js';

const getInviters = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'registrations/invite-signers/');
        const data = await response.json();

        dispatch(getInvitersAction(data));
    }
}

export default getInviters;