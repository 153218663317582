import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SelectInput from "../../../elements/SelectInput/SelectInput";
import TextInput from "../../../elements/TextInput/TextInput";

import styles from "./UserDataForms.module.scss";
import SelectInputLocation from "../../../elements/SelectInput/SelectInputLocation";
import getRegions from "../../../../functions/getRegions";
import getCities from "../../../../functions/getCities";
import { setPersonalRegDataAction } from "../../../../redux/Reducers/regInfoReducers";
import TranslitTextInput from "../../../elements/TextInput/TranslitTextInput";
import { globalTranslate } from "../../../../functions/languages";
import { useLocation } from "react-router-dom";

const PersonalData = ({
  header,
  invite,
  isSMI,
  errorList,
  clearError,
  isValue,
  change,
  isPayed,
  isOnline,
  isDisabled
}) => {
  const [changedData, setChangedData] = useState({});
  const [isCountryChange, setCountryChange] = useState(false);
  const [isRegionChange, setRegionChange] = useState(false);
  const [countryList, setCountryList] = useState()
  const [regionList, setRegionList] = useState()
  const [cityList, setCityList] = useState()
  const [categoryList, setCategoryList] = useState()
  const [inviteFrom, setInviteFrom] = useState()
  const countryListRedux = useSelector((state) => state.location.countries);
  const regionListRedux = useSelector((state) => state.location.regions);
  const cityListRedux = useSelector((state) => state.location.cities);
  const categoryListRedux = useSelector((state) => state.categoryList.category);
  const dispatch = useDispatch();

  const { pathname } = useLocation()

  useEffect(() => {
    if (categoryListRedux.length < 1) return;
    const arr = [];
    if (pathname.includes('company')) {
      categoryListRedux.forEach(item => {
        if (item.is_visible_group) {
          arr.push(item)
        }
      })
    } else {
      categoryListRedux.forEach(item => {
        if (item.is_visible_personal) {
          arr.push(item)
        }
      })
    }
    
    setCategoryList(arr.reverse())
  }, [categoryListRedux, pathname])

  useEffect(() => {
    if (countryListRedux.length < 1) return
    setCountryList(countryListRedux)
  }, [countryListRedux])

  useEffect(() => {
    if (!regionListRedux) return
    setRegionList(regionListRedux)
  }, [regionListRedux])
  useEffect(() => {
    if (!cityListRedux) return
    setCityList(cityListRedux)
  }, [cityListRedux])

  const data = useSelector((state) => state.regInfo);

  const [userData, setUserData] = useState({
    leg_type: "",
    last_name: "",
    last_name_en: "",
    first_name: "",
    first_name_en: "",
    middle_name: "",
    middle_name_en: "",
    country: "",
    region: "",
    city: "",
    invite_signer: "",
    participant_category: "",
    person_inn: "",
  });

  const formOfOrganization = [
    {name: "Юридическое лицо", name_en: "Legal person", id: 1},
    {name: "Физическое лицо", name_en: "Natural person", id: 2}
  ];


  useEffect(() => {
    if (!isValue) return;
    setUserData({
      ...userData,
      leg_type: isValue.leg_type,
      invite_signer: isValue.invite_signer,
      last_name: isValue.last_name,
      last_name_en: isValue.last_name_en,
      first_name: isValue.first_name,
      first_name_en: isValue.first_name_en,
      middle_name: isValue.middle_name,
      middle_name_en: isValue.middle_name_en,
      participant_category: isValue.participant_category,
      country: isValue.country,
      region: isValue.region,
      city: isValue.city,
      person_inn: isValue.person_inn,
    });
  }, [isValue]);

  useEffect(() => {
    if (!change) return;
    change(changedData);
  }, [changedData]);

  const setStatus = (data) => {
    setUserData({ ...userData, leg_type: data.name });
    setChangedData({ ...changedData, leg_type: data.name });
    clearError(["Выберите юридический статус участника"]);
  };
  const setFirstName = (data) => {
    setUserData({ ...userData, first_name: data });
    setChangedData({ ...changedData, first_name: data });
    clearError(["Введите имя участника", 'Поле "Имя" должно быть длинее 2 символов']);
  };
  const setFirstNameEn = (data) => {
    setUserData({ ...userData, first_name_en: data });
    setChangedData({ ...changedData, first_name_en: data });
    clearError(["Введите имя участника на английском", 'Поле "Имя" должно быть длинее 2 символов']);
  };
  const setLastName = (data) => {
    setUserData({ ...userData, last_name: data });
    setChangedData({ ...changedData, last_name: data });
    clearError(["Введите фамилию участника", 'Поле "Фамилия" должно быть длинее 1 символа']);
  };
  const setLastNameEn = (data) => {
    setUserData({ ...userData, last_name_en: data });
    setChangedData({ ...changedData, last_name_en: data });

    clearError(["Введите фамилию участника на английском", '"Фамилия на английском" не может содержать кирилицу, цифры и спецсимволы']);
  };
  const setMiddleName = (data) => {
    setUserData({ ...userData, middle_name: data });
    setChangedData({ ...changedData, middle_name: data });

    clearError("Введите отчество участника");
  };
  const setMiddleNameEn = (data) => {
    setUserData({ ...userData, middle_name_en: data });
    setChangedData({ ...changedData, middle_name_en: data });
  };
  const setCategoryOfParticipants = (data) => {
    setUserData({ ...userData, participant_category: data.id });
    setChangedData({ ...changedData, participant_category: data.id });

    clearError("Выберите категорию участника");
  };
  const setInvite = (data) => {
    setUserData({ ...userData, invite_signer: data.id });
    setChangedData({ ...changedData, invite_signer: data.id });

    clearError("Выберите приглашение");
  };
  const setCountry = (data) => {
    setUserData({ ...userData, country: data.name, region: "", city: "" });
    setChangedData({ ...changedData, country: data.name });
    dispatch(getRegions(countryList.filter(item => item.name === data.name || item.name_en === data.name)[0].id))
    clearError("Укажите страну");
  };  
  const setRegion = (data) => {
    setUserData({ ...userData, region: data.name, city: "" });
    setChangedData({ ...changedData, region: data.name });
    dispatch(getCities(regionList.filter(item => item.name === data.name)[0].id))
    clearError("Укажите регион");
  }; 
  const setCity = (data) => {
    setUserData({ ...userData, city: data.name });
    setChangedData({ ...changedData, city: data.name });
    clearError("Укажите населенный пункт");
  };
  const setPersonalINN = (data) => {
    setUserData({ ...userData, person_inn: data });
    setChangedData({ ...changedData, person_inn: data });

    clearError("Укажите личный ИНН");
  }

  const invitationFormRedux = useSelector(state => state.inviters.inviters)

  useEffect(() => {
    if (!invitationFormRedux) return;
    setInviteFrom(invitationFormRedux)
  }, [invitationFormRedux])
 

  // const inviteFrom = [
  //   { name: "Губернатора Красноярского края - А. В. Усса",
  //     name_en: 'Governor of the Krasnoyarsk Territory - A. V. Uss', 
  //     id: 1, 
  //   },
  //   {
  //     name: "Первого заместителя Губернатора Красноярского края - Председателя Правительства Красноярского края - Ю. А. Лапшина",
  //     name_en: 'First Deputy Governor of the Krasnoyarsk Territory - Chairman of the Government of the Krasnoyarsk Territory - Y. A. Lapshin',
  //     id: 2,
  //   },
  //   {
  //     name: "Первого заместителя Губернатора Красноярского края - руководителя Администрации Губрнатора Красноярского края - С. А. Пономаренко",
  //     name_en: 'First Deputy Governor of the Krasnoyarsk Territory - Head of the Administration of the Governor of the Krasnoyarsk Territory - S. A. Ponomarenko',
  //     id: 3,
  //   },
  //   {
  //     name: "Заместителя председателя Правительства Красноярского края - С. В. Верещагина",
  //     name_en: 'Deputy Chairman of the Government of the Krasnoyarsk Territory - S. V. Vereshchagin',
  //     id: 4,
  //   },
  //   {
  //     name: "Министра экономики и регионального развития Красноярского края - А. К. Гарнец",
  //     name_en: 'Minister of Economy and Regional Development of the Krasnoyarsk Territory - A. K. Garnets',
  //     id: 5,
  //   },
  //   { name: 'АНО "Корпорация развития Енисейской Сибири"',
  //     name_en: 'ANO "Yenisei Siberia Development Corporation"', 
  //     id: 6, 
  //   },
  // ];

  const isManager = useSelector((state) => state.user.is_manager);
  const isManagerReg = useSelector(
    (state) => state.registrations.registrationCounter
  );
  const currentLang = useSelector(state => state.lang.currentLang)

  useEffect(() => {
    dispatch(setPersonalRegDataAction(userData));
  }, [userData]);

  return (
    <div>
      <h3> {header ? header : globalTranslate(currentLang, "Персональные данные")}</h3>
      {!invite || isOnline ? (
        !isSMI &&
        (isManagerReg === 0 && isPayed) && (
          <SelectInput
            isDisabled={isDisabled}
            labelText={"Ваш статус"}
            options={formOfOrganization}
            isValue={userData.leg_type ? formOfOrganization.filter(item => currentLang === 'ru' ? item.name === userData.leg_type : item.name_en === userData.leg_type)[0].id : ''}
            onChange={setStatus}
            twoKeys={true}
            error={
              errorList &&
              errorList.includes("Выберите юридический статус участника")
                ? true
                : false
            }
          />
        )
      ) : (!isManager && (
        <>
          <SelectInput
            isDisabled={isDisabled}
            twoKeys={true}
            labelText={"Категория участника"}
            options={categoryList}
            isValue={userData.participant_category}
            onChange={setCategoryOfParticipants}
            error={
              errorList && errorList.includes("Выберите категорию участника")
                ? true
                : false
            }
          />
          <SelectInput
            isDisabled={isDisabled}
            twoKeys={true}
            labelText={"Приглашение за подписью"}
            options={inviteFrom}
            isValue={userData.invite_signer}
            onChange={setInvite}
            error={
              errorList && errorList.includes("Выберите приглашение")
                ? true
                : false
            }
          />
        </>
      ))}

      <div className={styles.one_one_template}>
        <div
          style={{ boxSizing: "border-box" }}
          className={styles.one_one_item}
        >
          <TextInput
            isDisabled={isDisabled}
            labelText={"Фамилия"}
            onChange={setLastName}
            isValue={userData.last_name}
            error={
              errorList &&
              (errorList.includes("Введите фамилию участника") 
              || errorList.includes(
                  'Поле "Фамилия" должно быть длинее 1 символа'
                ))
                ? true
                : false
            }
          />
        </div>
        <div className={styles.one_two_item}>
          <TranslitTextInput
            isDisabled={isDisabled}
            isValue={userData.last_name}
            labelText={"Фамилия на английском"}
            onChange={setLastNameEn}
            error={
              errorList &&
              (errorList.includes("Введите фамилию участника на английском") ||
                errorList.includes(
                  '"Фамилия на английском" не может содержать кирилицу, цифры и спецсимволы'
                ))
                ? true
                : false
            }
          />
        </div>
      </div>

      <div className={styles.one_one_template}>
        <div
          style={{ boxSizing: "border-box" }}
          className={styles.one_one_item}
        >
          <TextInput
            labelText={"Имя"}
            isDisabled={isDisabled}
            onChange={setFirstName}
            isValue={userData.first_name}
            error={
              errorList &&
              (errorList.includes("Введите имя участника")
              || errorList.includes('Поле "Имя" должно быть длинее 2 символов'))
                ? true
                : false
            }
          />
        </div>
        <div className={styles.one_two_item}>
          <TranslitTextInput
            isDisabled={isDisabled}
            isValue={userData.first_name}
            labelText={"Имя на английском"}
            onChange={setFirstNameEn}
            error={
              errorList &&
              (errorList.includes("Введите имя участника на английском") ||
                errorList.includes(
                  '"Имя на английском" не может содержать кирилицу, цифры и спецсимволы'
                ))
                ? true
                : false
            }
          />
        </div>
      </div>

      <div className={styles.one_one_template}>
        <div
          style={{ boxSizing: "border-box" }}
          className={styles.one_one_item}
        >
          <TextInput
            labelText={"Отчество"}
            isDisabled={isDisabled}
            onChange={setMiddleName}
            isValue={userData.middle_name}
            error={
              errorList && errorList.includes("Введите отчество участника")
                ? true
                : false
            }
          />
        </div>
        <div className={styles.one_two_item}>
          <TranslitTextInput
            // disabled
            isDisabled={isDisabled}
            isValue={userData.middle_name}
            labelText={"Отчество на английском"}
            onChange={setMiddleNameEn}
          />
        </div>
      </div>

      <SelectInputLocation
        isDisabled={isDisabled}
        labelText={"Страна"}
        onClick={setCountryChange}
        options={countryList}
        onChange={setCountry}
        isValue={userData.country}
        error={errorList && errorList.includes("Укажите страну") ? true : false}
      />
      {userData.country !== "" && (
        <SelectInputLocation
        isDisabled={isDisabled}
          onClick={setRegionChange}
          labelText={"Регион"}
          options={regionList}
          onChange={setRegion}
          isValue={userData.region}
          error={
            errorList && errorList.includes("Укажите регион") ? true : false
          }
        />
      )}
      {userData.region !== "" && (
        <SelectInputLocation
        isDisabled={isDisabled}
          labelText={"Город"}
          options={cityList}
          onChange={setCity}
          isValue={userData.city}
          error={
            errorList && errorList.includes("Укажите населенный пункт")
              ? true
              : false
          }
        />
      )}
      {userData.leg_type && (userData.leg_type === 'Физическое лицо' || userData.leg_type === 'Natural person' ) && (
        <TextInput
        isDisabled={isDisabled}
          labelText={'ИНН'}
          onChange={setPersonalINN}
          isValue={userData.person_inn}
          error={
            errorList && errorList.includes("Укажите личный ИНН")
              ? true
              : false
          }
        />        
      )}
    </div>
  );
};

export default PersonalData;
