import React from 'react';
import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate';
import ContentContainer from '../../elements/Container/ContentContainer';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { setNewPassword } from '../../../functions/setNewPassword';
import TextInput from '../../elements/TextInput/TextInput';
import Button from '../../elements/Button/Button';
import resetPasswordConfirm from '../../../functions/resetPasswordConfirm';

const ResetPasswordConfirm = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [resetParams, setResetParams] = useState({
    uid: params.id,
    token: params.token,
    new_password: '' 
  })
  const [isLoad, setIsLoad] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorList, setErrorList] = useState([])


  const setNewPassword = (data) => setResetParams({...resetParams, new_password: data})

  const sendNewPassword = () => {
    setErrorList([])
    setIsLoad(true)
    resetPasswordConfirm(resetParams, setErrorList, setIsLoad, setIsSuccess)
  }

  useEffect(() => {
    if (!isSuccess) return;
    setTimeout(() => {
      navigate('/login');
    }, 5000)
  }, [isSuccess])



  return (
    <PageTemplate>
        <Container>
            <ContentContainer>
                <h3>{isSuccess ? 'Пароль успешно изменен' : 'Введите новый пароль'}</h3>
                {isSuccess && <p>Вы будете перенаправлены на страницу входа через 5 секунд...</p>}
                {!isSuccess && <TextInput type={'isPass'} labelText={"Введите новый пароль"} onChange={setNewPassword} />}
                {errorList.length > 0 && errorList.map((item, index) => <p key={index} style={{color: 'red'}}>{item}</p>)}
                {!isSuccess && <Button onClick={sendNewPassword}>{isLoad ? 'Отправляем запрос' : 'Сменить пароль'}</Button>}
            </ContentContainer>
        </Container>
    </PageTemplate>
  )
}

export default ResetPasswordConfirm;