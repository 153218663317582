import React, { useState, useEffect } from "react";

import styles from "./TextArea.module.scss";
import Box from "../Box/Box";
import { useSelector } from 'react-redux';
import { globalTranslate } from "../../../functions/languages";

const TextArea = ({
  onChange,
  labelText,
  isValue,
  isDisabled,
  dateValue,
  onBlur,
  withErrorNote,
  errorText,
}) => {
  const [isInputActive, setIsInputActive] = useState(false);
  const [value, setValue] = useState(isValue ? isValue : "");
  const [error, setError] = useState(withErrorNote);

  const currentLang = useSelector(state => state.lang.currentLang)

  const onChangeHandler = (event) => {
    setValue(event.target.value);
    if (isDisabled) return;
    // onChange(value);
  };

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  const inputOnFocusHandler = () => {
    if (!value && !isDisabled) setIsInputActive(false);
    if (!value && isDisabled) setIsInputActive(true);
    onBlur && onBlur();
  };

  useEffect(() => {
    if (!isValue) return;
    setValue(isValue);
  }, [isValue]);

  useEffect(() => {
    if (isDisabled) {
      !value && setIsInputActive(true);
      return;
    }
    onChange(value);
  }, [value]);

  useEffect(() => {
    if (value !== "") {
      setIsInputActive(true);
    }
  }, [value]);

  return (
    <Box maxW={700}>
      <div className={styles.wrapper}>
        <span
          className={isInputActive ? styles.activeLabel : styles.label}
          onClick={() => setIsInputActive(true)}
        >
          {globalTranslate(currentLang, labelText)}
        </span>
        <label
          className={styles.labelWrapper}
          onClick={() => setIsInputActive(true)}
          onFocus={() => setIsInputActive(true)}
        >
          <textarea
            style={{minHeight: 45}}
            type={"text"}
            disabled={isDisabled}
            value={isDisabled ? dateValue : value}
            onChange={onChangeHandler}
            onFocus={onBlur && (() => onBlur())}
            onBlur={inputOnFocusHandler}
          ></textarea>
          {withErrorNote && (
            <div className={styles.error}>
              {" "}
              <p>{errorText}</p>{" "}
            </div>
          )}
        </label>
      </div>
    </Box>
  );
};

export default TextArea;
