import React from "react";
import DG from "2gis-maps";
import styles from "./HotelsMap.module.scss";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

import "./HotelsMap.scss";

import iconMarker from "../../../image/icons/marker.svg";
import iconActiveMarker from "../../../image/icons/active-marker.svg";
// import activeMarker from 'https://maps.api.2gis.ru/2.0/img/DGCustomization__markerHover.png';

const HotelsMap = ({ currentHotel, setCurrentHotel, hotelList }) => {

  const currentLang = useSelector((state) => state.lang.currentLang)

  useEffect(() => {
    if (!hotelList) return;

    let map = DG.map("map", {
      center: [currentHotel.marker_latitude, currentHotel.marker_longitude],
      zoom: 13,
      zoomControl: false,
      fullscreenControl: false,
    });

    let marker = DG.icon({
      iconUrl: iconMarker,
      iconSize: [30, 30],
    });
    let activeMarker = DG.icon({
      iconUrl: iconActiveMarker,
      iconSize: [30, 30],
    });

    if (window.innerWidth < 768) {
        hotelList.forEach(item => {
            const lat = currentHotel.marker_latitude;
            const lon = currentHotel.marker_longitude;

            if (item.marker_latitude === lat && item.marker_longitude === lon) {
              DG.marker([item.marker_latitude, item.marker_longitude], {icon: activeMarker})
              .on("click", (e) => {
                  setCurrentHotel(item)
                  const activeHotel = document.getElementById('hotelList')
                  activeHotel.scrollIntoView({behavior: "smooth", block: "center"})
                })
              .addTo(map)
              .bindPopup(currentLang === 'ru' ? item.title : item.title_en)
              .openPopup();
            } else {
              DG.marker([item.marker_latitude, item.marker_longitude], {icon: marker})
              .on("click", (e) => {
                  setCurrentHotel(item)
                  const activeHotel = document.getElementById('hotelList')
                  activeHotel.scrollIntoView({behavior: "smooth", block: "center"})
                })
              .addTo(map)
              .bindPopup(currentLang === 'ru' ? item.title : item.title_en)
            }

            
        });
    } else {
        hotelList.forEach(item => {
            const lat = currentHotel.marker_latitude;
            const lon = currentHotel.marker_longitude;
            if (item.marker_latitude === lat && item.marker_longitude === lon) {
              DG.marker([item.marker_latitude, item.marker_longitude], {icon: activeMarker})
              .on("click", (e) => {
                  setCurrentHotel(item)
                })
              .addTo(map)
              .bindPopup(currentLang === 'ru' ? item.title : item.title_en)
              .openPopup();
            } else {
              DG.marker([item.marker_latitude, item.marker_longitude], {icon: marker})
              .on("click", (e) => {
                  setCurrentHotel(item)
                })
              .addTo(map)
              .bindPopup(currentLang === 'ru' ? item.title : item.title_en)
            }
            
        });
    }
    return () => map.remove();
  }, [hotelList, currentHotel]);


  return (
    <div className={styles.map} style={{ marginBottom: 30 }}>
      <div id="map" style={{ width: "100%", height: 250 }}></div>
    </div>
  );
};

export default HotelsMap;
