import React, { useRef, useState } from "react";
import styles from "./StreamsPage.module.scss";
import PageTemplate from "../../PageTemplate/PageTemplate";
import Container from "../../elements/Container/Container";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getStreams } from "../../../functions/getStreams";
import { Link } from "react-router-dom";
import fakeLive from "../../../image/backgrounds/live.svg";

import Live_ru from "../../../image/live_ru.png";
import Live_en from "../../../image/live_en.png";

import liveOn from "../../../image/icons/live-on.gif";

import defaultStyles from "./defaultStyles.scss";
import { globalTranslate } from "../../../functions/languages";

const StreamsPage = () => {
  const [streams, setStreams] = useState();
  const [videoHeight, setVideoHeight] = useState(100);

  const dispatch = useDispatch();
  const streamsRedux = useSelector((state) => state.streams.streams);
  const currentLang = useSelector((state) => state.lang.currentLang);

  const streamItemID = document.getElementById("streamItem");

  useEffect(() => {
    if (!streamItemID) return;
    setVideoHeight(streamItemID.clientWidth / 1.77);
  }, [streamItemID]);

  useEffect(() => {
    dispatch(getStreams());
  }, [dispatch]);

  useEffect(() => {
    if (!streamsRedux) return;
    setStreams(streamsRedux);
  }, [streamsRedux]);

  return (
    <PageTemplate>
      <Container>
        <h3>{(!streams || streams.length < 1) ? globalTranslate(currentLang, "Нет активных трансляций") : globalTranslate(currentLang, "Прямые трансляции")}</h3>
        {streams && streams.length > 0 && (
          <div className={styles.wrap}>
            {currentLang === "ru"
              ? streams.map((item, index) => (
                  <Link
                    to={"/live/" + item.id}
                    key={index}
                    className={styles.streamItem}
                    id="streamItem"
                  >
                    <div className={styles.video} id="streamItem1">
                      <img src={Live_ru} alt="" />
                    </div>
                    <p>
                      <img src={liveOn} />
                      {item.title}
                    </p>
                  </Link>
                ))
              : streams.map((item, index) => (
                item.link_en &&
                  <Link
                    to={"/live/" + item.id}
                    key={index}
                    className={styles.streamItem}
                    id="streamItem"
                  >
                    <div className={styles.video} id="streamItem1">
                      <img src={Live_en} alt="" />
                    </div>
                    <p>
                      <img src={liveOn} />
                      {item.title_en}
                    </p>
                  </Link>
                ))}
          </div>
        )}
      </Container>
    </PageTemplate>
  );
};

export default StreamsPage;
