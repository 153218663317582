import { combineReducers } from "redux";

import { newsReducers } from './Reducers/newsReducers';
import { photosReducers } from "./Reducers/photosReducers";
import { indexConstanceReducer } from './Reducers/indexConstanceReducer';
import { locationsReducers } from './Reducers/locationsReducers';
import { RegInfoReducer } from "./Reducers/regInfoReducers";
import { AuthReducer } from './Reducers/AuthReducer';
import { currentUserReducer } from "./Reducers/currentUserReducer";
import { companyRegCounterReducer } from './Reducers/companyRegCounter';
import { companySpheresReducer } from './Reducers/companySpheresReducer';
import { errorsReducer } from './Reducers/errorsReducer';
import { categoryOfParticipantsReducer } from './Reducers/categoryOfParticipantsReducer';
import { programReducer } from './Reducers/programReducer';
import { organisationFormsReducer } from './Reducers/organisationFormsReducer';
import { carsReducer } from './Reducers/carsReducer';
import { documentsReducer } from './Reducers/documentsReducer';
import { otherDocsReducer } from "./Reducers/otherDocsReducer";
import { movementTypeReducer } from './Reducers/movementTypeReducer';
import { contactsReducer } from "./Reducers/contactsReducer";
import { welcomeWordsReducer } from './Reducers/welcomeWordsReducer';
import { socialsReducer } from './Reducers/socialsReducer';
import { pagesReducer } from './Reducers/pagesReducer';
import { currentPageReducer } from './Reducers/currentPageReducer';
import { photosReducer } from "./Reducers/photosReducer";
import { videosReducer } from './Reducers/videosReducer';
import { menuReducer } from './Reducers/menuReducer';
import { registrationTypeReducer } from './Reducers/registrationTypeReducer';
import { langReducer } from './Reducers/langReducer';
import { hotelsReducer } from './Reducers/hotelsReducer';
import { partnersReducer } from './Reducers/partnersReducer';
import { mediaPartnersReducer } from './Reducers/mediaPartnersReducer';
import { slotsReducer } from './Reducers/slotsReducer';
import { inviteReducer } from "./Reducers/inviteReducer";
import { programFilesReducer } from './Reducers/programFilesReducer';
import { steamsReducer } from './Reducers/steamsReducer';


export const rootReducer = combineReducers({
    news: newsReducers,
    albums: photosReducers,
    indexConstance: indexConstanceReducer,
    location: locationsReducers,
    regInfo: RegInfoReducer,
    isAuth: AuthReducer,
    user: currentUserReducer,
    registrations: companyRegCounterReducer,
    spheres: companySpheresReducer,
    error: errorsReducer,
    categoryList: categoryOfParticipantsReducer,
    program: programReducer,
    organisation_forms: organisationFormsReducer,
    cars: carsReducer,
    docs: documentsReducer,
    regDocs: otherDocsReducer,
    movement: movementTypeReducer,
    contacts: contactsReducer,
    welcomeWords: welcomeWordsReducer,
    socials: socialsReducer,
    pages: pagesReducer,
    currentPage: currentPageReducer,
    photos: photosReducer,
    videos: videosReducer,
    menuList: menuReducer,
    registrationType: registrationTypeReducer,
    lang: langReducer,
    hotels: hotelsReducer,
    partners: partnersReducer,
    mediaPartners: mediaPartnersReducer,
    slots: slotsReducer,
    inviters: inviteReducer,
    programFiles: programFilesReducer,
    streams: steamsReducer,
})
