import React from "react";
import { useParams } from "react-router-dom";
import Container from "../../elements/Container/Container";
import ContentContainer from "../../elements/Container/ContentContainer";
import PageTemplate from "../../PageTemplate/PageTemplate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAlbumBySlug } from "../../../functions/getAlbums";
import { useSelector } from "react-redux";
import { getAlbumsBySlug } from "../../../functions/getPhotos";

import styles from "./AlbumsPage.module.scss";
import ModalPhoto from "./ModalPhoto";
import { useState } from "react";
import ReadMoreLink from "../../elements/ReadMoreLink/ReadMoreLink";
import { useRef } from "react";
import { globalTranslate } from "../../../functions/languages";

const AlbumsPage = () => {
  const [isShowModal, setShowModal] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState();
  const [hideNext, setHideNext] = useState(false);
  const [hidePrev, setHidePrev] = useState(true);
  const [itemWidth, setItemWidth] = useState(255);
  const [isError, setError] = useState();
  const [isLoad, setLoad] = useState(true);

  const params = useParams();
  const dispatch = useDispatch();
  const container = useRef();

  useEffect(() => {
    dispatch(getAlbumsBySlug(params.album, setError));
  }, [dispatch]);

  const currentAlbums = useSelector((state) => state.photos.currentAlbums);
  const currentLang = useSelector(state => state.lang.currentLang);

  const openModal = (file) => {
    setCurrentPhoto(file);
    setShowModal(true);
  };

  const resize = () => {
    let itemsInStroke = 4;
    if (window.innerWidth > 1700) itemsInStroke = 4;
    if (window.innerWidth <= 1700) itemsInStroke = 3;
    if (window.innerWidth < 1300) itemsInStroke = 2;
    if (window.innerWidth < 768) itemsInStroke = 1;

    const width = container.current.clientWidth / itemsInStroke - 25;

    setItemWidth(width);
  };

  window.addEventListener("resize", resize);

  useEffect(() => {
    resize();
  }, []);

  useEffect(() => {
    if (currentPhoto) {
      const currentIndex = currentAlbums.photos.findIndex(
        (el) => el.file === currentPhoto
      );
      if (currentIndex === 0) {
        setHidePrev(true);
        setHideNext(false);
      } else if (currentIndex === currentAlbums.photos.length - 1) {
        setHidePrev(false);
        setHideNext(true);
      } else {
        setHidePrev(false);
        setHideNext(false);
      }
    }
  }, [currentPhoto]);

  const nextPhoto = () => {
    const currentIndex = currentAlbums.photos.findIndex(
      (el) => el.file === currentPhoto
    );
    setCurrentPhoto(currentAlbums.photos[currentIndex + 1].file);
  };
  const prevPhoto = () => {
    const currentIndex = currentAlbums.photos.findIndex(
      (el) => el.file === currentPhoto
    );
    setCurrentPhoto(currentAlbums.photos[currentIndex - 1].file);
  };

  return (
    <PageTemplate>
      <Container>
        <ContentContainer>
          {!isError ? (
            <>
              <div className={styles.head} ref={container}>
                <h3>{currentLang === 'ru' ? currentAlbums.title : currentAlbums.title_en}</h3>
                <ReadMoreLink
                  text={globalTranslate(currentLang, "Вернуться в галерею события")}
                  link={"/gallery/photos/"}
                />
              </div>
              {isShowModal && (
                <ModalPhoto
                  file={currentPhoto}
                  hideNext={hideNext}
                  hidePrev={hidePrev}
                  next={nextPhoto}
                  prev={prevPhoto}
                  close={() => setShowModal(false)}
                />
              )}
              <div className={styles.photosWrapper}>
                {currentAlbums &&
                  currentAlbums.photos.map((item, index) => (
                    <div key={index} className={styles.imgWrap}>
                    <img
                      src={item.thumbnail}
                      alt={currentLang === 'ru' ? currentAlbums.title : currentAlbums.title_en}
                      style={{ width: itemWidth }}
                      className={styles.mainImg}
                      onClick={() => openModal(item.file)}
                    />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <div className={styles.head} ref={container}>
              <h3>Не удалось найти данный альбом</h3>
              <ReadMoreLink
                text={"Вернуться в галерею события"}
                link={"/gallery/photos/"}
              />
            </div>
          )}
        </ContentContainer>
      </Container>
    </PageTemplate>
  );
};

export default AlbumsPage;
