import React from 'react'
import Container from '../../elements/Container/Container'
import ContentContainer from '../../elements/Container/ContentContainer'
import PageTemplate from '../../PageTemplate/PageTemplate'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import VideosItem from '../GalleryPage/Videos/VideosItem';

import styles from './AllVideos.module.scss';
import { useDispatch } from 'react-redux';
import { getFirstAlbums } from '../../../functions/getPhotos';
import { getFirstVideos } from '../../../functions/getVideos';
import ModalVideo from '../GalleryPage/Videos/ModalVideo';
import { useRef } from 'react';
import ReadMoreLink from '../../elements/ReadMoreLink/ReadMoreLink';
import { globalTranslate } from '../../../functions/languages';

const AllVideos = () => {
  const [isShowModal, setShowModal] = useState(false);
  const [currentLink, setCurrentLink] = useState('')

  const [localVideos, setVideos] = useState()
  const [filteredVideos, setFilteredVideos] = useState()
  const [days, setDays] = useState()
  const [currentDay, setCurrentDay] = useState()
  const [itemWidth, setItemWidth] = useState(250) 

  const allVideos = useSelector(state => state.videos.videos)
  const currentLang = useSelector(state => state.lang.currentLang);

  const dispatch = useDispatch();
  const container = useRef()

  
  const resize = () => {
    let itemsInStroke = 4
    if (window.innerWidth > 1700) itemsInStroke = 4
    if (window.innerWidth <= 1700) itemsInStroke = 3
    if (window.innerWidth < 1300) itemsInStroke = 2
    if (window.innerWidth < 768) itemsInStroke = 1
    
    const width = container.current.clientWidth / itemsInStroke - 25

    setItemWidth(width)
  }

  window.addEventListener('resize', resize)

  useEffect(() => {
    resize()
  }, [])

  useEffect(() => {
    dispatch(getFirstVideos())
  }, [dispatch])

  useEffect(() => {
    if (!allVideos) return;
    const newArray = []
    const eventsName = []
    allVideos.results.forEach(item => {
        if (eventsName.includes(item.year) === false) {
            eventsName.push(item.year)
        }
        item.videos.forEach(point => {
            newArray.push(point)
        })
    })
    eventsName.sort((a,b) => { 
      return b - a
    })
    setDays(eventsName)
    setCurrentDay(eventsName[0])
    setVideos(newArray)
  }, [allVideos])

  const openModal = (link) => {
    setCurrentLink(link)
    setShowModal(true)
  }

  useEffect(() => {
    if (!currentDay) return;
    if (!allVideos) return
    const newArray = []
    const filtered = allVideos.results.filter(item => item.year === currentDay);

    filtered.forEach(point => {
            point.videos.forEach(item => newArray.push(item))
        })
    setFilteredVideos(newArray)
  }, [currentDay])


  return (
    <PageTemplate>
        <Container>
            <ContentContainer>
                <h3 className={styles.pageTitle}>{globalTranslate(currentLang, 'Все видео')}</h3>
                <div style={{margin: '40px 0'}}>
                  <ReadMoreLink text={globalTranslate(currentLang, "Галерея")} link={'/gallery'} />
                </div>
                {isShowModal && <ModalVideo link={currentLink} close={() => setShowModal(false)} /> }
                <div className={styles.daysWrapper} ref={container}>
                    {days && days.map((item, index) => <p key={index} style={currentDay === item ? {fontFamily: 'Gotham Pro Bold', color: '#047EC2'} : {}} onClick={() => setCurrentDay(item)}>{item}</p>)}
                </div>
                <div className={styles.videosWrapper}>
                    {localVideos && !currentDay && localVideos.map((item, index) => <VideosItem key={index} item={item} width={itemWidth} onClick={openModal} />)}
                    {localVideos && currentDay && filteredVideos && filteredVideos.map((item, index) => <VideosItem key={index} item={item} width={itemWidth} onClick={openModal} />)}
                </div>
                <div style={{margin: '40px 0'}}>
                  <ReadMoreLink text={globalTranslate(currentLang, "Галерея")} link={'/gallery'} />
                </div>
            </ContentContainer>
        </Container>
    </PageTemplate>
  )
}

export default AllVideos