import React, { useState } from "react";
import TextInput from "../../../elements/TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  setArrivalDataAction,
  setContactRegDataAction,
} from "../../../../redux/Reducers/regInfoReducers";
import Box from "../../../elements/Box/Box";
import SelectInput from "../../../elements/SelectInput/SelectInput";
import TextArea from "../../../elements/TextArea/TextArea";

import activeCheckbox from '../../../../image/icons/active-checkbox.svg';
import disactiveCheckbox from '../../../../image/icons/disactive-checkbox.svg';

import styles from "./UserDataForms.module.scss";
import { useRef } from "react";
import CalendarInput from '../../../elements/CalendarInput/CalendarInput';
import TimeInput from '../../../elements/TimeInput/TimeInput';
import { globalTranslate } from "../../../../functions/languages";


const ArrivalInfo = () => {
  const dispatch = useDispatch();

  const currentLang = useSelector(state => state.lang.currentLang)


  const [arrivalData, setArrivalData] = useState({
    arrival_type: "",
    arrival_transport_number: "",
    arrival_date: "",
    arrival_time: "",
    departure_type: "",
    departure_transport_number: "",
    departure_date: "",
    departure_time: "",
    stay_address: "",
    additional_comment: "",
  });

  const arrivalType = [
    { name: "Самолетом", id: 3 },
    { name: "Железнодорожным транспортом", id: 2 },
    { name: "Автомобилем", id: 1 },
  ];

  const flyRef = useRef()
  const trainRef = useRef()
  const autoRef = useRef()

  const departureFlyRef = useRef()
  const departureTrainRef = useRef()
  const departureAutoRef = useRef()

  const setArrivalType = (id) => {
    setArrivalData({ ...arrivalData, arrival_type: id, arrival_transport_number: "", arrival_date: "", arrival_time: ""  });
  }
  const setArrivalFlyNumber = (data) => {
    setArrivalData({ ...arrivalData, arrival_transport_number: data });
  }
  const setArrivalDate = (data) => {
    setArrivalData({ ...arrivalData, arrival_date: data });
  }
  const setArrivalTime = (data) => {
    setArrivalData({ ...arrivalData, arrival_time: data === 'NaN:NaN' ? "" : data });
  }
  const setDepartureType = (id) => {
    setArrivalData({ ...arrivalData, departure_type: id, departure_transport_number: "", departure_date: "", departure_time: ""  });
  }
  const setDepartureFlyNumber = (data) => {
    setArrivalData({ ...arrivalData, departure_transport_number: data });
  }
  const setDepartureDate = (data) => {
    setArrivalData({ ...arrivalData, departure_date: data });
  }
  const setDepartureTime = (data) => {
    setArrivalData({ ...arrivalData, departure_time: data === 'NaN:NaN' ? "" : data });
  }

  const setKrasnoyarskAdres = (data) =>
    setArrivalData({ ...arrivalData, stay_address: data });
  const setComments = (data) =>
    setArrivalData({ ...arrivalData, additional_comment: data });

  useEffect(() => {
    dispatch(setArrivalDataAction(arrivalData));
  }, [arrivalData]);

  return (
    <Box maxW={700}>
      <h3>{globalTranslate(currentLang, "Информация о прибытии и проживании")}</h3>
      <p style={{fontFamily: 'Gotham Pro Bold'}}>{globalTranslate(currentLang, "На чем прибываете?")}</p>
      <div className={styles.arrivalType}>
        <div className={styles.item}>
          <div
            className={styles.fakeInput}
            onClick={() => flyRef.current.click()}
          >
            {arrivalData.arrival_type === 3 ? (
              <img src={activeCheckbox} alt={"check"} />
            ) : (
              <img src={disactiveCheckbox} alt={"false"} />
            )}
          </div>
          <input
            type="radio"
            name="arrival"
            id="fly"
            onChange={(event) => event.target.value === 'on' ? setArrivalType(3) : setArrivalType('')}
          />
          <label htmlFor="fly" ref={flyRef}>
            {globalTranslate(currentLang, "Самолетом")}
          </label>
        </div>
        <div className={styles.item}>
          <div
            className={styles.fakeInput}
            onClick={() => trainRef.current.click()}
          >
            {arrivalData.arrival_type === 2 ? (
              <img src={activeCheckbox} alt={"check"} />
            ) : (
              <img src={disactiveCheckbox} alt={"false"} />
            )}
          </div>
          <input
            type="radio"
            name="arrival"
            id="train"
            onChange={(event) => event.target.value === 'on' ? setArrivalType(2) : setArrivalType('')}
          />
          <label htmlFor="train" ref={trainRef} >
            {globalTranslate(currentLang, "Поездом")}
          </label>
        </div>
        <div className={styles.item}>
          <div
            className={styles.fakeInput}
            onClick={() => autoRef.current.click()}
          >
            {arrivalData.arrival_type === 1 ? (
              <img src={activeCheckbox} alt={"check"} />
            ) : (
              <img src={disactiveCheckbox} alt={"false"} />
            )}
          </div>
          <input
            type="radio"
            name="arrival"
            id="auto"
            onChange={(event) => event.target.value === 'on' ? setArrivalType(1) : setArrivalType('')}
          />
          <label htmlFor="auto" ref={autoRef}>
          {globalTranslate(currentLang, "Автомобилем")}
          </label>
        </div>
      </div>
      
      {arrivalData.arrival_type === 3 && (
        <>
          <p style={{fontFamily: 'Gotham Pro Bold'}}>{globalTranslate(currentLang, "Укажите номер рейса и дату прибытия")}</p>
          <TextInput labelText={"Номер рейса"} onChange={setArrivalFlyNumber} />
          <div className={styles.one_one_template}>
        <div
          style={{ boxSizing: "border-box" }}
          className={styles.one_one_item}
        >
          <CalendarInput
            labelText={"Дата прилета"}
            onChange={setArrivalDate}
            isValue={arrivalData.arrival_date}
          />
        </div>
        <div className={styles.one_two_item}>
          <TimeInput labelText={"Время прилета"} onChange={setArrivalTime} />
        </div>
      </div>
        </>
      )}
      {arrivalData.arrival_type === 2 && (
        <>
          <p style={{fontFamily: 'Gotham Pro Bold'}}>{globalTranslate(currentLang, "Укажите номер поезда и дату прибытия")}</p>
          <TextInput labelText={"Номер поезда"} onChange={setArrivalFlyNumber} />
          <div className={styles.one_one_template}>
        <div
          style={{ boxSizing: "border-box" }}
          className={styles.one_one_item}
        >
          <CalendarInput
            labelText={"Дата прибытия"}
            onChange={setArrivalDate}
            isValue={arrivalData.arrival_date}
          />
        </div>
        <div className={styles.one_two_item}>
          
          <TimeInput labelText={"Время прибытия"} onChange={setArrivalTime} />
        </div>
      </div>
        </>
      )}
      <p style={{fontFamily: 'Gotham Pro Bold'}}>{globalTranslate(currentLang, "На чем убываете?")}</p>
      <div className={styles.arrivalType}>
        <div className={styles.item}>
          <div
            className={styles.fakeInput}
            onClick={() => departureFlyRef.current.click()}
          >
            {arrivalData.departure_type === 3 ? (
              <img src={activeCheckbox} alt={"check"} />
            ) : (
              <img src={disactiveCheckbox} alt={"false"} />
            )}
          </div>
          <input
            type="radio"
            name="departure"
            id="departureFly"
            onChange={(event) => event.target.value === 'on' ? setDepartureType(3) : setDepartureType('')}
          />
          <label htmlFor="departureFly" ref={departureFlyRef}>
          {globalTranslate(currentLang, "Самолетом")}
          </label>
        </div>
        <div className={styles.item}>
          <div
            className={styles.fakeInput}
            onClick={() => departureTrainRef.current.click()}
          >
            {arrivalData.departure_type === 2 ? (
              <img src={activeCheckbox} alt={"check"} />
            ) : (
              <img src={disactiveCheckbox} alt={"false"} />
            )}
          </div>
          <input
            type="radio"
            name="departure"
            id="departureTrain"
            onChange={(event) => event.target.value === 'on' ? setDepartureType(2) : setDepartureType('')}
          />
          <label htmlFor="departureTrain" ref={departureTrainRef} >
          {globalTranslate(currentLang, "Поездом")}
          </label>
        </div>
        <div className={styles.item}>
          <div
            className={styles.fakeInput}
            onClick={() => departureAutoRef.current.click()}
          >
            {arrivalData.departure_type === 1 ? (
              <img src={activeCheckbox} alt={"check"} />
            ) : (
              <img src={disactiveCheckbox} alt={"false"} />
            )}
          </div>
          <input
            type="radio"
            name="departure"
            id="departureAuto"
            onChange={(event) => event.target.value === 'on' ? setDepartureType(1) : setDepartureType('')}
          />
          <label htmlFor="departureAuto" ref={departureAutoRef}>
          {globalTranslate(currentLang, "Автомобилем")}
          </label>
        </div>
      </div>
      {arrivalData.departure_type === 3 && (
        <>
          <p style={{fontFamily: 'Gotham Pro Bold'}}>{globalTranslate(currentLang, "Укажите номер рейса и дату убытия")}</p>
          <TextInput labelText={"Номер рейса"} onChange={setDepartureFlyNumber} />
          <div className={styles.one_one_template}>
        <div
          style={{ boxSizing: "border-box" }}
          className={styles.one_one_item}
        >
          <CalendarInput
            labelText={"Дата вылета"}
            onChange={setDepartureDate}
            isValue={arrivalData.departure_date}
          />
        </div>
        <div className={styles.one_two_item}>
          <TimeInput labelText={"Время вылета"} onChange={setDepartureTime} />
        </div>
      </div>
        </>
      )}
      {arrivalData.departure_type === 2 && (
        <>
          <p style={{fontFamily: 'Gotham Pro Bold'}}>{globalTranslate(currentLang, "Укажите номер поезда и дату убытия")}</p>
          <TextInput labelText={"Номер поезда"} onChange={setDepartureFlyNumber} />
          <div className={styles.one_one_template}>
        <div
          style={{ boxSizing: "border-box" }}
          className={styles.one_one_item}
        >
          <CalendarInput
            labelText={"Дата отъезда"}
            onChange={setDepartureDate}
            isValue={arrivalData.departure_date}
          />
        </div>
        <div className={styles.one_two_item}>
          <TimeInput labelText={"Время отъезда"} onChange={setDepartureTime} />
        </div>
      </div>
        </>
      )}

      
      

      <TextArea
        labelText={"Дополнительные комментарии"}
        onChange={setComments}
      />

      {/* <TextInput labelText={"Адрес проживания в Красноярске"} onChange={setKrasnoyarskAdres} /> */}

      {/* <SelectInput
        labelText={"Прибытие"}
        onChange={setArrival}
        options={arrivalType}
        twoKeys={true}
      /> */}
    </Box>
  );
};

export default ArrivalInfo;
