import { BASE_URL_API } from '../BASE_URL.js';
import { getAllGalleriesAction } from '../redux/Reducers/photosReducer.js';

const getPhotoGalleries = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'gallery/photos/galleries/');
        const data = await response.json();

        dispatch(getAllGalleriesAction(data));
    }
}

export default getPhotoGalleries;