import { BASE_URL_API } from "../BASE_URL.js";
import { getCarsAction } from "../redux/Reducers/carsReducer.js";
import { getCompanyRegistrations } from './getCompanyRegistrations';

const updateUser = async (userData, id, navigate, dispatch, setLoad, setError) => {
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const form_data = new FormData();

  for (var key in userData) {
    form_data.append(key, userData[key]);
  }
  const response = await fetch(
    BASE_URL_API + "registrations/registrations/" + id + "/",
    {
      headers: {
        "X-CSRFToken": csrftoken,
        Authorization: "Token " + window.localStorage.token,
      },
      body: form_data,
      method: "PATCH",
    }
  );
  if (response.status === 200) {
    getCompanyRegistrations(dispatch)
    setLoad(false);
    navigate('/cabinet/my_registrations')
  }
  if (response.status === 400) {
    const errorList = await response.json()
    const formatedErrorList = []

    for (let key in errorList) {
      if (key === 'passport_date') formatedErrorList.push('Неверный формат даты выдачи паспорта')
      if (key === 'birthday_date') formatedErrorList.push('Неверный формат даты рождения')
      if (key === 'arrival_date') formatedErrorList.push('Неверный формат даты прибытия')
      if (key === 'departure_date') formatedErrorList.push('Неверный формат даты убытия')
    }

    setError(formatedErrorList)

    setLoad(false)
  }
};

export default updateUser;
