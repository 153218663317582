import { BASE_URL_API } from "../BASE_URL.js";
import { setAuthAction } from "../redux/Reducers/AuthReducer";
import { setCurrentUserAction } from "../redux/Reducers/currentUserReducer";

export const isAuth = async (dispatch) => {
    const response = await fetch(BASE_URL_API + "accounts/info/", {
      headers: {
        Authorization: "Token " + window.localStorage.token,
      },
    });
    if (response.status === 200) {
        dispatch(setAuthAction(true));
        const data = await response.json();
        dispatch(setCurrentUserAction(data))
    }
};
