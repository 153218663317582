import { BASE_URL_API } from '../BASE_URL.js';

import { getAllVideosAction, getFirstVideosAction } from '../redux/Reducers/videosReducer.js';

export const getFirstVideos = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'gallery/link-videos/?limit=8&offset=0');
        const data = await response.json();

        dispatch(getFirstVideosAction(data));
    }
}

export const getAllVideos = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'gallery/link-videos/');
        const data = await response.json();

        dispatch(getAllVideosAction(data));
    }
}
