const initialState = {
    registrationCounter: 0,
    currentRegistration: false,
};
  
  const SET_COUNTER = 'SET_COUNTER';
  const SET_CURRENT_REGISTRATIONS = 'SET_CURRENT_REGISTRATIONS';
  
  export const companyRegCounterReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_COUNTER:
        return {...state,
            registrationCounter: action.payload,
        };
      case SET_CURRENT_REGISTRATIONS:
        return {...state,
            currentRegistration: action.payload,
        }
      default:
        return state;
    }
  };
  
  export const setCounterAction = (payload) => ({ type: SET_COUNTER, payload});
  export const setCurrentRegistrationsAction = (payload) => ({ type: SET_CURRENT_REGISTRATIONS, payload});