import React, { useState, useEffect, useRef } from "react";

import ArrowDown from "../../../image/icons/arrow-down.png";
import SearchIcon from "../../../image/icons/search-icon.svg";

import styles from "./SelectInput.module.scss";
import Box from "../Box/Box";
import { useSelector } from "react-redux";
import { globalTranslate } from "../../../functions/languages";

const SelectInput = ({ labelText, options, twoKeys, onChange, isValue, error, isDisabled }) => {
  const [activeValue, setActiveValue] = useState({ name: "", name_en: "", id: "" });
  const [isScaleLabel, setIsScaleLabel] = useState(false);

  const [arrayOfOptions, setArrayOfOptions] = useState();

  const [searchField, setSearchField] = useState("");

  const [isSelectActive, setIsSelectActive] = useState(false);

  const spheres = useSelector(state => state.spheres.spheres)

  const currentLang = useSelector(state => state.lang.currentLang)


  useEffect(() => {
    if (!isValue) return
    if (!arrayOfOptions || arrayOfOptions.length === 0) return;
      const localOptions = arrayOfOptions.filter(item => item.id == isValue)[0]
      setActiveValue(localOptions)
  }, [arrayOfOptions, isValue])

  const selectClickHandler = () => {
    if (isDisabled) return;
    if (isSelectActive === false && activeValue.name === "") {
      setIsScaleLabel(true);
      setIsSelectActive(true);
    } else if (isSelectActive === true && activeValue.name === "") {
      setIsScaleLabel(false);
      setIsSelectActive(false);
    } else if (isSelectActive === true && activeValue.name !== "") {
      setIsScaleLabel(true);
      setIsSelectActive(false);
    } else if (isSelectActive === false && activeValue.name !== "") {
      setIsScaleLabel(true);
      setIsSelectActive(true);
    }
  };

  const itemClickHandler = (event) => {
    const object = arrayOfOptions.filter(item => item.name === event.target.innerHTML || item.name_en === event.target.innerHTML)
    onChange(twoKeys ? object[0] : event.target.innerHTML );
    setActiveValue(object[0]);
    setIsSelectActive(false);
  };

  const rootEl = useRef();

  useEffect(() => {
    if (searchField === "") {
      setArrayOfOptions(options);
    } else {
      if (currentLang === 'ru') {
        setArrayOfOptions(
          arrayOfOptions.filter((item) =>
            item.name.toLowerCase().includes(searchField.toLowerCase())
          )
        );
      } else {
        setArrayOfOptions(
          arrayOfOptions.filter((item) =>
            item.name_en.toLowerCase().includes(searchField.toLowerCase())
          )
        );
      }
      
    }
  }, [searchField, currentLang]);

  useEffect(() => {
    if (!options) return;
    setArrayOfOptions(options);
  }, [options]);

  useEffect(() => {
    if (isSelectActive) {
      const onClick = e => {
        if (!rootEl.current.contains(e.target)) {
          setIsSelectActive(false);
          if (activeValue.name === '') {
            setIsScaleLabel(false); 
          }
        };
      }
      document.addEventListener('click', onClick);
      return () => document.removeEventListener('click', onClick);
    }
  }, [isSelectActive]);

  useEffect(() => {
    if (activeValue && activeValue.name !== "") {
      setIsScaleLabel(true);
    }
  }, [activeValue]);

  return (
    <Box maxW={700}>
      <div className={styles.select} ref={rootEl} style={error ? {backgroundColor: '#FFEDED'} : {}}>
        <div className={styles.clickableElement} onClick={selectClickHandler}>
          <img
            src={ArrowDown}
            style={
              isSelectActive
                ? { transform: "rotate(180deg)" }
                : { transform: "rotate(0deg)" }
            }
            alt=""
          />
        </div>
        <p className={isScaleLabel ? styles.activeLabel : styles.label} onClick={selectClickHandler}>
          {globalTranslate(currentLang, labelText)}
        </p>
        <p className={styles.activeItem} style={ isDisabled ? {color: '#545454', cursor: 'default'} : {}}>{activeValue && (currentLang === 'ru' ? activeValue.name : activeValue.name_en)}</p>
        <ul
          className={styles.selectList}
          style={isSelectActive ? { display: "block" } : { display: "none" }}
        >
          {labelText && !labelText.includes('статус') && (
            <div className={styles.searchWrapper}>
              <img src={SearchIcon} />
            <input
              type="text"
              onChange={(event) => setSearchField(event.target.value)}
            />
          </div>
          )}
          {arrayOfOptions && arrayOfOptions.map((item, index) => (
            <li
              key={index}
              id={twoKeys ? item.id : item}
              onClick={itemClickHandler}
            >
              {twoKeys ? (currentLang === 'ru' ? item.name : item.name_en) : item}
            </li>
          ))}
        </ul>
      </div>
    </Box>
  );
};

export default SelectInput;
