import React from 'react';
import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate';
import { useSelector } from 'react-redux';

import styles from './RegDocs.module.scss';
import { globalTranslate } from '../../../functions/languages';

const PaidPackadge = () => {
  const paidText = useSelector(state => state.regDocs.participant_packages)
  const paidTextEn = useSelector(state => state.regDocs.participant_packages_en)
  const currentLang = useSelector((state) => state.lang.currentLang);

  const createMarkup = (text) => {
    return { __html: text };
  };
  return (
    <PageTemplate>
        <Container>
            <h3>{globalTranslate(currentLang, "Платное участие в Форуме")}</h3>

            <div className={styles.textWrapper} dangerouslySetInnerHTML={createMarkup(currentLang === 'ru' ? paidText : paidTextEn)}
            ></div>
        </Container>
    </PageTemplate>
  );
};

export default PaidPackadge;