import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./RegPage.module.scss";

import PersonalData from "./UserDataForms/PersonalData";
import CompanyInfo from "./UserDataForms/CompanyInfo";
import ContactPersonData from "./UserDataForms/ContactPersonData";
import getCountries from "../../../functions/getCountries";
import PassData from "./UserDataForms/PassData";
import ContactInfoData from "./UserDataForms/ContactInfoData";
import AgreementsData from "./UserDataForms/AgreementsData";

const OnePersoneRegPage = ({ header, isValue, isPayed, clearError, errorList }) => {
  const dispatch = useDispatch();

  const regData = useSelector((state) => state.regInfo);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <>
      <PersonalData header={header && header} clearError={clearError} errorList={errorList} isPayed={isPayed} />
      <PassData clearError={clearError} errorList={errorList} />
      <ContactInfoData clearError={clearError} errorList={errorList} />
      <CompanyInfo isValue={isValue && isValue} clearError={clearError} errorList={errorList} />
      {/* {regData.is_contact_via_manager && <ContactPersonData isValue={isValue && isValue} />}   */}
      <AgreementsData isPayed={isPayed} clearError={clearError} errorList={errorList} />
    </>
  );
};

export default OnePersoneRegPage;
