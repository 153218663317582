import React, { useState, useEffect } from "react";
import CalendarInput from "../../../elements/CalendarInput/CalendarInput";
import TextInput from "../../../elements/TextInput/TextInput";

import styles from "./UserDataForms.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setPassRegDataAction } from "../../../../redux/Reducers/regInfoReducers";
import { globalTranslate } from "../../../../functions/languages";

const PassData = ({ errorList, clearError, isValue, change, isOnline, isDisabled }) => {
  const [changedData, setChangedData] = useState({});
  const dispatch = useDispatch();

  const typeOfRegLocal = useSelector(state => state.regInfo.registration_type)
  const typeOfRegAPI = useSelector(state => state.registrations.currentRegistration)
  const currentLang = useSelector(state => state.lang.currentLang)


  const [passData, setPassData] = useState({
    passport_series: "",
    passport_number: "",
    passport_issuer: "",
    passport_date: "",
    birthday_date: "",
    birthday_address: "",
    registration_address: "",
    registration_date: "",
  });

  useEffect(() => {
    if (!isValue) return;
    setPassData({
      ...passData,
      passport_series: isValue.passport_series,
      passport_number: isValue.passport_number,
      passport_issuer: isValue.passport_issuer,
      passport_date: isValue.passport_date,
      birthday_date: isValue.birthday_date,
      birthday_address: isValue.birthday_address,
      registration_address: isValue.registration_address,
      registration_date: isValue.registration_date,
    });
  }, [isValue]);

  const setSeries = (data) => {
    setPassData({ ...passData, passport_series: data });
    setChangedData({...changedData, passport_series: data})
    clearError("Укажите серию паспорта");
  };
  const setNumber = (data) => {
    setPassData({ ...passData, passport_number: data });
    setChangedData({...changedData, passport_number: data})

    clearError("Укажите номер паспорта");
  };
  const setIssuedBy = (data) => {
    setPassData({ ...passData, passport_issuer: data });
    setChangedData({...changedData, passport_issuer: data})

    clearError("Укажите орган, выдавший паспорт");
  };
  const setDateOfIssued = (data) => {
    setPassData({ ...passData, passport_date: data });
    setChangedData({...changedData, passport_date: data})

    clearError("Укажите дату выдачи паспорта");
  };
  const setDateOfBirth = (data) => {
    setPassData({ ...passData, birthday_date: data });
    setChangedData({...changedData, birthday_date: data})

    clearError("Укажите дату рождения");
  };
  const setPlaceOfBirth = (data) => {
    setPassData({ ...passData, birthday_address: data });
    setChangedData({...changedData, birthday_address: data})

    clearError("Укажите место рождения");
  };

  const setRegistrationAdres = (data) => {
    setPassData({ ...passData, registration_address: data });
    setChangedData({...changedData, registration_address: data});

    clearError("Укажите адрес прописки");
  }

  const setRegistrationDate = (data) => {
    setPassData({ ...passData, registration_date: data });
    setChangedData({...changedData, registration_date: data});

    clearError("Укажите дату регистрации");
  }

  useEffect(() => {
    if (!change) return;
    change(changedData)
  }, [changedData])

  useEffect(() => {
    dispatch(setPassRegDataAction(passData));
  }, [passData]);

  return (
    <div style={{ maxWidth: "700px" }}>
      <h3>{globalTranslate(currentLang, 'Паспортные данные')}</h3>
      {!isOnline && (
        <div className={styles.one_two_template}>
        <div style={{ boxSizing: "border-box" }} className={styles.one}>
          <TextInput
            isDisabled={isDisabled}
            labelText={"Серия паспорта"}
            onChange={setSeries}
            isValue={passData.passport_series}
            error={
              errorList && errorList.includes("Укажите серию паспорта")
                ? true
                : false
            }
          />
        </div>
        <div className={styles.two}>
          <TextInput
            isDisabled={isDisabled}
            labelText={"Номер паспорта"}
            onChange={setNumber}
            isValue={passData.passport_number}
            error={
              errorList && errorList.includes("Укажите номер паспорта")
                ? true
                : false
            }
          />
        </div>
      </div>
      )}
      {!isOnline && (
        <TextInput
        isDisabled={isDisabled}
        labelText={"Кем выдан"}
        onChange={setIssuedBy}
        isValue={passData.passport_issuer}
        error={
          errorList && errorList.includes("Укажите орган, выдавший паспорт")
            ? true
            : false
        }
      />
      )}
      {!isOnline ? (
        <div className={styles.one_one_template}>
        <div
          style={{ boxSizing: "border-box" }}
          className={styles.one_one_item}
        >
          <CalendarInput
            isDisabled={isDisabled}
            labelText={"Дата выдачи"}
            onChange={setDateOfIssued}
            isValue={passData.passport_date}
            error={
              errorList && errorList.includes("Укажите дату выдачи паспорта")
                ? true
                : false
            }
          />
        </div>
        <div className={styles.one_two_item}>
          <CalendarInput
            isDisabled={isDisabled}
            labelText={"Дата рождения"}
            onChange={setDateOfBirth}
            isValue={passData.birthday_date}
            error={
              errorList && errorList.includes("Укажите дату рождения")
                ? true
                : false
            }
          />
        </div>
      </div>
      ) : (
        <CalendarInput
            isDisabled={isDisabled}
            labelText={"Дата рождения"}
            onChange={setDateOfBirth}
            isValue={passData.birthday_date}
            error={
              errorList && errorList.includes("Укажите дату рождения")
                ? true
                : false
            }
          />
      ) }
      {(typeOfRegLocal === 'smi' || (typeOfRegAPI && typeOfRegAPI.length > 0 && typeOfRegAPI[0].registration_type === 'smi')) && (
        <div className={styles.one_one_template}>
        <div
          style={{ boxSizing: "border-box" }}
          className={styles.one_one_item}
        >
          <TextInput
            isDisabled={isDisabled}
            labelText={"Адрес регистрации"}
            onChange={setRegistrationAdres}
            isValue={passData.registration_address}
            error={
              errorList && errorList.includes("Укажите адрес прописки")
                ? true
                : false
            }
          />
        </div>
        <div className={styles.one_two_item}>
          <CalendarInput
            isDisabled={isDisabled}
            labelText={"Дата регистрации"}
            onChange={setRegistrationDate}
            isValue={passData.registration_date}
            error={
              errorList && errorList.includes("Укажите дату регистрации")
                ? true
                : false
            }
          />
        </div>
      </div>
      )
      }
      <TextInput
        isDisabled={isDisabled}
        labelText={"Место рождения"}
        onChange={setPlaceOfBirth}
        isValue={passData.birthday_address}
        error={
          errorList && errorList.includes("Укажите место рождения")
            ? true
            : false
        }
      />
    </div>
  );
};

export default PassData;
