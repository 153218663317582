import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";
import React, { useState, useEffect, useRef } from "react";

import ArrowDown from "../../../image/icons/arrow-down.png";
import SearchIcon from "../../../image/icons/search-icon.svg";

import styles from "./SelectInput.module.scss";
import { useSelector } from 'react-redux';
import { globalTranslate } from "../../../functions/languages";

const SelectInputLocation = ({
  labelText,
  options,
  onChange,
  defaultValue,
  isValue,
  error,
  onClick,
  isDisabled
}) => {
  const [activeValue, setActiveValue] = useState({ name: "", name_en: '', id: "" });
  const [isScaleLabel, setIsScaleLabel] = useState(false);

  const [arrayOfOptions, setArrayOfOptions] = useState();
  const [filteredOptions, setFilteredOptions] = useState();

  const [searchField, setSearchField] = useState("");

  const [isSelectActive, setIsSelectActive] = useState(false);


  const currentLang = useSelector(state => state.lang.currentLang)

  useEffect(() => {
    if (isValue !== activeValue.name) {
      if (!isValue) setActiveValue({ name: "", name_en: "", id: "" })
      if (isValue) {
        setActiveValue({name: isValue, name_en: isValue, id: ""})
      }
    }
  }, [isValue])

  useEffect(() => {
    if (!options) return;
    setArrayOfOptions(options)
  }, [options, currentLang])

  const selectClickHandler = () => {
    if (isDisabled) return
    if (!activeValue) return;
    if (isSelectActive === false && activeValue.name === "") {
      setIsScaleLabel(true);
      setIsSelectActive(true);
    } else if (isSelectActive === true && activeValue.name === "") {
      setIsScaleLabel(false);
      setIsSelectActive(false);
    } else if (isSelectActive === true && activeValue.name !== "") {
      setIsScaleLabel(true);
      setIsSelectActive(false);
    } else if (isSelectActive === false && activeValue.name !== "") {
      setIsScaleLabel(true);
      setIsSelectActive(true);
    }
  };
  const itemClickHandler = (event) => {
    if (onClick) onClick(true)
    setSearchField('');
    setActiveValue({ name: event.target.innerHTML, name_en: event.target.innerHTML, id: event.target.id });
    onChange({ name: event.target.innerHTML, name_en: event.target.innerHTML, id: event.target.id });
    setIsSelectActive(false);
    setIsScaleLabel(false);
    setFilteredOptions();
  };

  useEffect(() => {
    // if (!activeValue) return;
    if (activeValue.name !== "") {
      setIsScaleLabel(true);
    } else {
      setIsScaleLabel(false);
      setIsSelectActive(false);
    }
  }, [activeValue]);

  const rootEl = useRef(null);

  useEffect(() => {
    if (isSelectActive) {
      const onClick = (e) => {
        if (!rootEl.current.contains(e.target)) {
          setIsSelectActive(false);
          if (activeValue.name === "") {
            setIsScaleLabel(false);
          }
        }
      };
      document.addEventListener("click", onClick);
      return () => document.removeEventListener("click", onClick);
    }
  }, [isSelectActive]);


  useEffect(() => {
    if (searchField.length < 1) {
      setFilteredOptions();
      return;
    }
    if (!arrayOfOptions) return
    if (currentLang === 'ru') {
      let filterOptions = arrayOfOptions.filter((item) =>
      item.name.toLowerCase().includes(searchField.toLowerCase())
    );
    setFilteredOptions(filterOptions);
    } else {
      let filterOptions = arrayOfOptions.filter((item) =>
      item.name_en.toLowerCase().includes(searchField.toLowerCase())
    );
    setFilteredOptions(filterOptions);
    }
    
  }, [searchField, currentLang]);

  // useEffect(() => {
  //   if (!activeValue) return
  //   if (!activeValue.name) return;
  //   onChange(activeValue);
  // }, [activeValue]);

  return (
    <div
      className={styles.select}
      ref={rootEl}
      style={error ? { backgroundColor: "#FFEDED" } : {}}
    >
      <div className={styles.clickableElement} onClick={selectClickHandler}>
        <img
          src={ArrowDown}
          style={
            isSelectActive
              ? { transform: "rotate(180deg)" }
              : { transform: "rotate(0deg)" }
          }
          alt=""
        />
      </div>
      <p className={isScaleLabel ? styles.activeLabel : styles.label}>
        {globalTranslate(currentLang, labelText)}
      </p>
      <p className={styles.activeItem} style={ isDisabled ? {color: '#545454'} : {}}>{activeValue && (currentLang === 'ru' ? activeValue.name : activeValue.name_en) }</p>
      <ul
        className={styles.selectList}
        style={isSelectActive ? { display: "block" } : { display: "none" }}
      >
        <div className={styles.searchWrapper}>
          <img src={SearchIcon} />
          <input
            type="text"
            placeholder={globalTranslate(currentLang, "Начните вводить название")}
            value={searchField}
            onChange={(event) => setSearchField(event.target.value)}
          />
        </div>
        {filteredOptions ? filteredOptions.map((item, index) => (
          <li
            key={index}
            id={item.id}
            onClick={itemClickHandler}
            data-location_id={item.id}
          >
            {currentLang === 'ru' ? item.name : item.name_en}
          </li>
        )) : arrayOfOptions && arrayOfOptions.map((item, index) => (
          <li
            key={index}
            id={item.id}
            onClick={itemClickHandler}
            data-location_id={item.id}
          >
            {currentLang === 'ru' ? item.name : item.name_en}
          </li>
        )) }
      </ul>
    </div>
  );
};

export default SelectInputLocation;
