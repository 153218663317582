const initialState = {
  countries: [],
  regions: [],
  cities: [],
};

const GET_COUNTRIES = "GET_COUNTRY";
const GET_REGIONS = "GET_REGIONS";
const GET_CITIES = "GET_CITIES";

export const locationsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return {...state, countries: action.payload,
      };
    case GET_REGIONS:
      return {...state, regions: action.payload.results,
      };
    case GET_CITIES:
      return {...state, cities: action.payload.results,
      };
    default:
      return state;
  }
};

export const getCountriesAction = (payload) => ({ type: GET_COUNTRIES, payload });
export const getRegionsAction = (payload) => ({ type: GET_REGIONS, payload });
export const getCitiesAction = (payload) => ({ type: GET_CITIES, payload });
