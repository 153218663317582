const initialState = {
    page: false,
  };
  
  const GET_CURRENT_PAGE = 'GET_CURRENT_PAGE';
  
  export const currentPageReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CURRENT_PAGE:
        return {
          page: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getCurrentPageAction = (payload) => ({ type: GET_CURRENT_PAGE, payload});