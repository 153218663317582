import React, { useState } from "react";
import { useEffect } from "react";
import CarsData from "../../pages/RegPage/UserDataForms/CarsData";
import PageTemplate from "../../PageTemplate/PageTemplate";
import Button from "../Button/Button";
import CabinetNav from "../CabinetNav/CabinetNav";
import Container from "../Container/Container";
import setCar from "../../../functions/setCar";
import { useDispatch, useSelector } from "react-redux";

import deleteCarIcon from "../../../image/icons/delete.png";

import styles from "./CabinetProfile.module.scss";
import getCars from "../../../functions/getCars";
import deleteCars from "../../../functions/deleteCars";
import TextInput from "../TextInput/TextInput";
import { useNavigate } from 'react-router-dom';
import { globalTranslate } from "../../../functions/languages";

const CabinetProfileCars = () => {
  const [load, setLoad] = useState(false);
  const [carsList, setListCars] = useState();
  const carsRedux = useSelector((state) => state.cars.cars);

  const [carDataLocal, setCarDataLocal] = useState({
    car_brand: "",
    car_model: "",
    car_number: "",
    car_color: "",
  });

  const setBrand = (data) =>
    setCarDataLocal({ ...carDataLocal, car_brand: data });
  const setModel = (data) =>
    setCarDataLocal({ ...carDataLocal, car_model: data });
  const setNumber = (data) =>
    setCarDataLocal({ ...carDataLocal, car_number: data });
  const setColor = (data) =>
    setCarDataLocal({ ...carDataLocal, car_color: data });

  const currentLang = useSelector(state => state.lang.currentLang)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const setCars = () => {
    setLoad(true);
    setCar(carDataLocal);
    setLoad(false)
    navigate('/success_add_car')
  };

  const deleteCar = (id) => {
    setLoad(true);
    deleteCars(id, setLoad);
  };

  useEffect(() => {
    if (carsRedux.length < 1) {
      setListCars();
    } else {
      setListCars(carsRedux);
    }
  }, [carsRedux]);

  useEffect(() => {
    dispatch(getCars());
  }, [load, dispatch]);

  return (
    <PageTemplate>
      <Container>
        <CabinetNav />
        <div>
          <h3>{globalTranslate(currentLang, 'Аккредитация автомобиля')}</h3>
          <TextInput labelText={"Марка ТС"} onChange={setBrand} />
          <TextInput labelText={"Модель ТС"} onChange={setModel} />
          <TextInput labelText={"Государственный номер ТС"} onChange={setNumber} />
          <TextInput labelText={"Цвет кузова ТС"} onChange={setColor} />
        </div>
        <Button onClick={setCars}>
          {load ? globalTranslate(currentLang, "Выполняю запрос") : globalTranslate(currentLang, "Сохранить изменения")}
        </Button>
        <div style={{ marginBottom: 40 }}>
          {carsList && (
            <>
              <h3 style={window.innerWidth < 1000 ? {marginTop: 30, marginBottom: 0} : {}}>{globalTranslate(currentLang, "Ваши автомобили")}</h3>
              {carsList.map((item, index) => (
                <div key={index} className={styles.carItem}>
                  <p>
                    {item.car_brand} {item.car_model}
                  </p>
                  <p>{item.car_number}</p>
                  <p>
                    {item.is_confirmed
                      ? globalTranslate(currentLang, "Аккредитован")
                      : globalTranslate(currentLang, "Ожидает аккредитации")}
                  </p>
                  <img src={deleteCarIcon} onClick={() => deleteCar(item.id)} />
                </div>
              ))}
            </>
          )}
        </div>
      </Container>
    </PageTemplate>
  );
};

export default CabinetProfileCars;
