import React, { useState } from "react";
import styles from './Container.module.scss';
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";

const ContentContainer = ({ children }) => {
  const [pagesPaddingRight, setPagesPaddingRight] = useState(100)
  const [isMobile, setMobile] = useState(false)
  const { pathname } = useLocation()

  const checkMobile = () => {
    if (window.innerWidth < 1200) setMobile(true)
    if (window.innerWidth > 1200) setMobile(false)
    if (pathname.includes('pages/hotels')) setMobile(true)
  }

  useEffect(() => {
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  useEffect(() => {
    checkMobile()
    if (!pathname.includes('pages')) return;
    setPagesPaddingRight(350)
  }, [pathname, isMobile])

  return (
    <div 
      className={styles.ContentContainer}
      style={!isMobile ? {paddingRight: pagesPaddingRight} : {paddingRight: 0}}
    >
        {children}
    </div>
  );
};

export default ContentContainer;
