import React, { useEffect, useRef } from "react";

import styles from "./PageTemplate.module.scss";

import { ReactComponent as Logo } from "./../../image/2024/logo_white_2024.svg";
import { ReactComponent as LogoMobile } from "../../image/2024/logo-new.svg";
import { ReactComponent as LogoEn } from "../../image/2024/main_logo_en.svg";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setNewLang } from "../../functions/languages";
import { setNewLangAction } from "../../redux/Reducers/langReducer";
import { useSelector } from "react-redux";

import globe from "../../image/icons/globe_white.png";

const TransparentHeader = ({ getHeight }) => {
  const headerRef = useRef();
  const [headerWidth, setHeaderWidth] = useState(1200);
  const [currentLang, setCurrentLang] = useState();
  const [isMobile, setMobile] = useState(false);
  const dispatch = useDispatch();

  const setNewLangClickHandler = (lang) => {
    setNewLang(lang);
    setCurrentLang(lang);
    dispatch(setNewLangAction(lang));
  };

  const resizeLang = () => {
    if (window.innerWidth > 1000) setMobile(false);
    if (window.innerWidth <= 1000) setMobile(true);
  };

  useEffect(() => {
    resizeLang();
    window.addEventListener("resize", resizeLang);

    return () => window.removeEventListener("resize", resizeLang);
  }, []);

  const currentLangRedux = useSelector((state) => state.lang.currentLang);

  useEffect(() => {
    setCurrentLang(currentLangRedux);
  }, [currentLangRedux]);

  const resize = () => {
    if (!headerRef.current) return;
    setHeaderWidth(headerRef.current.clientWidth);
    getHeight(headerRef.current.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  return (
    <div className={styles.TransparentHeader} ref={headerRef}>
      {!isMobile && (
        <div className={styles.chooseLang}>
          <span
            style={
              currentLang === "ru" ? { fontFamily: "Gotham Pro Bold" } : {}
            }
            onClick={() => setNewLangClickHandler("ru")}
          >
            РУС
          </span>
          <span
            style={
              currentLang === "en" ? { fontFamily: "Gotham Pro Bold" } : {}
            }
            onClick={() => setNewLangClickHandler("en")}
          >
            ENG
          </span>
          <img src={globe} alt="" />
        </div>
      )}

      <Link to={"/main"}>
        {currentLang === "ru" ? (
          window.innerWidth < 1000 ? (
            <LogoMobile width={300} height={157} />
          ) : (
            <Logo
              width={headerWidth / 2.45}
              height={headerWidth / 3.25 / 2.7}
            />
          )
        ) : window.innerWidth < 1000 ? (
          <LogoEn width={300} height={157} />
        ) : (
          <LogoEn
            width={headerWidth / 2.45}
            height={headerWidth / 3.25 / 2.7}
          />
        )}
      </Link>
    </div>
  );
};

export default TransparentHeader;
