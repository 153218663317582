import React, { useState } from "react";
import Container from "../../elements/Container/Container";
import PageTemplate from "../../PageTemplate/PageTemplate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getNews, getNewsBySearch } from "../../../functions/getNews";
import { useSelector } from "react-redux";
import NewsItem from "./NewsItem";
import { translit } from "../../../functions/translit";
import Pagination from "../../elements/Pagination/Pagination";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import ContentContainer from "../../elements/Container/ContentContainer";
import { globalTranslate } from "../../../functions/languages";

import styles from "./NewsPage.module.scss";

const SearchNewsPage = () => {
  const { page } = useParams();
  const [newsList, setNewsList] = useState();
  const [activePage, setActivePage] = useState(page);
  const [search_string, setSearchString] = useState('')
  const [search_query, setSearchQuery] = useState('')
  const [isLoad, setIsLoad] = useState(true)

  const navigate = useNavigate();

  const previousLink = useSelector((state) => state.news.prev);
  const nextLink = useSelector((state) => state.news.next);
  const news = useSelector((state) => state.news.search_results);
  const news1 = useSelector((state) => state.news);
  const newsCount = useSelector((state) => state.news.countNews);
  const currentLang = useSelector((state) => state.lang.currentLang);
  const dispatch = useDispatch();

  const titleRef = useRef();

  const location = useLocation()

  useEffect(() => {
    if (location.state && location.state.search) {
      setSearchQuery(location.state.search)
      if (location.state.search !== search_string) {
        setSearchString(location.state.search)
      }
    }
  }, [location, location.state])

  const searchNewNews = () => {
    // if (!page) return;
    // // if (!search_string) return;
    setActivePage(page);
    // // dispatch(getNewsBySearch(page, search_string, currentLang === "en"));
    // location.state.search = search_string

    navigate('/news/search/' + 1, {state: {search: search_string}})
    
  }

  function getNoun(number, one, two, five) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
  }

  useEffect(() => {
    if (!page) return;
    if (!search_query) return;
    setActivePage(page);
    dispatch(getNewsBySearch(page, search_query, currentLang === "en"));
  }, [page, search_query, currentLang]);

  const clickPage = (number) => {
    setIsLoad(true)
    navigate("/news/search/" + number, {state: {search: search_string}});
  };
  const clickNext = () => {
    setIsLoad(true)
    navigate("/news/search/" + (Number(activePage) + 1), {state: {search: search_string}});
  };
  const clickPrev = () => {
    setIsLoad(true)
    navigate("/news/search/" + (Number(activePage) - 1), {state: {search: search_string}});
  };

  // useEffect(() => {
  //   if (!news || news.length < 1)
  //   if (currentLang === 'ru') {
  //     const rusNews = news
  //   }
  // }, [])

  useEffect(() => {
    setIsLoad(false)
    if (!news) return;
    setNewsList(news);
  }, [news, currentLang]);

  return (
    <PageTemplate>
      <Container>
        {isLoad ? <h3>{globalTranslate(currentLang, 'Загрузка')}...</h3> : (
          <>
<div className={styles.head}>
          <Link to={'/news/1'}><h3 ref={titleRef}>{globalTranslate(currentLang, "Вернуться в новости")}</h3></Link>
          <div className={styles.SearchWrap}>
            <input type="text" placeholder={globalTranslate(currentLang, "Введите запрос")} value={search_string} onChange={(e) => setSearchString(e.target.value)} />
            <button onClick={searchNewNews}>
              <span>{globalTranslate(currentLang, "Поиск")}</span>
            </button>
          </div>
        </div>
        <p style={{fontWeight: 'bold'}}>{globalTranslate(currentLang, "По запросу")} "{search_query}" {globalTranslate(currentLang, "найдено")} {newsCount + ' ' + globalTranslate(currentLang, getNoun(newsCount, 'новость', 'новости', 'новостей'))}</p>
        <ContentContainer>
          {newsList && newsList.length > 0 && 
            newsList.map((item, index) => (
              <NewsItem
                key={index}
                title={currentLang === "ru" ? item.title : item.title_en}
                description={
                  currentLang === "ru"
                    ? item.min_description
                    : item.min_description_en
                }
                date={item.publish_time}
                slug={item.slug}
              />
            ))}
        </ContentContainer>
        <Pagination
          clickPage={clickPage}
          activePage={activePage}
          clickNext={clickNext}
          clickPrev={clickPrev}
        />
          </>
        )}
        
      </Container>
    </PageTemplate>
  );
};

export default SearchNewsPage;
