const initialState = {
    welcomeWords: [],
  };
  
  const GET_WELCOME_WORDS = 'GET_WELCOME_WORDS';
  
  export const welcomeWordsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_WELCOME_WORDS:
        return {
          welcomeWords: action.payload.results,
        };
      default:
        return state;
    }
  };
  
  export const getWelcomeWordsAction = (payload) => ({ type: GET_WELCOME_WORDS, payload});