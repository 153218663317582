import { useEffect, useState } from 'react';
import { Outlet, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { isAuth } from '../functions/isAuth';
import { useSelector } from 'react-redux';

const InitializationRoute = () => {
  const [checkAuth, setCheckAuth] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  const isAuth = useSelector(state => state.isAuth.isAuth)

  useEffect(() => {
    if (checkAuth) return;
    isAuth && navigate('/cabinet/my_registrations');
    setCheckAuth(true)
  }, [isAuth, navigate])

  return checkAuth ? <Outlet /> : <Navigate to="/main" />; //TODO
};

export default InitializationRoute;
