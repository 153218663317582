const initialState = {
    types: [],
  };
  
  const GET_REGISTRATION_TYPE = 'GET_REGISTRATION_TYPE';
  
  export const registrationTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_REGISTRATION_TYPE:
        return {
          types: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getRegistrationTypesAction = (payload) => ({ type: GET_REGISTRATION_TYPE, payload});