import { BASE_URL_API } from "../BASE_URL.js";
import { resetRegDataAction } from "../redux/Reducers/regInfoReducers.js";
import { getCompanyRegistrations } from "./getCompanyRegistrations";

const testFile = async (
  userData,
  dispatch,
  navigation,
  navigateURL,
  isLoad,
  setError
) => {
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const data = new FormData();

  for (let key in userData) {
    data.append(key, userData[key]);
  }
  const token = window.localStorage.token;

  const response = await fetch(BASE_URL_API + "registrations/registrations/", {
    headers: {
      Authorization: "Token " + token,
      "X-CSRFToken": csrftoken,
    },
    body: data,
    method: "POST",
  });
  if (response.status === 201) {
    await getCompanyRegistrations(dispatch);
    dispatch(resetRegDataAction());
    isLoad(false);
    navigation(navigateURL);
    return;
  }
  if (response.status === 400) {
    const errorList = await response.json();
    const formatedErrorList = [];

    for (let key in errorList) {
      if (key === "passport_date")
        formatedErrorList.push("Неверный формат даты выдачи паспорта");
      if (key === "birthday_date")
        formatedErrorList.push("Неверный формат даты рождения");
      if (key === "arrival_date")
        formatedErrorList.push("Неверный формат даты прибытия");
      if (key === "departure_date")
        formatedErrorList.push("Неверный формат даты убытия");
      if (key === "non_field_errors")
        formatedErrorList.push(
          "Регистрация с такими серией и номером паспорта уже существует"
        );
    }

    setError(formatedErrorList);

    isLoad(false);
  }

  isLoad(false);
};

export default testFile;
