const initialState = {
    files: false,
  };
  
  const SET_PROGRAM_FILES = 'SET_PROGRAM_FILES';
  
  export const programFilesReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PROGRAM_FILES:
        return {...state,
          files: action.payload.results,
        };
      default:
        return state;
    }
  };
  
  export const setProgramFilesAction = (payload) => ({ type: SET_PROGRAM_FILES, payload});