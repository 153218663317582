const initialState = {
    pages: [],
  };
  
  const GET_PAGES = 'GET_PAGES';
  
  export const pagesReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_PAGES:
        return {
          pages: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getPagesAction = (payload) => ({ type: GET_PAGES, payload});