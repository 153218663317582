const initialState = {
    cars: [],
  };
  
  const GET_CARS = 'GET_CARS';
  
  export const carsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CARS:
        return {
          cars: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getCarsAction = (payload) => ({ type: GET_CARS, payload});