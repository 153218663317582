import { BASE_URL_API } from '../BASE_URL.js';
import { getMenuAction } from '../redux/Reducers/menuReducer.js';

const getMenu = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'base/menu/');
        const data = await response.json();

        dispatch(getMenuAction(data));
    }
}

export default getMenu;