import { BASE_URL_API } from '../BASE_URL.js';
import { getWelcomeWordsAction } from '../redux/Reducers/welcomeWordsReducer.js';

const getWelcomeWords = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'welcome-words/');
        const data = await response.json();

        dispatch(getWelcomeWordsAction(data));
    }
}

export default getWelcomeWords;