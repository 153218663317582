import { BASE_URL_API } from '../BASE_URL.js';
import { getCountriesAction } from '../redux/Reducers/locationsReducers.js';
import { getSlotsAction } from '../redux/Reducers/slotsReducer.js';

const getSlots = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'organization-staffs/slots/', {
            headers: {
                Authorization: "Token " + window.localStorage.token,
            }
        });
        const data = await response.json();

        dispatch(getSlotsAction(data));
    }
}

export default getSlots;