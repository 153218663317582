import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

import styles from "./TimeInput.module.scss";
import { translit } from '../../../functions/translit';
import { globalTranslate } from "../../../functions/languages";
import { useSelector } from 'react-redux';

const TimeInput = ({ labelText, onChange }) => {
  const [activeValue, setActiveValue] = useState("");
  const [isInputActive, setIsInputActive] = useState(false)

  const currentLang = useSelector(state => state.lang.currentLang)

  const formateTime = (time) => {
    let hours = (time - (time % 3600000)) / 3600000;
    let minutes = (time % 3600000) / 60000;
    if (hours.toString().length < 2) {
      hours = "0" + hours;
    }
    if (minutes.toString().length < 2) {
      minutes = "0" + minutes;
    }
    return hours.toString() + ":" + minutes.toString();
  };
  const inputBlurHandler = () => {
    if (activeValue.length > 0 && activeValue !== 'NaN:NaN') {
        setIsInputActive(true)
    } else {
        setIsInputActive(false)
        if (activeValue === 'NaN:NaN') setActiveValue('')
    }
  }

  const input = useRef();


  const click = () => {
    setIsInputActive(true);
    rootEl.current.click()
  }


  const onChangeHandler = (e) => {
    setActiveValue(formateTime(e.target.valueAsNumber));
    onChange(formateTime(e.target.valueAsNumber));
  };

  const rootEl = useRef();

  useEffect(() => {
    if (isInputActive) {
      const onClick = e => {
        if (!rootEl.current.contains(e.target)) {
          if (activeValue === '' || activeValue === 'NaN:NaN') {
            setIsInputActive(false); 
          }
        };
      }
      document.addEventListener('click', onClick);
      return () => document.removeEventListener('click', onClick);
    }
  }, [isInputActive, activeValue]);

  return (
    <label htmlFor={translit(labelText)} ref={rootEl} className={styles.labelWrapper} onClick={click}>
      <span className={isInputActive ? styles.activeLabel : styles.label}   onClick={click}>{globalTranslate(currentLang, labelText)}</span>
      <input
        className={styles.input}
        style={isInputActive ? {color: 'black'} : {color: '#f1f5f8'}}
        type={"time"}
        id={translit(labelText)}
        align="left"
        ref={input}
        value={activeValue}
        onChange={onChangeHandler}
        onFocus={() => setIsInputActive(true)}
        onBlur={inputBlurHandler}
      />
    </label>
  );
};

export default TimeInput;
