import React from "react";

import closeIcon from "../../../../image/icons/close-modal.svg";

import styles from "../GalleryPage.module.scss";

const ModalVideo = ({ link, close }) => {

  const createMarkup = (text) => {
    return { __html: text };
  };

  return (
    <div className={styles.modalVideoWrapper}>
      <div className={styles.modalVideoContainer}>
        <img src={closeIcon} className={styles.close} onClick={close} />
        {link.type === "youtube" ? (
          <iframe
            width="100%"
            height="100%"
            src={"https://www.youtube.com/embed/" + link.link}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        ) : (
          <div className={styles.frame} dangerouslySetInnerHTML={createMarkup(link.link)}></div>
        )}
      </div>
    </div>
  );
};

export default ModalVideo;
