import React from 'react'
import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getFirstAlbums } from '../../../functions/getPhotos';
import { getFirstVideos } from '../../../functions/getVideos';
import VideosSlider from './Videos/VideosSlider';

import styles from './GalleryPage.module.scss';
import ReadMoreLink from '../../elements/ReadMoreLink/ReadMoreLink';
import ContentContainer from '../../elements/Container/ContentContainer';
import { useRef } from 'react';
import PhotosSlider from './Photos/PhotosSlider';
import getPhotoGalleries from '../../../functions/getPhotoGalleries';
import { globalTranslate } from '../../../functions/languages';


const GalleryPage = () => {
  const firstAlbums = useSelector(state => state.photos.firstAlbums)
  const firstVideos = useSelector(state => state.videos.firstVideos)
  const currentLang = useSelector(state => state.lang.currentLang)

  const containerWidth = useRef()

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getFirstAlbums())
    dispatch(getFirstVideos())
    dispatch(getPhotoGalleries())
  }, [dispatch])
  return (
    <PageTemplate>
      <Container>
        <ContentContainer>
        <h3 ref={containerWidth} style={{marginBottom: 40}}>{globalTranslate(currentLang, 'Галерея')}</h3>
        <div className={styles.head}>
          <h4>{globalTranslate(currentLang, 'Последние фотографии')}</h4>
          <ReadMoreLink text={globalTranslate(currentLang, 'Все фотографии')} link={'/gallery/photos/'} />
        </div>
        <div className='photo'>
        {firstAlbums && firstAlbums.length > 0 && (<PhotosSlider items={firstAlbums} containerWidth={containerWidth} />)} 
        </div>
        <div className={styles.head}>
          <h4>{globalTranslate(currentLang, 'Последние видео')}</h4>
          <ReadMoreLink text={globalTranslate(currentLang, 'Все видео')} link={'/gallery/videos'} />
        </div>
        <div className='photo'>
        {firstVideos && firstVideos.length > 0 && (<VideosSlider items={firstVideos} containerWidth={containerWidth} />)} 
        </div>
        
        </ContentContainer>
      </Container>
    </PageTemplate>
  )
}

export default GalleryPage;