const initialState = {
    streams: false,
    singleStream: false,
  };
  
  const GET_STREAMS = 'GET_STREAMS';
  const GET_SINGLE_STREAM = 'GET_SINGLE_STREAM';
  
  export const steamsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_STREAMS:
        return {
          streams: action.payload.results,
        };
      case GET_SINGLE_STREAM:
        return {...state,
          singleStream: action.payload,
        }
      default:
        return state;
    }
  };
  
  export const getStreamsAction = (payload) => ({ type: GET_STREAMS, payload});
  export const getStreamsByIDAction = (payload) => ({ type: GET_SINGLE_STREAM, payload});