import React, { useState } from "react";
import TextInput from "../../../elements/TextInput/TextInput";
import UploadInput from "../../../elements/UploadInput/UploadInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setContactRegDataAction } from "../../../../redux/Reducers/regInfoReducers";
import Box from "../../../elements/Box/Box";

import activeCheckbox from '../../../../image/icons/active-checkbox.svg';
import disactiveCheckbox from '../../../../image/icons/disactive-checkbox.svg';

import styles from './UserDataForms.module.scss'
import { useRef } from "react";
import { globalTranslate } from "../../../../functions/languages";

const ContactInfoData = ({ errorList, clearError, isValue, change, isDisabled }) => {
  const dispatch = useDispatch();

  const dataRedux = useSelector((state) => state.regInfo);
  const currentLang = useSelector(state => state.lang.currentLang)

  const [contactData, setContactData] = useState({
    phone: '',
    photo: {},
    is_contact_via_manager: false,
    contact_person_fio: isValue ? isValue.contact_person_fio : "",
    contact_person_phone: isValue ? isValue.contact_person_phone : "",
  });
  const [changedData, setChangedData] = useState({})

  useEffect(() => {
    if (!change) return;
    change(changedData);
  }, [changedData])

  useEffect(() => {
    if (!isValue) return;
    setContactData({
      ...contactData,
      photo: isValue.photo,
      phone: isValue.phone,
      is_contact_via_manager: isValue.is_contact_via_manager,
      contact_person_phone: isValue.contact_person_phone,
      contact_person_fio: isValue.contact_person_fio,
    })
  }, [isValue])

  const setPhone = (data) => {
    setContactData({ ...contactData, phone: data });
    setChangedData({...changedData, phone: data})

    clearError(["Укажите номер", 'Номер должен быть в международном формате и начинаться с символа "+"']);
  };
  const setImage = (file) => {
    setContactData({ ...contactData, photo: file });
    setChangedData({...changedData, photo: file})

    clearError("Загрузите фото");
  };
  const setCallManager = (data) => {
    setContactData({ ...contactData, is_contact_via_manager: data });
    setChangedData({...changedData, is_contact_via_manager: data})
  }

  const setFIO = (data) => {
    setContactData({ ...contactData, contact_person_fio: data });
    setChangedData({...changedData, contact_person_fio: data});
  }
  const setPhoneContact = (data) => {
    setContactData({ ...contactData, contact_person_phone: data });
    setChangedData({...changedData, contact_person_phone: data});
  }
  useEffect(() => {
    dispatch(setContactRegDataAction(contactData));
  }, [contactData]);

  const callManagerRef = useRef();

  return (
    <Box maxW={700}>
      <h3>{globalTranslate(currentLang, 'Контактная информация')}</h3>

      <TextInput
        isDisabled={isDisabled}
        type={'number'}
        isValue={contactData.phone}
        labelText={"Номер телефона"}
        onChange={setPhone}
        error={
          errorList &&
          (errorList.includes("Укажите свой номер") ||
            errorList.includes(
              'Номер должен быть в международном формате и начинаться с символа "+"'
            ))
            ? true
            : false
        }
      />
      <div className={styles.checkboxWrapper}>
      <div className={styles.fakeInput} onClick={() => callManagerRef.current.click()}>
            {contactData.is_contact_via_manager ? <img src={activeCheckbox} alt={'check'} /> : <img src={disactiveCheckbox} alt={'false'} />}
          </div>
        <input
          type="checkbox"
          checked={contactData.is_contact_via_manager}
          disabled={isDisabled}
          name=""
          id="is_contact_via_manager"
          onChange={(event) => setCallManager(event.target.checked)}
        />
        <label htmlFor="is_contact_via_manager" ref={callManagerRef}  style={ isDisabled ? {color: '#545454', cursor: 'default'} : {}}>
          {globalTranslate(currentLang, 'Прошу связываться через контактное лицо')}
        </label>
      </div>
      {contactData.is_contact_via_manager && (
        <>
          <TextInput
            isDisabled={isDisabled}
            isValue={contactData.contact_person_fio}
            labelText={"ФИО контактного лица"}
            onChange={setFIO}
            error={
              errorList &&
              (errorList.includes("Введите ФИО контактного лица") ||
                errorList.includes(
                  'Введите корректные ФИО'
                ))
                ? true
                : false
            }
          />
          <TextInput
            isDisabled={isDisabled}
            isValue={contactData.contact_person_phone}
            labelText={"Номер телефона контактного лица"}
            type={'number'}
            onChange={setPhoneContact}
            error={
              errorList &&
              (errorList.includes("Укажите номер контактного лица") ||
                errorList.includes(
                  'Номер телефона должен быть в международном формате и начинаться с символа "+"'
                ))
                ? true
                : false
            }
          />
        </>
      )}

      <UploadInput
        isDisabled={isDisabled}
        isValue={contactData.photo}
        onChange={setImage}
        errorValidate={
          errorList && errorList.includes("Загрузите фото") ? true : false
        }
      />
    </Box>
  );
};

export default ContactInfoData;
