import React, { useMemo, useRef } from "react";

import styles from "../MainPage.module.scss";
import { useSelector } from "react-redux";
import { globalTranslate } from "../../../../functions/languages";

const Timer = ({ days, hours, minutes, seconds, isUnVisible }) => {
  const currentLang = useSelector(state => state.lang.currentLang)
  return (
      <div className={styles.timerWrapper} style={{visibility: 'hidden'}} >
        <div>
          <span>{days}</span>
          <span>{globalTranslate(currentLang, 'дней')}</span>
        </div>
        <div>
          <span>{hours}</span>
          <span>{globalTranslate(currentLang, 'часов')}</span>
        </div>
        <div>
          <span>{minutes}</span>
          <span>{globalTranslate(currentLang, 'минут')}</span>
        </div>
        <div>
          <span>{seconds}</span>
          <span>{globalTranslate(currentLang, 'секунд')}</span>
        </div>
      </div>
  );
};

export default Timer;
