import React from "react";
import styles from "./ModalConfirm.module.scss";
import { useSelector } from 'react-redux';
import { globalTranslate } from "../../../functions/languages";

const ModalConfirm = ({ text, actionSuccess, actionCancel }) => {
  const currentLang = useSelector(state => state.lang.currentLang)

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalContainer}>
        <p>{text}</p>
        <div className={styles.buttonGroup}>
          <button onClick={actionCancel}>{globalTranslate(currentLang, "Отмена")}</button>
          <button onClick={actionSuccess}>{globalTranslate(currentLang, "Да")}</button>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
