import React, { useRef, useState } from "react";
import PageTemplate from "../../PageTemplate/PageTemplate";
import Container from "../Container/Container";

import styles from "../CabinetProfile/CabinetProfile.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import getHotels from "../../../functions/getHotels";
import HotelsSlider from "../HotelsSlider/HotelsSlider";
import ContentContainer from "../Container/ContentContainer";

import websiteIcon from "../../../image/icons/globe_blue.png";
import mailIconBlue from "../../../image/icons/mailIconBlue.svg";
import { useLocation } from "react-router-dom";
import ReadMoreLink from "../ReadMoreLink/ReadMoreLink";
import sendHotelReservation from "../../../functions/sendHotelReservation";
import CalendarInput from "../CalendarInput/CalendarInput";
import Button from "../Button/Button";

const HotelsProfile = () => {
  const [currentHotel, setCurrentHotel] = useState();
  const hotels = useSelector((state) => state.hotels.hotels);
  const dispatch = useDispatch();
  const location = useLocation();

  const [isShowModal, setShowModal] = useState(false);
  const [arrivalDate, setArrivalDate] = useState();
  const [departuresDate, setDeparturesDate] = useState();
  const [errors, setErrors] = useState();
  const [succsess, setSuccess] = useState(false);

  useEffect(() => {
    if (!succsess) return;
    setTimeout(() => {
      setArrivalDate();
      setDeparturesDate();
      setErrors();
      setShowModal(false);
      setSuccess(false);
    }, 5000);
  }, [succsess]);

  const currentUser = useSelector(
    (state) => state.registrations.currentRegistration
  );

  useEffect(() => {
    if (!location) return;
    setCurrentHotel(location.state.item);
  }, [location]);

  const containerWidth = useRef();

  

  useEffect(() => {
    if (!hotels) return;
    // setHotelList(hotels.results);
    // setCurrentHotel(hotels.results[0]);
  }, [hotels]);

  useEffect(() => {
    dispatch(getHotels());
  }, [dispatch]);

  const sendReservation = () => {
    setErrors();
    const data = {
      start_date: arrivalDate,
      end_date: departuresDate,
      hotel_id: currentHotel.id,
      registration_id: currentUser[0].id,
    };
    if (arrivalDate && departuresDate && currentHotel.id && currentUser[0].id) {
      sendHotelReservation(data, setErrors, setSuccess);
    }
  };

  return (
    <PageTemplate>
      <Container>
        <ContentContainer>
          <div className={styles.hotelsWrapper}>
            {isShowModal && (
              <div className={styles.modalWrapper}>
                <div className={styles.modal}>
                  {!succsess ? (
                    <>
                      <p>
                        Ваша заявка будет отправлена на электронную почту отеля.
                        Сотрудники "{currentHotel.title}" свяжутся с вами по
                        почте или номеру телефона, указанным при регистрации.
                      </p>
                      <h4>Укажите даты бонирования</h4>
                      {errors &&
                        errors.map((item, index) => (
                          <p key={index} className={styles.error}>
                            {item}
                          </p>
                        ))}
                      <CalendarInput
                        labelText={"Дата заезда"}
                        onChange={setArrivalDate}
                      />
                      <CalendarInput
                        labelText={"Дата выезда"}
                        onChange={setDeparturesDate}
                      />
                      <div className={styles.button_group}>
                        <Button
                          backColor={"red"}
                          onClick={() => {
                            setArrivalDate();
                            setDeparturesDate();
                            setShowModal(false);
                          }}
                        >
                          Отменить
                        </Button>
                        <Button onClick={sendReservation}>
                          Отправить заявку
                        </Button>
                      </div>
                      <p
                        className={styles.close}
                        onClick={() => setShowModal(false)}
                      >
                        close
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Ваша заявка успешно отправлена на электронную почту
                        отеля. Сотрудники "{currentHotel.title}" свяжутся с вами
                        по почте или номеру телефона, указанным при регистрации.
                      </p>
                      <p>Окно закроется автоматически через 5 секунд...</p>
                    </>
                  )}
                </div>
              </div>
            )}
            {currentHotel && (
              <div className={styles.hotelsItem} ref={containerWidth}>
                <div className={styles.hostelHead}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                      }}
                    >
                      <h4>{currentHotel.title}</h4>
                      <ReadMoreLink text={"Назад"} link={"/pages/hotels"} />
                    </div>
                    <p>{currentHotel.address}</p>
                  </div>
                  <div className={styles.itemContacts}>
                    <div className={styles.site}>
                      <img src={websiteIcon} alt="Сайт" />
                      <a href={currentHotel.link}>Веб-сайт</a>
                    </div>
                    {currentUser &&
                    currentUser.length > 0 &&
                    currentUser[0].is_reg_confirmed &&
                    (currentUser[0].registration_type === "free" ||
                      currentUser[0].registration_type === "paid") ? (
                      <button onClick={() => setShowModal(true)}>
                        Забронировать отель
                      </button>
                    ) : (
                      <div className={styles.email}>
                        <img src={mailIconBlue} alt="" />
                        <a href={currentHotel.email}>{currentHotel.email}</a>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.slider}>
                  <HotelsSlider photos={currentHotel.photos} />
                </div>
                <div className={styles.hotelInfo}>
                  <h5>Описание</h5>
                  <p style={{textAlign: 'justify'}}>{currentHotel.description}</p>
                </div>
              </div>
            )}
          </div>
        </ContentContainer>
      </Container>
    </PageTemplate>
  );
};

export default HotelsProfile;
