import React from "react";
import PageTemplate from "../../PageTemplate/PageTemplate";
import CabinetNav from "../CabinetNav/CabinetNav";
import Container from "../Container/Container";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import getHotels from "../../../functions/getHotels";
import ContentContainer from "../Container/ContentContainer";

import Hotels from '../Hotels/Hotels';
import { globalTranslate } from "../../../functions/languages";

const CabinetProfileHotels = () => {
  const currentLang = useSelector(state => state.lang.currentLang)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHotels());
  }, [dispatch]);

  return (
    <PageTemplate>
      <Container>
        <ContentContainer>
          <CabinetNav />
          <h3>{globalTranslate(currentLang, "Гостиницы")}</h3>
          <Hotels />
          
        </ContentContainer>
      </Container>
    </PageTemplate>
  );
};

export default CabinetProfileHotels;
