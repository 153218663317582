import React, { useState, useEffect, useRef } from "react";

import styles from "./TextInput.module.scss";
import Box from '../Box/Box';
import { useSelector } from 'react-redux';
import { globalTranslate } from "../../../functions/languages";

import showPassIcon from '../../../image/icons/show-password.png';
import hidePassIcon from '../../../image/icons/hide-password.png';

const TextInput = ({
  onChange,
  labelText,
  isValue,
  isDisabled,
  onBlur,
  type,
  // errorText,
  error
}) => {
  const [isInputActive, setIsInputActive] = useState(false);
  const [value, setValue] = useState(isValue ? isValue : "");
  const [isShowPass, setShowPass] = useState(false)
  const [isPass, setPass] = useState()
  // const [error, setError] = useState(withErrorNote);

  const currentLang = useSelector(state => state.lang.currentLang)

  const onChangeHandler = (event) => {
    if (isDisabled) return;
    let valueLocal = event.target.value
      if (type === 'number') {
        const regex = /[A-Za-zА-Яа-яЁё]/g
        valueLocal = valueLocal.replace(regex, '')
        setValue(valueLocal)
        return
      }
      setValue(valueLocal)
  }

  const inputRef = useRef()

  useEffect(() => {
    if (isInputActive && value === '+') {
      inputRef.current.selectionStart = 2
    }
  }, [isInputActive, value])

  useEffect(() => {
    if (onChange) {
      
      onChange(value);
    }
  }, [value])

  const inputOnFocusHandler = () => {
    if (!value && !isDisabled) setIsInputActive(false);
    if (!value && isDisabled) setIsInputActive(false);
    if (value && isDisabled) setIsInputActive(true)
    if (value === '+' && labelText.includes('телефона')) {
      setValue('') 
      setIsInputActive(false)
    }
    onBlur && onBlur();
  };

  const onFocusFunc = () => {
    if (value === '' && labelText.includes('телефона')) {
      setValue('+') 
    }
  }

  useEffect(() => {
    if (!isValue) return
    setValue(isValue);
  }, [isValue])

  useEffect(() => {
    if (isDisabled) {
      !value && setIsInputActive(false);
      return;
    }
    onChange(value);
  }, [value]);

  useEffect(() => {
    if (value !== '') {
      setIsInputActive(true)
    }
  }, [value])

  return (
    <Box maxW={700}>
      <label
        className={styles.labelWrapper}
        onClick={() => setIsInputActive(true)}
        onFocus={() => setIsInputActive(true)}
        style={labelText.includes('Дата') ? {cursor: 'pointer'} : {}}
      >
        <span className={isInputActive ? styles.activeLabel : styles.label} onClick={() => setIsInputActive(true)}>
          {globalTranslate(currentLang, labelText)}
        </span>
        <input
          autoComplete="off"
          ref={inputRef}
          type={type === 'isPass' ? isShowPass ? 'text' : 'password' : "text"}
          disabled={isDisabled}
          name="fdssdfsd"
          value={value}
          onChange={onChangeHandler}
          onFocus={onFocusFunc}
          onBlur={inputOnFocusHandler}
          style={error ? {backgroundColor: '#FFEDED'} : labelText.includes('Дата') ? {cursor: 'pointer'} : {}}
        
        />
        {/* {withErrorNote && <div className={styles.error}> <p>{ errorText }</p> </div>} */}
        {(type === 'isPass' && value.length > 0) && (isShowPass ? <img src={hidePassIcon} onClick={() => setShowPass(!isShowPass)} /> : <img src={showPassIcon} onClick={() => setShowPass(!isShowPass)} />)}
      </label>
    </Box>
  );
};

// onBlur && (() => onBlur())

export default TextInput;
