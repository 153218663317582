import React from "react";

import styles from "./NewsSinglePage.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import PageTemplate from "../../PageTemplate/PageTemplate";
import Container from "../../elements/Container/Container";
import { translitUrls } from "../../../functions/translitUrls";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { ReactComponent as Clock } from "../../../image/icons/clock.svg";
import moment from "moment/moment";
import ReadMoreLink from "../../elements/ReadMoreLink/ReadMoreLink";
import { getNewsById } from '../../../functions/getNews';
import ContentContainer from '../../elements/Container/ContentContainer';
import { BASE_URL } from "../../../BASE_URL";
import { globalTranslate } from "../../../functions/languages";

const NewsSinglePage = () => {
  const [singleNews, setSingleNews] = useState();
  const currentNews = useSelector((state) => state.news.currentNews);
  const currentLang = useSelector(state => state.lang.currentLang)
  const { name, page } = useParams();

  const dispatch = useDispatch()

  useEffect(() => {
     dispatch(getNewsById(name));
  }, [dispatch])

  const formateDate = (date) => {
    const localDate = new Date(date);
    moment.locale(currentLang === 'ru' ? "ru" : 'en');
    const getmoment = moment(localDate, "MMM").format();
    const dateTo = moment(getmoment).format("D MMM YYYY / hh:mm");
    return dateTo;
  };

  const createMarkup = (text) => {
    const strWithImg = text.replaceAll("/media", `${BASE_URL}media`)
    const promoStr = strWithImg.replaceAll("../", "")
    return { __html: promoStr };
  };

  useEffect(() => {
    if (!currentNews) return;
      setSingleNews(currentNews);
  }, [currentNews]);


  return (
    <PageTemplate>
      <Container>
        <ContentContainer>
        <div className={styles.newsWrapper}>
          {singleNews && (
            <>
              <h2>{currentLang === 'ru' ? singleNews.title : singleNews.title_en}</h2>
              <div className={styles.date}>
                <Clock
                  width={17}
                  height={17}
                  fill={"#696969"}
                  viewBox="0 0 18 18"
                />
                <span>{formateDate(singleNews.publish_time)}</span>
              </div>
              <div 
                className={styles.newsBody}
                dangerouslySetInnerHTML={createMarkup(currentLang === 'ru' ? singleNews.max_description : singleNews.max_description_en)}
              ></div>
              <div style={{marginBottom: 30}}>
                <ReadMoreLink text={globalTranslate(currentLang, 'Назад к списку новостей')} link={'/news/' + page} />
              </div>
            </>
          )}
        </div>
        </ContentContainer>
      </Container>
    </PageTemplate>
  );
};

export default NewsSinglePage;
