const initialState = {
  countNews: 0,
  next: false,
  prev: false,
  newsList: [],
  mainPageNews: [],
  currentNews: false,
  search_results: false
};

const GET_NEWS = "GET_NEWS";
const GET_MAIN_PAGE_NEWS = 'GET_MAIN_PAGE_NEWS';
const GET_CURRENT_NEWS = 'GET_CURRENT_NEWS';
const GET_NEWS_BY_SEARCH = 'GET_NEWS_BY_SEARCH';

export const newsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWS:
      return {...state,
        countNews: action.payload.count,
        next: action.payload.next,
        prev: action.payload.previous,
        newsList: action.payload.results,
      };
      case GET_MAIN_PAGE_NEWS:
        return {...state, mainPageNews: action.payload.results}
      case GET_CURRENT_NEWS:
        return {...state, currentNews: action.payload}
      case GET_NEWS_BY_SEARCH:
        return {...state, 
          countNews: action.payload.count,
          next: action.payload.next,
          prev: action.payload.previous,
          search_results: action.payload.results
        }
    default:
      return state;
  }
};

export const getNewsAction = (payload) => ({ type: GET_NEWS, payload });
export const getNewsBySearchAction = (payload) => ({ type: GET_NEWS_BY_SEARCH, payload });
export const getMainPageNewsAction = (payload) => ({ type: GET_MAIN_PAGE_NEWS, payload });
export const getCurrentNewsAction = (payload) => ({ type: GET_CURRENT_NEWS, payload });
