import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SucceessRegPage = () => {
  const navigate = useNavigate();

  
  
  useEffect(() => {
    navigate('/cabinet/company');
  }, [])

  return (
    <div></div>
  )
}

export default SucceessRegPage