import React, { useEffect, useMemo } from "react";
import Container from "../../elements/Container/Container";
import PageTemplate from "../../PageTemplate/PageTemplate";
import { useSelector } from "react-redux";
import Button from "../../elements/Button/Button";
import { logout } from "../../../functions/logout";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PersonalRegPage from "../RegPage/PersonalRegPage";
import CompanyRegPage from "../RegPage/CompanyRegPage";
import Box from "../../elements/Box/Box";
import CabinetNav from "../../elements/CabinetNav/CabinetNav";
import CabinetProfile from "../../elements/CabinetProfile/CabinetProfile";
import bluePlus from "../../../image/icons/blue_plus.svg";
import bluePlusSmall from "../../../image/icons/blue_plus.svg";

import styles from "./CabinetPage.module.scss";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import PofileDeleteModal from "../../elements/CabinetProfile/PofileDeleteModal";
import getCompanySphere from "../../../functions/getCompanySphere";
import getCountries from "../../../functions/getCountries";
import getOrganizationForms from "../../../functions/getOrganizationForms";
import getDocuments from "../../../functions/getDocuments";
import getCategoryOfParticipants from "../../../functions/getCategoryOfParticipants";
import { getCompanyRegistrations } from "../../../functions/getCompanyRegistrations";
import getCars from "../../../functions/getCars";
import { isAuth } from "../../../functions/isAuth";
import getMovementType from "../../../functions/getMovementType";
import { globalTranslate } from "../../../functions/languages";
import getSlots from "../../../functions/getSlots";

const CabinetPage = () => {
  const [deletedID, setDeletedID] = useState();
  const [isShowModal, setShowModal] = useState(false);
  const [slots, setSlots] = useState();
  const [regsLocal, setRegsLocal] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const regs = useSelector((state) => state.registrations.currentRegistration);
  const isAuthRedux = useSelector((state) => state.isAuth.isAuth);
  const user = useSelector((state) => state.user);
  const currentLang = useSelector((state) => state.lang.currentLang);

  const aviableCategories = useSelector((state) => state.categoryList.category);
  const slotsRedux = useSelector((state) => state.slots.slots);

  const setNewItems = useMemo(() => setRegsLocal(regs), [regs]);

  useEffect(() => {
    setSlots(slotsRedux);
  }, [slotsRedux]);

  useEffect(() => {
    if (window.localStorage.token) {
      isAuth(dispatch);
      dispatch(getCars());

      dispatch(getCategoryOfParticipants());
      dispatch(getSlots());
      dispatch(getDocuments());
      dispatch(getMovementType());
    }
    dispatch(getCompanySphere());
    dispatch(getCountries());
    dispatch(getOrganizationForms());
  }, [dispatch]);

  const deletePersone = (data) => {
    setShowModal(true);
    setDeletedID(data);
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (isAuthRedux) {
      if (user.is_manager) {
        if (regs && regs.length > 0) {
          navigate("/cabinet/my_registrations/");
        } else {
          navigate("/cabinet/company/");
        }
      } else {
        if (regs && regs.length > 0) {
          navigate("/cabinet/my_profile/");
        } else {
          navigate("/cabinet/personal/");
        }
      }
      return;
    }
    navigate("/login");
  }, [isAuthRedux]);

  return (
    <PageTemplate
      children={
        <Container
          children={
            <>
              {isShowModal && (
                <PofileDeleteModal
                  cancelAction={cancelDelete}
                  data={deletedID}
                  successAction={() => setShowModal(false)}
                />
              )}
              <CabinetNav />
              {user.is_manager &&
                (slots && slots.length > 0 ? (
                  <div className={styles.slots}>
                    <span>Доступное количество анкет: </span>
                    <ul>
                      {slots.map((item, index) => (
                        <li key={index}>
                          {item.title}: {item.slots_count}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <span
                    style={{
                      display: "block",
                      maxWidth: 700,
                      marginBottom: 40,
                    }}
                  >
                    Вы сможете зарегистрировать других сотрудников после того,
                    как участие компании будет одобрено и будет определена квота
                    на участие в Форуме. После одобрения на указанную почту
                    будет направлено письмо с подтверждением, количество
                    участников будет отражено в личном кабинете компании.
                  </span>
                ))}
              <div className={styles.profilesWrapper}>
                {regsLocal &&
                  regsLocal.length > 0 &&
                  regsLocal.map((item, index) => (
                    <CabinetProfile
                      key={index}
                      item={item}
                      slots={slots}
                      getNewSlots={() => dispatch(getSlots())}
                      deletePersone={deletePersone}
                    />
                  ))}
                {user.is_manager &&
                  (slots && slots.length > 0 ? (
                    <div className={styles.defaultItemWrapp}>
                      <Link to={"/cabinet/company"}>
                        <div className={styles.defaultItem}>
                          <div className={styles.imgWrap}>
                            {!window.innerWidth > 1000 ? (
                              <img src={bluePlus} alt="Добавить участника" />
                            ) : (
                              <img
                                src={bluePlusSmall}
                                alt="Добавить участника"
                              />
                            )}
                          </div>
                          <span>
                            {globalTranslate(currentLang, "Добавить")}
                            {!window.innerWidth > 1000 ? <br /> : " "}
                            {globalTranslate(currentLang, "участника")}
                          </span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  ))}
              </div>
              {/* {user.is_manager ? <CompanyRegPage /> : <PersonalRegPage />} */}
            </>
          }
        />
      }
    />
  );
};

export default CabinetPage;
