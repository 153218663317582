const initialState = {
    category: [],
};
  
  const SET_CATEGORY = 'SET_CATEGORY';
  
  export const categoryOfParticipantsReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_CATEGORY:
        return {
            category: action.payload.results,
        };
      default:
        return state;
    }
  };
  
  export const setcategoryOfParticipantsAction = (payload) => ({ type: SET_CATEGORY, payload});