import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SuccessAddCar = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/cabinet/my_cars");
  }, []);
  return;
};

export default SuccessAddCar;
