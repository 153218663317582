import { BASE_URL_API } from '../BASE_URL.js';
import { getCarsAction } from '../redux/Reducers/carsReducer.js';

const getCars = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'accreditations/cars/', {
            headers: {
                Authorization: "Token " + window.localStorage.token,
            }
        });
        const data = await response.json();
        dispatch(getCarsAction(data));
    }
}

export default getCars;