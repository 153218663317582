const initialState = {
    slots: false,
  };
  
  const GET_SLOTS = 'GET_SLOTS';
  
  export const slotsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SLOTS:
        const arr = action.payload.filter(item => item.slots_count > 0)
        return arr.length > 0 ? {slots: arr} : {slots: arr};
      default:
        return state;
    }
  };
  
  export const getSlotsAction = (payload) => ({ type: GET_SLOTS, payload});