import React from 'react';

import styles from './Button.module.scss';

const Button = ({ children, onClick, disabled, backColor }) => {
  return (
    <button style={{backgroundColor: backColor}} disabled={disabled ? 'true' : ''} onClick={onClick} className={styles.button}>{children}</button>
  )
}

export default Button;