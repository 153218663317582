import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "./HotelsSlider.module.scss";
import { useState } from "react";
import sliderStyles from './sliderStyles.scss'

const HotelsSlider = ({ photos }) => {
  const [slider1, setSlider1] = useState();
  const [slider2, setSlider2] = useState();
  const [mainImgHeight, setMainImgHeight] = useState(0);
  const [secondImgWidth, setSecondImgWidth] = useState(300);
  const [secondImgHeight, setSecondImgHeight] = useState(0);

  const mainImg = useRef();

  const resize = () => {
    if (!mainImg) return;

    setMainImgHeight(mainImg.current.clientWidth * 0.58)
    setSecondImgWidth(mainImg.current.clientWidth / 3 - 2)
    setSecondImgHeight((mainImg.current.clientWidth / 3) * 0.58)
  }

  useEffect(() => {
    resize()
  }, [mainImg])

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  const settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: "-1px",
  };
  return (
    <div className="sliderWrapper">
      <Slider {...settings1} ref={slider1 => setSlider1(slider1)} asNavFor={slider2}>
        {photos &&
          photos.map((item, index) => (
            <div key={index} className={styles.sliderItem} ref={mainImg}  style={{maxHeight: mainImgHeight}} >
              <img src={item.cropped_image} alt={item.id} />
            </div>
          ))}
      </Slider>
      <Slider {...settings2} ref={slider2 => setSlider2(slider2)} asNavFor={slider1} >
        {photos &&
          photos.map((item, index) => (
            <div key={index} className={styles.sliderItem} >
              <img src={item.cropped_image} alt={item.id} style={{width: secondImgWidth, height: secondImgHeight, objectFit: 'cover'}}/>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default HotelsSlider;
