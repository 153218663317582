import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import styles from "./CabinetNav.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../functions/logout";
import { isMobile } from "react-device-detect";
import Container from "../Container/Container";
import { globalTranslate } from "../../../functions/languages";

const CabinetNav = () => {
  const dispatch = useDispatch();
  const is_manager = useSelector((state) => state.user.is_manager);
  const currentLang = useSelector((state) => state.lang.currentLang);
  const navigate = useNavigate();

  const logoutHanler = async () => {
    await logout(dispatch, navigate);
  };

  const currentTypeOfRegistration = useSelector(
    (state) => state.registrations.currentRegistration
  );

  return (
    <ul className={styles.cabinetNav}>
      {is_manager ? (
        <li>
          <NavLink
            to={"/cabinet/my_registrations"}
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            {globalTranslate(currentLang, 'Общие регистрации')}
          </NavLink>
        </li>
      ) : (
        <li>
          <NavLink
            to={"/cabinet/my_profile"}
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            {globalTranslate(currentLang, 'Мой профиль')}
          </NavLink>
        </li>
      )}
      {currentTypeOfRegistration &&
        currentTypeOfRegistration.length > 0 &&
        currentTypeOfRegistration[0].registration_type !== "onln" && (
          <>
            <li>
              <NavLink
                to={"/cabinet/my_cars"}
                className={({ isActive }) =>
                  isActive ? styles.activeLink : ""
                }
              >
                {globalTranslate(currentLang, 'Аккредитация ТС')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/cabinet/my_documents"}
                className={({ isActive }) =>
                  isActive ? styles.activeLink : ""
                }
              >
                {globalTranslate(currentLang, 'Документы')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/cabinet/my_hotels"}
                className={({ isActive }) =>
                  isActive ? styles.activeLink : ""
                }
              >
                {globalTranslate(currentLang, 'Гостиницы')}
              </NavLink>
            </li>
          </>
        )}

      <li>
        <NavLink
          to={"/cabinet/my_settings"}
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
        >
          {globalTranslate(currentLang, 'Настройки')}
        </NavLink>
      </li>

      <a onClick={logoutHanler} style={{ color: "#ED1941" }}>
        {globalTranslate(currentLang, 'Выйти')}
      </a>
    </ul>
  );
};

export default CabinetNav;
