import moment from "moment/moment";
import React from "react";

import styles from "./NewsPage.module.scss";
import { ReactComponent as Clock } from "../../../image/icons/clock.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { translitUrls } from '../../../functions/translitUrls';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from "react";
import { getNewsById } from '../../../functions/getNews';
import { BASE_URL } from "../../../BASE_URL";

const NewsItem = ({ date, title, description, slug }) => {
  const [isLoad, setIsLoad] = useState(false)  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentLang = useSelector(state => state.lang.currentLang)

  const formateDate = (date) => {
    const localDate = new Date(date);
    moment.locale(currentLang);
    const getmoment = moment(localDate, "MMM").format();
    const dateTo = moment(getmoment).format("D MMM YYYY / hh:mm");
    return dateTo;
  };

  const createMarkup = (text) => {
    const strWithImg = text.replaceAll("/media", `${BASE_URL}media`)
    const promoStr = strWithImg.replaceAll("../", "")
    return { __html: promoStr };
  };

  const { page, name } = useParams();



  const newsItemClickHandler = () => {
    dispatch(getNewsById(slug, navigate, page));
  }

  return (
    <div className={styles.item} onClick={newsItemClickHandler}>
        <div className={styles.date}>
          <Clock width={17} height={17} fill={"#696969"} viewBox="0 0 18 18" />
          <span>{formateDate(date)}</span>
        </div>
        <div className={styles.text}>
          <p className={styles.title}>{title}</p>
          {description && (
            <div
            className={styles.description}
            dangerouslySetInnerHTML={createMarkup(description)}
          ></div>
          )}
        </div>
    </div>
  );
};

export default NewsItem;
