import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ChooseTypeReg from "./ChooseTypeReg";

import styles from "./RegPage.module.scss";
import PersonalData from "./UserDataForms/PersonalData";
import CompanyInfo from "./UserDataForms/CompanyInfo";
import ContactPersonData from "./UserDataForms/ContactPersonData";
import getCountries from "../../../functions/getCountries";
import PassData from "./UserDataForms/PassData";
import ContactInfoData from "./UserDataForms/ContactInfoData";
import AgreementsData from "./UserDataForms/AgreementsData";
import Button from "../../elements/Button/Button";
import Box from "../../elements/Box/Box";
import testFile from "../../../functions/testFile";
import ArrivalInfo from "./UserDataForms/ArrivalInfo";
import { Link, useNavigate } from "react-router-dom";
import { validate, validateSingleReg } from "../../../functions/validate";
import getCategoryOfParticipants from "../../../functions/getCategoryOfParticipants";
import PageTemplate from "../../PageTemplate/PageTemplate";
import Container from "../../elements/Container/Container";
import { logout } from "../../../functions/logout";
import { setCurrentRegistrationsAction } from "../../../redux/Reducers/companyRegCounter";
import { globalTranslate } from "../../../functions/languages";

const PersonalRegPage = () => {
  const [errorList, setErrorList] = useState();
  const [isLoad, setLoad] = useState(false);
  const [currentTypeOfRegistration, setCurrentTypeRegistration] = useState();
  const [isOpenRegistration, setOpenRegistration] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentLang = useSelector((state) => state.lang.currentLang);
  
  const currentTypeOfRegistrationRedux = useSelector(
    (state) => state.regInfo.registration_type
  );

  useEffect(() => {
    dispatch(getCategoryOfParticipants());
  }, []);


  const typeOfRegistration = useSelector(
    (state) => state.registrationType.types
  );

  useEffect(() => {
    if (!typeOfRegistration) return;
    if (typeOfRegistration.reg_allow_person) {
      setOpenRegistration(true);
    } else {
      return;
    }
    let flag = false;

    for (let key in typeOfRegistration) {
      if (typeOfRegistration[key]) flag = true;
    }
  }, [typeOfRegistration]);

  useEffect(() => {
    setCurrentTypeRegistration(currentTypeOfRegistrationRedux);
  }, [currentTypeOfRegistrationRedux]);

  const reg_smi_text = useSelector((state) => state.regDocs.reg_smi_text);
  const reg_smi_textEn = useSelector((state) => state.regDocs.reg_smi_text_en);
  const createMarkup = (text) => {
    return { __html: text };
  };

  const [isRegistr, setIsRegistr] = useState(false);

  const regData = useSelector((state) => state.regInfo);
  const user = useSelector((state) => state.user);
  const isRegistration = useSelector(
    (state) => state.registrations.registrationCounter
  );

  const apllyReg = () => {
    setErrorList();
    setLoad(true);
    const validate = validateSingleReg(regData, currentTypeOfRegistration);
    if (validate === true) {
      testFile(
        regData,
        dispatch,
        navigate,
        "/cabinet/my_profile",
        setLoad,
        setErrorList
      );
    } else {
      setErrorList(validate);
      setLoad(false);
    }
  };

  const clearError = (error) => {
    if (!errorList) return;
    setErrorList(errorList.filter((item) => item !== error));
  };

  const logoutHanler = async () => {
    await logout(dispatch, navigate);
  };

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    if (!isRegistration) return;
    setIsRegistr(true);
  }, [isRegistration]);

  return (
    <PageTemplate>
      <Container>
        {isOpenRegistration ? (
        <>
          {isRegistr ? (
            <div style={{ maxWidth: "700px" }}>
              <h3>{globalTranslate(currentLang, "Добро пожаловать в личный кабинет")}</h3>
              <p>
                {globalTranslate(currentLang, "Вы уже успешно зарегистрированы. Ваша заявка на участие в Красноярском экономическом форуме отправлена модераторам. Дождитесь подтверждения на указанную почту.")}
              </p>
            </div>
          ) : (
            <>
              <ChooseTypeReg clearErrorList={setErrorList} />
              {currentTypeOfRegistration &&
                currentTypeOfRegistration === "free" && (
                  <>
                    <PersonalData
                      invite={true}
                      errorList={errorList}
                      clearError={clearError}
                    />
                    <PassData errorList={errorList} clearError={clearError} />
                    <ContactInfoData
                      errorList={errorList}
                      clearError={clearError}
                    />
                    <CompanyInfo
                      invite={true}
                      errorList={errorList}
                      clearError={clearError}
                    />
                    {/* {regData.is_contact_via_manager && <ContactPersonData errorList={errorList} clearError={clearError} />} */}
                    <ArrivalInfo
                      errorList={errorList}
                      clearError={clearError}
                    />
                    <AgreementsData
                      isPayed={false}
                      errorList={errorList}
                      clearError={clearError}
                    />
                  </>
                )}
              {currentTypeOfRegistration &&
                currentTypeOfRegistration === "onln" && (
                  <>
                    <PersonalData
                      invite={true}
                      errorList={errorList}
                      isOnline={true}
                      clearError={clearError}
                    />
                    <PassData
                      isOnline={true}
                      errorList={errorList}
                      clearError={clearError}
                    />
                    <ContactInfoData
                      errorList={errorList}
                      clearError={clearError}
                    />
                    <AgreementsData
                      isPayed={false}
                      errorList={errorList}
                      clearError={clearError}
                    />
                  </>
                )}
              {currentTypeOfRegistration &&
                currentTypeOfRegistration === "paid" && (
                  <>
                    <span className={styles.notes}>
                      * {globalTranslate(currentLang, "Пожалуйста, ознакомьтесь с описанием пакетов участия ")} {" "}
                      <a href="/pakety-uchastiya-v-forume" target="_blank">
                        {globalTranslate(currentLang, "здесь.")}
                      </a>{" "}
                    </span>
                    <PersonalData
                      errorList={errorList}
                      clearError={clearError}
                      isPayed={currentTypeOfRegistration === "paid"}
                    />
                    <PassData errorList={errorList} clearError={clearError} />
                    <ContactInfoData
                      errorList={errorList}
                      clearError={clearError}
                    />
                    <CompanyInfo
                      errorList={errorList}
                      clearError={clearError}
                    />
                    {/* {regData.is_contact_via_manager && <ContactPersonData />} */}
                    <ArrivalInfo
                      errorList={errorList}
                      clearError={clearError}
                    />
                    <AgreementsData
                      isPayed={true}
                      errorList={errorList}
                      clearError={clearError}
                    />
                  </>
                )}
              {currentTypeOfRegistration &&
                currentTypeOfRegistration === "smi" && (
                  <>
                    <div
                      dangerouslySetInnerHTML={createMarkup(currentLang === 'ru' ? reg_smi_text : reg_smi_textEn)}
                      className={styles.notes}
                    ></div>
                    <PersonalData
                      isSMI={true}
                      errorList={errorList}
                      clearError={clearError}
                    />
                    <PassData errorList={errorList} clearError={clearError} />
                    <ContactInfoData
                      errorList={errorList}
                      clearError={clearError}
                    />
                    <CompanyInfo
                      invite={true}
                      isSMI={true}
                      errorList={errorList}
                      clearError={clearError}
                    />
                    {/* {regData.is_contact_via_manager && <ContactPersonData />} */}
                    <AgreementsData
                      errorList={errorList}
                      clearError={clearError}
                    />

                    <div style={{ marginTop: "60px", marginBottom: "60px" }}>
                      <span className={styles.notes}>
                        {globalTranslate(currentLang, 'Исполнитель согласно Федеральному закону от 27.07.2006 года №152 ФЗ "О персональных данных" обеспечивает конфиденциальность и безопасность полученных персональных данных. Согласно статье 6 Федерального закона от 27.07.2006 года №152 ФЗ "О персональных данных" Исполнитель в период с момента предоставления настоящих персональных данных обрабатывает данные субъекта персональных данных как с помощью собственных программно-аппаратных средств так и без их использования. Под обработкой персональных данных понимаются действия (операции) с персональными данными, включая сбор, систематизацию, накопление, хранение, уточнение, обновление, изменение полностью или в части, использование, распространение, передачу, обезличивание, блокирование и/или уничтожение персональных данных.')}
                      </span>
                    </div>
                  </>
                )}

              <ul className={styles.errorListWrapper}>
                {errorList &&
                  errorList.map((item, index) => (
                    <li key={index} className={styles.error}>
                      {globalTranslate(currentLang, item)}
                    </li>
                  ))}
              </ul>

              <div style={{ marginTop: "60px", marginBottom: "20px" }}>
                <Button disabled={isLoad} onClick={apllyReg}>
                  {isLoad ? globalTranslate(currentLang, "Отправляем запрос...") : globalTranslate(currentLang, "Зарегистрироваться")}
                </Button>
              </div>
              <div style={{ marginTop: 0, marginBottom: 20 }}>
                  <Button onClick={logoutHanler}>{globalTranslate(currentLang, "Выйти")}</Button>
                </div>
            </>
          )}
        </>
        ) : (
          <>
            <p>
              {globalTranslate(currentLang, 'В данный момент регистрация закрыта. Вы не сможете создать личный кабинет.')}
            </p>
            <div style={{ marginTop: 0, marginBottom: 20 }}>
              <Button onClick={logoutHanler}>{globalTranslate(currentLang, "Выйти")}</Button>
            </div>
          </>
        )}
      </Container>
    </PageTemplate>
  );
};

export default PersonalRegPage;
