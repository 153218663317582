import React from "react";

import styles from "./Footer.module.scss";
import FooterNav from "./FooterNav/FooterNav";
import Container from "../Container/Container";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import vk from '../../../image/icons/vk.svg';
import tg from '../../../image/icons/tg.svg';
import ok from '../../../image/icons/ok.svg';
import web from '../../../image/icons/web.svg';
import { globalTranslate } from "../../../functions/languages";

const Footer = ({ refLink }) => {
  const date = new Date();
  const currentYear = date.getFullYear();

  const socials = useSelector((state) => state.socials.links);
  const currentLang = useSelector((state) => state.lang.currentLang);

  const chooseLinkType = (type) => {
    let result;

    if (type === 'vk') result = vk;
    if (type === 'tg') result = tg;
    if (type === 'ok') result = ok;
    if (type === 'cm') result = web;

    return result
  }

  return (
    <div ref={refLink} id="footer" className={styles.Footer}>
      <Container>
        <FooterNav />
        <div className={styles.socialsWrapper}>
          {socials &&
            socials.length > 0 &&
            socials.map((item, index) => (
              <a key={index} href={item.link} target="_blank">
                <img src={chooseLinkType(item.type)} alt="" />
              </a>
            ))}
        </div>

        <div className={styles.copy}>
          <span>© {currentYear} {globalTranslate(currentLang, 'Все права защищены')}.</span>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
