import { BASE_URL_API } from "../BASE_URL.js";

const sendHotelReservation = async (userData, setErrors, setSuccess) => {
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");

    const response = await fetch(BASE_URL_API + "hotels/register/", {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
        Authorization: "Token " + window.localStorage.token,
      },
      body: JSON.stringify(userData),
      method: "POST",
    });

    if (response.status === 400) {
      const error = await response.json()
      const errorArray = []
      for (let key in error) {
        errorArray.push(error[key])
      }
      setErrors(errorArray)
    }
    if (response.status === 201) {
      setSuccess(true)
    }
};

export default sendHotelReservation;
