import React, { useState } from "react";

import styles from "./NavMenu.module.scss";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { globalTranslate } from "../../../functions/languages";

import { ReactComponent as MainMenuArrowIcon } from "../../../image/icons/arrow-left-grey.svg";
import { useEffect } from "react";

const NavMenuItem = ({ text, url, is_external_link, childrenList, marginBottom = 20 }) => {
  const [isShowSubmenu, setShowSubmenu] = useState(false)
  const [isMobile, setMobile] = useState(false)

  const currentLang = useSelector(state => state.lang.currentLang);
  const { pathname } = useLocation()

  const checkMobile = () => {
    if (window.innerWidth < 1200) setMobile(true)
    if (window.innerWidth > 1200) setMobile(false)
    // if (pathname.includes('pages/hotels')) setMobile(true)
  }

  useEffect(() => {
    window.addEventListener('resize', checkMobile)

    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  useEffect(() => {
    setShowSubmenu(false)
  }, [pathname])
  return (
    <li
      className={styles.NavMenuItem}
      onMouseOver={() => setShowSubmenu(true)}
      onMouseLeave={() => setShowSubmenu(false)}
      // style={{ marginBottom: marginBottom + "px" }}
    >
      {childrenList && pathname.includes(url) && (!isMobile ? <div id="currentPageMarker"><MainMenuArrowIcon /></div> : null)}
      {/* {childrenList && (
        <ul className={styles.subNavMenu} style={isShowSubmenu ? window.innerWidth > 1000 ? {display: 'block'} : {height: 'auto', marginTop: '20px', paddinTop: '10px', paddingBottom: ''} : window.innerWidth > 1000 ? {display: 'none'} : {height: '0px', marginTop: '0px', paddingTop: '0px', paddingBottom: '0px'}}>
          <li>
            {is_external_link ? (
              <a href={url} target="_blank" rel="noreferrer">{globalTranslate(currentLang, text)}</a>
            ) : (
              <Link to={url}>{globalTranslate(currentLang, text)}</Link>
            )}
          </li>
          {childrenList.map((item, index) => 
            <li key={index}>
              {item.is_external_link ? (
                <a href={item.link} target="_blank" rel="noreferrer">{globalTranslate(currentLang, text)}</a>
              ) : (
                <Link to={'/pages/' + item.link}>{item.title}</Link>
              )}
            </li>)}
          </ul>
        )
        } */}
      {is_external_link ? (
        <a href={url} target="_blank" rel="noreferrer">{globalTranslate(currentLang, text)}</a>
      ) : (
        <Link to={url}>{globalTranslate(currentLang, text)}</Link>
      )}
    </li>
  );
};

export default NavMenuItem;
