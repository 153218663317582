import { BASE_URL_API } from '../BASE_URL.js';
import { setCounterAction, setCurrentRegistrationsAction } from '../redux/Reducers/companyRegCounter';

export const getCompanyRegistrations = async (dispatch) => {
    const response = await fetch(BASE_URL_API + "registrations/registrations/", {
        headers: {
          Authorization: "Token " + window.localStorage.token,
        },
      });
      const data = await response.json();
      data.registrations && dispatch(setCounterAction(data.registrations.length))
      dispatch(setCurrentRegistrationsAction(data.registrations))

}