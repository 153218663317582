import { BASE_URL_API } from '../BASE_URL.js';
import { getCitiesAction } from '../redux/Reducers/locationsReducers.js';
import { getOrganizationFormsAction } from '../redux/Reducers/organisationFormsReducer.js';

const getOrganizationForms = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'registrations/company-forms/');
        const data = await response.json();

        dispatch(getOrganizationFormsAction(data.results));
    }
}

export default getOrganizationForms;