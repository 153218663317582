import { BASE_URL_API } from '../BASE_URL.js';
import { getContactsAction } from '../redux/Reducers/contactsReducer.js';

const getContacts = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'contacts/');
        const data = await response.json();

        dispatch(getContactsAction(data));
    }
}

export default getContacts;