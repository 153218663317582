import { BASE_URL_API } from '../BASE_URL.js';

import { getRegistrationTypesAction } from '../redux/Reducers/registrationTypeReducer.js';

const getRegistraionType = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'base/status/');
        const data = await response.json();

        dispatch(getRegistrationTypesAction(data));
    }
}

export default getRegistraionType;