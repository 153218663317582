import { BASE_URL_API } from '../BASE_URL.js';
import { getIndexConstanceAction } from '../redux/Reducers/indexConstanceReducer.js';

const getIndexConstance = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'base/index/');
        const data = await response.json();

        dispatch(getIndexConstanceAction(data));
    }
}

export default getIndexConstance;