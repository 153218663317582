import React, { useState, useEffect } from 'react';
import Footer from '../elements/Footer/Footer';
import NavMenu from '../elements/NavMenu/NavMenu';
import NonTransparentHeader from './NonTransparentHeader';

import styles from './PageTemplate.module.scss';
import TransparentHeader from './TransparentHeader';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import getMenu from '../../functions/getMenu';

const PageTemplate = ({ children, isMainPage, title }) => {
  const [footerHeight, setFooterHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const location = useLocation();

  const getHeaderHeight = (height) => {
    setHeaderHeight(height)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    setFooterHeight(document.getElementById('footer').clientHeight);
  }, [])
    
  return (
    <div className={styles.PageTemplate}>
        { isMainPage ? (null) : <NonTransparentHeader getHeight={getHeaderHeight} />}
        {/* {!location.pathname.includes('/forum_program') && <NavMenu /> } */}
        <NavMenu />
        {title && <h2>{title}</h2> }
        <div style={window.innerWidth < 1000 ? {flex: '1 1 auto', minHeight: `calc(100vh - ${headerHeight}px`} : {flex: '1 1 auto'}}>{children}</div>
        <Footer />
    </div>
  )
}

export default PageTemplate;