import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Container from "../../elements/Container/Container";
import PageTemplate from "../../PageTemplate/PageTemplate";
import { useDispatch } from "react-redux";
import getContacts from "../../../functions/getContacts";

import { ReactComponent as EmailIcon } from "../../../image/icons/mail-icon.svg";
import { ReactComponent as PhoneIcon } from "../../../image/icons/phone-icon.svg";
import { ReactComponent as LocationIcon } from "../../../image/icons/location-icon.svg";

import styles from "./ContactsPage.module.scss";
import { globalTranslate } from "../../../functions/languages";

const ContactsPage = () => {
  const [emails, setEmails] = useState();
  const [places, setPlaces] = useState();
  const [phones, setPhones] = useState();
  const [maps, setMaps] = useState();
  const [socials, setSocials] = useState();
  
  const contacts = useSelector((state) => state.contacts.contacts);
  const currentLang = useSelector(state => state.lang.currentLang)
  const dispatch = useDispatch();

  useEffect(() => {
    if (contacts.length && contacts.length > 0) {
      const emailItems = contacts.filter((item) => item.type === "email");
      emailItems.length > 0 && setEmails(emailItems);

      const placeItems = contacts.filter((item) => item.type === "place");
      placeItems.length > 0 && setPlaces(placeItems);

      const phoneItems = contacts.filter((item) => item.type === "phone");
      phoneItems.length > 0 && setPhones(phoneItems);

      const mapItems = contacts.filter((item) => item.type === "map");
      mapItems.length > 0 && setMaps(mapItems);

      const socialItems = contacts.filter((item) => item.type === "social");
      socialItems.length > 0 && setSocials(socialItems);
    }
  }, [contacts]);

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  const createMarkup = (text) => {
    return { __html: text };
  };

  return (
    <PageTemplate>
      <Container>
        <h3>{globalTranslate(currentLang, 'Контакты')}</h3>

        {contacts.length > 0 && (
          <div className={styles.contactsWrapper}>
            {/* RENDER EMAILS */}

            {emails && (
              <div className={styles.emailsWrapper}>
                {emails.map((item, index) => (
                  <div key={index} className={styles.emailItem}>
                    <p>{currentLang === 'ru' ? item.title : item.title_en}</p>
                    <div>
                      <EmailIcon />
                      <a href={"mailto:" + item.value}>{currentLang === 'ru' ? item.value : item.value_en}</a>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* RENDER PHONES */}

            {phones && (
              <div className={styles.phonesWrapper}>
                {phones.map((item, index) => (
                  <div key={index} className={styles.phonesItem}>
                    <p>{currentLang === 'ru' ? item.title : item.title_en}</p>
                    <div>
                      <PhoneIcon />
                      <a href={"tel:+" + item.value}>{currentLang === 'ru' ? item.value : item.value_en}</a>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* RENDER PLACES */}

            {places && (
              <div className={styles.placesWrapper}>
                {places.map((item, index) => (
                  <div key={index} className={styles.placesItem}>
                    <p>{currentLang === 'ru' ? item.title : item.title_en}</p>
                    <div>
                      <LocationIcon />
                      <span>{currentLang === 'ru' ? item.value : item.value_en}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* RENDER MAPS */}

            {maps && (
              <div className={styles.mapsWrapper}>
                {maps.map((item, index) => (
                  <div
                    key={index}
                    className={styles.mapsItem}
                    dangerouslySetInnerHTML={createMarkup(item.value)}
                  ></div>
                ))}
              </div>
            )}

            {/* RENDER SOCIALS

            {socials && (
              <div className={styles.socialsWrapper}>
                {socials.map((item, index) => (
                  <div key={index} className={styles.socialsItem}>
                    <a href={item.value} target="_blank">
                      {item.title}
                    </a>
                  </div>
                ))}
              </div>
            )} */}

            {/* {contacts.map((item, index) => (
              <div className={styles.contactItem} key={index}>
                <span className={styles.title}>{item.title}</span>
                
                {item.type === 'phone' && <a href={"tel:+" + item.value}>{item.value}</a>}
                {item.type === 'email' && <a href={"mailto:" + item.value}>{item.value}</a>}
                {item.type === 'place' && <p>{item.value}</p>}
                {item.type === 'map' && <div dangerouslySetInnerHTML={createMarkup(item.value)}></div>}

              </div>
            ))} */}
          </div>
        )}
      </Container>
    </PageTemplate>
  );
};

export default ContactsPage;
