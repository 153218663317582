import React from "react";
import styles from "./Messages.module.scss";
import { useSelector } from 'react-redux';

const Message = ({item}) => {
  const user = useSelector(state => state.user)

  return (
    <div className={styles.message} style={user.email === item.sender ? {alignSelf: 'end', borderBottomRightRadius: 0} : {alignSelf: 'start', borderBottomLeftRadius: 0}}>
      {" "}
      <span
          style={
            user.email === item.sender
              ? { textAlign: "right", display: 'inline-block'}
              : {}
          }
        >
          {item.fio}
        </span>
        <p
          style={
            user.email === item.sender
            ? { textAlign: "right" }
            : {}
          }
        >
          {item.message}
        </p>
    </div>
  );
};

export default Message;
