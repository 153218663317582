import React, { useEffect } from 'react'
import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate'
import LoginQuestion from './LoginForm';

const LoginPage = () => {
  return (
    <PageTemplate children={
      <Container children={
        <LoginQuestion />
      } />
    } />
  )
}

export default LoginPage
