import React from "react";
import { Link } from "react-router-dom";

// import { ReactComponent as UserLogoIcon } from "../../../image/icons/user_avatar.svg";
import { ReactComponent as UserLogoIcon } from "../../../image/icons/account.svg";

import styles from './UserMiniature.module.scss';

const UserMiniature = ({ name, url }) => {
  return (
    <li className={styles.wrapper}>
      <Link to={url}>
        <UserLogoIcon />
        <p>{name}</p>
      </Link>
    </li>
  );
};

export default UserMiniature;
