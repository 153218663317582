import { BASE_URL_API } from '../BASE_URL.js';
import { getCountriesAction } from '../redux/Reducers/locationsReducers.js';

const getCountries = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'geo/countries/');
        const data = await response.json();

        dispatch(getCountriesAction(data));
    }
}

export default getCountries;