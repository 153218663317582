import React, { useRef, useEffect, useState } from "react";

import InputMask from "react-input-mask";

import styles from "./CalendarInput.module.scss";

import "moment/locale/ru";
import Box from '../Box/Box';
import { useSelector } from 'react-redux';
import { globalTranslate } from "../../../functions/languages";

const CalendarInput = ({ onChange,
  labelText,
  isValue,
  isDisabled,
  onBlur,
  error }) => {
  const [isInputActive, setIsInputActive] = useState(false);
  const [value, setValue] = useState(isValue ? isValue : "");

  const currentLang = useSelector(state => state.lang.currentLang)

  const onChangeHandler = (event) => {
    if (isDisabled) return;
    let valueLocal = event.target.value
      setValue(valueLocal)
  }

  const inputRef = useRef()

  useEffect(() => {
    if (isInputActive && value === '+') {
      inputRef.current.selectionStart = 2
    }
  }, [isInputActive, value])

  const inputOnFocusHandler = () => {
    if (value === '__.__.____') {
      setIsInputActive(false)
    }
    onBlur && onBlur();
  };

  useEffect(() => {
    if (!isValue) return
    if (value.includes('_') === false) {
      setValue(isValue.replaceAll('-', '.').split('.').reverse().join('.'))
      return
    }
    setValue(isValue)
  }, [isValue])

  useEffect(() => {
    if (isDisabled) {
      !value && setIsInputActive(false);
      return;
    }
    if (value.includes('_') === false) {
      onChange(value.split('.').reverse().join('.').replaceAll('.', '-'))
      return
    }
    onChange(value);
  }, [value]);

  useEffect(() => {
    if (value !== '') {
      setIsInputActive(true)
    }
  }, [value])

  const label = useRef()

  useEffect(() => {
    if (!label) return;
    if (label.current.childNodes.length < 3) return
    label.current.childNodes[label.current.childNodes.length - 1].remove()
  }, [label])

  return (
    <>
      <Box maxW={700}>
      <label
        ref={label}
        className={styles.labelWrapper}
        onClick={() => setIsInputActive(true)}
        onFocus={() => setIsInputActive(true)}
        style={labelText.includes('Дата') ? {cursor: 'pointer'} : {}}
      >
        <span className={isInputActive ? styles.activeLabel : styles.label} onClick={() => setIsInputActive(true)}>
          {globalTranslate(currentLang, labelText)}
        </span>
          <InputMask mask='99.99.9999' disabled={isDisabled} value={value} style={error ? {backgroundColor: '#FFEDED'} : labelText.includes('Дата') ? {cursor: 'pointer'} : {}} onBlur={inputOnFocusHandler} onChange={onChangeHandler} />;
      </label>
    </Box>

    </>
  );
};

export default CalendarInput;
