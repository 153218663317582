import { BASE_URL_API } from '../BASE_URL.js';
import { isAuth } from './isAuth';
import { getCompanyRegistrations } from './getCompanyRegistrations';
import { setErrorAction } from '../redux/Reducers/errorsReducer.js';

const login = (userData, navigate) => {
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    const csrftoken = getCookie('csrftoken');
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'auth/token/login/', {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'X-CSRFToken': csrftoken,
},
            body: JSON.stringify(userData),
            method: 'POST',
        });

        if (response.status === 200) {
            dispatch(setErrorAction())
            const data = await response.json();
            const token = data.auth_token;
            window.localStorage.setItem('token', token);
            await isAuth(dispatch)
            await getCompanyRegistrations(dispatch);
            navigate('/cabinet/my_registrations');
        }
        if (response.status === 400) {
            const data = await response.json();
            if (data.password) {
                dispatch(setErrorAction('Похоже, вы забыли ввести пароль'))
            } else if (data.non_field_errors) {
                dispatch(setErrorAction('Неверный логин или пароль'))
            } else if (data.email) {
                dispatch(setErrorAction('Похоже, вы забыли ввести электронную почту'))
            }
        }
    }
}


export default login;