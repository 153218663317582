import { BASE_URL_API } from '../BASE_URL.js';
import { getAlbumBySlugAction } from '../redux/Reducers/photosReducer.js';
import { getAlbumsAction } from '../redux/Reducers/photosReducers.js';

export const getAlbums = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'gallery/photos/albums/');
        const data = await response.json();

        dispatch(getAlbumsAction(data));
    }
}