import { BASE_URL_API } from '../BASE_URL.js';
import { getPagesAction } from '../redux/Reducers/pagesReducer.js';

const getPages = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'pages/');
        const data = await response.json();

        dispatch(getPagesAction(data.results));
    }
}

export default getPages;