import { BASE_URL_API } from "../BASE_URL.js";

const resetPasswordConfirm = async (userData, setError, setIsLoad, setSuccess) => {
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");

    const response = await fetch(BASE_URL_API + "auth/users/reset_password_confirm/", {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
        // Authorization: "Token " + window.localStorage.token,
      },
      body: JSON.stringify(userData),
      method: "POST",
    });

    if (response.status === 400) {
      const data = await response.json()

      const errorList = []
      data.new_password.forEach(element => {
        errorList.push(element)
      });
      setError(errorList)
    }

    if (response.status === 204) {
      setSuccess(true)
    }

    setIsLoad(false)
};

export default resetPasswordConfirm;
