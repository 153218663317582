import { BASE_URL_API } from '../BASE_URL.js';
import { setAuthAction } from '../redux/Reducers/AuthReducer';
import { setCounterAction, setCurrentRegistrationsAction } from '../redux/Reducers/companyRegCounter';
import { setCurrentUserAction } from '../redux/Reducers/currentUserReducer';
import { setErrorAction } from '../redux/Reducers/errorsReducer';

export const logout = async (dispatch, navigate) => {
    const token = window.localStorage.token;
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    const csrftoken = getCookie('csrftoken');
    const response = await fetch(BASE_URL_API + 'auth/token/logout/', {
        headers: {
            Authorization: 'Token ' + token,
            'Content-Type': 'application/json;charset=utf-8',
            'X-CSRFToken': csrftoken,

        },
        method: 'POST',
    })

    if (response.status === 204) {
        window.localStorage.removeItem('token');
        dispatch(setAuthAction(false));
        dispatch(setCurrentUserAction({id: '', email: '', is_manager: false}));
        dispatch(setCounterAction(0));
        dispatch(setCurrentRegistrationsAction({}));
        dispatch(setErrorAction(''));
        navigate('/main');
    }
} 