import { BASE_URL_API } from '../BASE_URL.js';

import { getAlbumAction, getAlbumBySlugAction, getFirstAlbumsAction } from '../redux/Reducers/photosReducer.js';

export const getFirstAlbums = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'gallery/photos/albums/?limit=8&offset=0');
        const data = await response.json();

        dispatch(getFirstAlbumsAction(data));
    }
}

export const getAlbumsBySlug = (slug, isError) => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'gallery/photos/albums/' + slug);
        const data = await response.json();

        if (response.status === 200) {
            dispatch(getAlbumBySlugAction(data));
        }
        if (response.status === 404) {
            isError(404)
        }
    }
}
