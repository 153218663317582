const initialState = {
    links: [],
  };
  
  const GET_SOCIALS = 'GET_SOCIALS';
  
  export const socialsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SOCIALS:
        return {
          links: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getSocialsAction = (payload) => ({ type: GET_SOCIALS, payload});