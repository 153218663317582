const initialState = {
    type: [],
  };
  
  const GET_MOVEMENT_TYPE = 'GET_MOVEMENT_TYPE';
  
  export const movementTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_MOVEMENT_TYPE:
        return {
          type: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getMovementTypeAction = (payload) => ({ type: GET_MOVEMENT_TYPE, payload});