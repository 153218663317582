const initialState = {
    error_text: '',
    
  };
  
  const SET_ERROR = 'SET_ERROR';
  
  export const errorsReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_ERROR:
        return {
            error_text: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const setErrorAction = (payload) => ({ type: SET_ERROR, payload});