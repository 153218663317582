import React from "react";
import styles from "./Messages.module.scss";
import {
  aproveMessage,
  deniedMessage,
} from "../../../../functions/chatSockets";
import { useSelector } from "react-redux";
import { globalTranslate } from "../../../../functions/languages";

const MessageToModerate = ({ item }) => {
  const user = useSelector((state) => state.user);
  const currentLang = useSelector((state) => state.lang.currentLang);

  return (
    <div
      className={styles.messageToModerate}
      style={
        user.email === item.sender
          ? { alignSelf: "end", borderBottomRightRadius: 0 }
          : { alignSelf: "start", borderBottomLeftRadius: 0 }
      }
    >
      {" "}
      <div className={styles.text_group}>
        <span
          style={
            user.email === item.sender
              ? { textAlign: "right", display: 'inline-block'}
              : {}
          }
        >
          {item.fio}
        </span>
        <p
          style={
            user.email === item.sender
            ? { textAlign: "right" }
            : {}
          }
        >
          {item.message}
        </p>
      </div>
      <div className={styles.btn_group} style={
            user.email === item.sender
            ? { justifyContent: 'end' }
            : {}
          }>
        <button onClick={() => aproveMessage(item.id)}>
          {globalTranslate(currentLang, "принять")}
        </button>
        <button onClick={() => deniedMessage(item.id)}>
          {globalTranslate(currentLang, "удалить")}
        </button>
      </div>
    </div>
  );
};

export default MessageToModerate;
