const initialState = {
    inviters: [],
  };
  
  const GET_INVITERS = 'GET_INVITERS';
  
  export const inviteReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_INVITERS:
        return {
          inviters: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getInvitersAction = (payload) => ({ type: GET_INVITERS, payload});