import React from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import styles from "./WelcomeSlider.module.scss";
import { Link } from "react-router-dom";
import ReadMoreLink from "../ReadMoreLink/ReadMoreLink";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { globalTranslate } from "../../../functions/languages";

const WelcomeSlider = () => {
  const welcomeWordsRedux = useSelector(
    (state) => state.welcomeWords.welcomeWords
  );

  const currentLang = useSelector((state) => state.lang.currentLang)

  const createMarkup = (text) => {
    return { __html: text };
  };

  let settings = {
    dots: true,
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 500,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // style: {{
    //     marginRight: 10,
    // }}
  };

  return (
    <div className={styles.wrapper}>
      <Slider {...settings}>
        {welcomeWordsRedux.length > 0 &&
          welcomeWordsRedux.map((item, index) => (
            <div key={index}>
              <div className={styles.sliderItem}>
                {window.innerWidth > 768 && (
                  <img src={item.photo} alt={item.fio} />
                )}
                <div className={styles.text}>
                  <h2>{currentLang === 'ru' ? item.title : item.title_en}</h2>
                  {window.innerWidth < 768 && (
                    <img src={item.photo} alt={item.fio} />
                  )}

                  {/* <i> */}
                    <div
                      dangerouslySetInnerHTML={createMarkup(currentLang === 'ru' ? item.min_text : item.min_text_en)}
                    ></div>
                  {/* </i> */}
                  <ReadMoreLink
                    link={"/welcome/" + item.id}
                    text={globalTranslate(currentLang, "Читать полностью")}
                  />
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default WelcomeSlider;
