import { BASE_URL_API } from '../BASE_URL.js';
import { getMovementTypeAction } from '../redux/Reducers/movementTypeReducer.js';

const getMovementType = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'registrations/movement-types/', {
            headers: {
                Authorization: "Token " + window.localStorage.token,
            }
        });
        const data = await response.json();
        dispatch(getMovementTypeAction(data));
    }
}

export default getMovementType;