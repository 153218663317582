import React, { useState } from "react";
import Container from "../../elements/Container/Container";
import PageTemplate from "../../PageTemplate/PageTemplate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getNews } from "../../../functions/getNews";
import { useSelector } from "react-redux";
import NewsItem from "./NewsItem";
import { translit } from "../../../functions/translit";
import Pagination from "../../elements/Pagination/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import ContentContainer from "../../elements/Container/ContentContainer";
import { globalTranslate } from "../../../functions/languages";

import styles from "./NewsPage.module.scss";

const NewsPage = () => {
  const { page } = useParams();
  const [newsList, setNewsList] = useState();
  const [activePage, setActivePage] = useState(page);
  const [search_string, setSearchString] = useState("");
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  const previousLink = useSelector((state) => state.news.prev);
  const nextLink = useSelector((state) => state.news.next);
  const news = useSelector((state) => state.news.newsList);
  const currentLang = useSelector((state) => state.lang.currentLang);

  const dispatch = useDispatch();

  const titleRef = useRef();

  const searchClickHandler = () => {
    if (search_string === "") return;
    navigate("/news/search/" + 1, { state: { search: search_string } });
  };

  useEffect(() => {
    if (!page) return;
    setActivePage(page);
    dispatch(getNews(page, currentLang === "en"));
  }, [page, currentLang]);

  const clickPage = (number) => {
    setIsLoad(true);
    navigate("/news/" + number);
  };
  const clickNext = () => {
    setIsLoad(true);
    navigate("/news/" + (Number(activePage) + 1));
  };
  const clickPrev = () => {
    setIsLoad(true);
    navigate("/news/" + (Number(activePage) - 1));
  };

  // useEffect(() => {
  //   if (!news || news.length < 1)
  //   if (currentLang === 'ru') {
  //     const rusNews = news
  //   }
  // }, [])

  useEffect(() => {
    setIsLoad(false);
    if (news.length < 1) return;
    setNewsList(news);
  }, [news, currentLang]);

  return (
    <PageTemplate>
      <Container>
        {isLoad ? (
          <h3>{globalTranslate(currentLang, "Загрузка")}...</h3>
        ) : (
          <>
            <div className={styles.head}>
              <h3 ref={titleRef}>{globalTranslate(currentLang, "Новости")}</h3>
              <div className={styles.SearchWrap}>
                <input
                  type="text"
                  placeholder={globalTranslate(currentLang, "Введите запрос")}
                  value={search_string}
                  onChange={(e) => setSearchString(e.target.value)}
                />
                <button onClick={searchClickHandler}>
                  <span>{globalTranslate(currentLang, "Поиск")}</span>
                </button>
              </div>
            </div>
            <ContentContainer>
              {newsList &&
                newsList.map((item, index) => (
                  <NewsItem
                    key={index}
                    title={currentLang === "ru" ? item.title : item.title_en}
                    description={
                      currentLang === "ru"
                        ? item.min_description
                        : item.min_description_en
                    }
                    date={item.publish_time}
                    slug={item.slug}
                  />
                ))}
            </ContentContainer>
            <Pagination
              clickPage={clickPage}
              activePage={activePage}
              clickNext={clickNext}
              clickPrev={clickPrev}
            />
          </>
        )}
      </Container>
    </PageTemplate>
  );
};

export default NewsPage;
