import React from 'react';
import styles from './Messages.module.scss';

import Clock from '../../../../image/icons/clock-chat.svg';
import { useSelector } from 'react-redux';
import { globalTranslate } from '../../../../functions/languages';

const SuccessfulSending = () => {
  const currentLang = useSelector((state) => state.lang.currentLang);

  return (
    <div className={styles.success}>
        <img src={Clock} alt="" />
      <span>{globalTranslate(currentLang, "Ваше сообщение успешно отправлено на модерацию")}</span>
    </div>
  )
}

export default SuccessfulSending
