const initialState = {
    documents: [],
  };
  
  const GET_DOCUMENTS = 'GET_DOCUMENTS';
  
  export const documentsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_DOCUMENTS:
        return {
          documents: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getDocumentsAction = (payload) => ({ type: GET_DOCUMENTS, payload});