const initialState = {
    pages: [],
  };
  
  const GET_MENU = 'GET_MENU';
  
  export const menuReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_MENU:
        return {
          pages: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getMenuAction = (payload) => ({ type: GET_MENU, payload});