import React, { useEffect, useState } from "react";
import ArrivalInfo from "../../pages/RegPage/UserDataForms/ArrivalInfo";
import ContactInfoData from "../../pages/RegPage/UserDataForms/ContactInfoData";
import PassData from "../../pages/RegPage/UserDataForms/PassData";
import PersonalData from "../../pages/RegPage/UserDataForms/PersonalData";
import PageTemplate from "../../PageTemplate/PageTemplate";
import CabinetNav from "../CabinetNav/CabinetNav";
import Container from "../Container/Container";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import updateUser from "../../../functions/updateUser";
import { getCompanyRegistrations } from "../../../functions/getCompanyRegistrations";
import CompanyInfo from "../../pages/RegPage/UserDataForms/CompanyInfo";

import styles from './CabinetProfile.module.scss';
import { globalTranslate } from "../../../functions/languages";

const CabinetProfileForm = () => {
  const [errorList, setErrorList] = useState();
  const [registrationData, setRegistrationData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [changedData, setChangedData] = useState();
  const [load, setLoad] = useState(false);
  const { id } = useParams();
  const data = useSelector((state) => state.registrations.currentRegistration);
  const is_manager = useSelector((state) => state.user.is_manager);
  const currentLang = useSelector(state => state.lang.currentLang)
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const clearError = (error) => {
    if (!errorList) return;
    setErrorList(errorList.filter((item) => item === error));
  };

  useEffect(() => {
    getCompanyRegistrations(dispatch);
  }, [dispatch])

  const currentTypeOfRegistration = useSelector(
    (state) => state.registrations.currentRegistration
  );


  useEffect(() => {
    if (!data || Object.keys(data).length < 1) return;
    setRegistrationData(data.filter((item) => item.id == id)[0]);
  }, [data]);

  const safeChanges = () => {
    setLoad(true);
    if (registrationData.is_reg_confirmed) {
      setShowModal(true);
      return;
    }
    updateUser(changedData, registrationData.id, navigate, dispatch, setLoad, setErrorList);
  };

  const cancelSafeChanges = () => {
    setLoad(false);
    setShowModal(false);
  };

  const safeChangesFromModal = () => {
    setShowModal(false);
    updateUser(changedData, registrationData.id, navigate, dispatch, setLoad);
  };

  const changeData = (data) => {
    let obj = Object.assign({}, changedData, data);
    setChangedData(obj);
  };

  return (
    <div>
      <PageTemplate>
        <Container>
          {showModal && (
            <ModalConfirm
              text={
                "Ваш профиль подтвержден администартором. Изменение данных отменит подтверждение участия. Оно будет запрошено снова. Изменить данные?"
              }
              actionCancel={cancelSafeChanges}
              actionSuccess={safeChangesFromModal}
            />
          )}
          <CabinetNav />
          {registrationData && registrationData.is_reg_confirmed ? (
            <p  className={styles.notes}>{globalTranslate(currentLang, 'Ваш профиль подтвержден администратором. Вы не можете менять учетные данные. Если вам нужно изменить информацию, сообщите об этом по адресу')} <a href="mailto:reg@kef2023.ru">reg@kef2023.ru</a></p>
          ) : (
            <p className={styles.notes}>{globalTranslate(currentLang, 'Для смены платного и бесплатного типа участия удалите пользователя в личном кабинете с помощью кнопки "Удалить анкету"')}</p>
          )}
          
          <PersonalData
            isDisabled={registrationData && registrationData.is_reg_confirmed}
            isOnline={currentTypeOfRegistration[0].registration_type === 'onln'}
            isSMI={
              registrationData && registrationData.registration_type === "smi"
            }
            invite={registrationData && registrationData.registration_type === 'free'}
            clearError={clearError}
            isValue={registrationData}
            change={changeData}
          />
          <PassData
            isDisabled={registrationData && registrationData.is_reg_confirmed}
            isOnline={currentTypeOfRegistration[0].registration_type === 'onln'}
            clearError={clearError}
            isValue={registrationData}
            change={changeData}
          />
          <ContactInfoData
            isDisabled={registrationData && registrationData.is_reg_confirmed}
            clearError={clearError}
            isValue={registrationData}
            change={changeData}
          />
          {currentTypeOfRegistration[0].registration_type !== 'onln' && (
            <CompanyInfo
            isDisabled={registrationData && registrationData.is_reg_confirmed}
            isSMI={
              registrationData && registrationData.registration_type === "smi"
            }
            clearError={clearError}
            isValue={registrationData}
            change={changeData}
            isPaidDefault={
              registrationData && registrationData.registration_type === "paid"
            }
          />
          ) }

          {errorList && errorList.length > 0 && (
            errorList.map((item, index) => <p className={styles.error} key={index}>* {item}</p>)
          )}
          

          {registrationData && registrationData.is_reg_confirmed ? (
            <p  className={styles.notes}>{globalTranslate(currentLang, "Ваш профиль подтвержден администратором. Вы не можете менять учетные данные. Если вам нужно изменить информацию, сообщите об этом по адресу")} <a href="mailto:reg@kef2023.ru">reg@kef2023.ru</a></p>
          ) : (
            <div style={{ margin: "40px 0 40px 0" }}>
            <Button onClick={safeChanges}>
              {load ? globalTranslate(currentLang, "Сохраняем изменения...") : globalTranslate(currentLang, "Сохранить изменения")}
            </Button>
          </div>
          )}
        </Container>
      </PageTemplate>
    </div>
  );
};

export default CabinetProfileForm;
