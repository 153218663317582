import React, { useEffect, useState } from "react";

import styles from "./RegPage.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../elements/Button/Button";
import TextInput from "../../elements/TextInput/TextInput";
import Box from "../../elements/Box/Box";
import registration from "../../../functions/registration";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setErrorAction } from "../../../redux/Reducers/errorsReducer";
import { globalTranslate } from "../../../functions/languages";

import getCategoryOfParticipants from '../../../functions/getCategoryOfParticipants';


const RegQuestion = () => {
  const { pathname } = useLocation()

  const dispatch = useDispatch();

  const error = useSelector((state) => state.error.error_text);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [applyPassword, setApply] = useState("");
  const [isOnePersonRegistration, setIsOnePersonRegistration] = useState(pathname === '/group-registration' ? false : true );
  const [isGroupRegistration, setIsGroupRegistration] = useState(pathname === '/registration' ? false : true);
  const [isCorrectPasswordError, setIsCorrectPasswordError] = useState(true);
  const [isOpenRegistration, setOpenRegistration] = useState(true);
  const [participants, setParticipants] = useState();
  const [currentParticipationOfRegistration, setCurrentParticipationOfRegistration] = useState();
  const [isPersonalRegistrationApply, setIsPersonalRegistrationApply] =
    useState(false);
  const [isGroupRegistrationApply, setIsGroupRegistrationApply] =
    useState(false);

  const emailChangeHandler = (value) => setEmail(value);
  const passwordChangeHandler = (value) => setPassword(value);
  const applyPasswordChangeHandler = (value) => setApply(value);

  const navigate = useNavigate();

  const categoryOfParticipant = useSelector(state => state.categoryList.category)

  const typeOfRegistration = useSelector(
    (state) => state.registrationType.types
  );

  const currentLang = useSelector(state => state.lang.currentLang)

  useEffect(() => {
    if (!typeOfRegistration || typeOfRegistration.length < 1) return;
    if (
      !typeOfRegistration.reg_allow_person &&
      !typeOfRegistration.reg_allow_group
    ) {
      setOpenRegistration(false);
    } else {
      if (typeOfRegistration.reg_allow_person)
        setIsPersonalRegistrationApply(true);
      if (typeOfRegistration.reg_allow_group) setIsGroupRegistrationApply(true);
    }
    let flag = false;

    for (let key in typeOfRegistration) {
      if (typeOfRegistration[key]) flag = true;
    }
  }, [typeOfRegistration]);

  const checkPasswords = () => {
    if (password === applyPassword) {
      setIsCorrectPasswordError(true);
      return;
    }
    setIsCorrectPasswordError(false);
  };

  useEffect(() => {
    dispatch(getCategoryOfParticipants())
  }, [dispatch])

  const validatePassword = (password) => {
    if (password.length < 6) {
      dispatch(setErrorAction(globalTranslate(currentLang, "Пароль должен содержать не менее 6 символов")));
      return false;
    }
    if (
      password === password.toUpperCase() ||
      password === password.toLowerCase()
    ) {
      dispatch(
        setErrorAction(
          globalTranslate(currentLang, "Пароль должен содержать по одному символу в верхнем и нижнем регистре")
        )
      );
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!categoryOfParticipant) return;
    const categories = [];
    categoryOfParticipant.forEach(element => {
      if (element.is_visible_group) {
        categories.push(element)
      }
    });
    setParticipants(categories)
  }, [categoryOfParticipant])

  const getErrors = (error) => {};

  const onePersonClickHandler = () => {
    setIsOnePersonRegistration(true);
    setIsGroupRegistration(false);
  };
  const groupClickHandler = () => {
    setIsOnePersonRegistration(false);
    setIsGroupRegistration(true);
  };

  const makeRegistration = () => {
    dispatch(setErrorAction(""));

    if (
      email &&
      password &&
      validatePassword(password) &&
      (isGroupRegistration || isOnePersonRegistration) &&
      password === applyPassword
    ) {
      dispatch(
        registration(
          { email, password, is_manager: isGroupRegistration},
          navigate
        )
      );
      dispatch(setErrorAction(""));
    } else {
      if (!isGroupRegistration && !isOnePersonRegistration) {
        dispatch(
          setErrorAction(
            globalTranslate(currentLang, "Вы не выбрали тип аккаунта: персональный или групповой.")
          )
        );
      }
      if (password !== applyPassword) {
        dispatch(setErrorAction(globalTranslate(currentLang, "Пароли не совпадают.")));
      }
      if (!validatePassword(password)) {
        validatePassword(password);
      }
    }
  };

  return (
    <div className={styles.regModal}>
      <h3>{pathname === '/group-registration' ? globalTranslate(currentLang, 'Групповая регистрация') : globalTranslate(currentLang, 'Регистрация')}</h3>
      {isOpenRegistration ? (
        <>
          {error && <h5>{error}</h5>}
          <Box width={"100%"}>
            <TextInput
              labelText={"Электронная почта"}
              onChange={emailChangeHandler}
            />
          </Box>
          <Box width={"100%"}>
            <TextInput
              labelText={"Придумайте пароль"}
              type={"isPass"}
              onChange={passwordChangeHandler}
            />
          </Box>
          <Box width={"100%"}>
            <TextInput
              labelText={"Подтвердите пароль"}
              type={"isPass"}
              onChange={applyPasswordChangeHandler}
              withErrorNote={!isCorrectPasswordError}
            />
          </Box>
          {/* {pathname === '/group-registration' && (
            <>
              <h4>{globalTranslate(currentLang, 'Выберите тип регистрации')}</h4>
              <div className={styles.linkGroup}>
                <div className={styles.checkbox}>
                  <div
                    className={
                      !isOnePersonRegistration
                        ? styles.fakeBox
                        : styles.activeFakeBox
                    }
                    onClick={onePersonClickHandler}
                  ></div>
                  <p
                    className={styles.fakeLabel}
                    onClick={onePersonClickHandler}
                  >
                    {globalTranslate(currentLang, 'Персональный')}
                  </p>
                </div>
                <div className={styles.checkbox}>
                  <div
                    className={
                      !isGroupRegistration
                        ? styles.fakeBox
                        : styles.activeFakeBox
                    }
                    onClick={groupClickHandler}
                  ></div>
                  <p className={styles.fakeLabel} onClick={groupClickHandler}>
                    {globalTranslate(currentLang, 'Групповой')}
                  </p>
                </div>
              </div>
            </>
          )} */}
          {/* {pathname === '/group-registration' && 
            <div>
              {participants && participants.map((item, index) => 
              <div key={index}> 
                <input type="radio" name="category" id={item.id} onChange={(e) => setCurrentParticipationOfRegistration(e.target.id)}  />
                <label htmlFor={item.id}>{item.name}</label>
              </div>)}
            </div>
          } */}
          <div style={{ marginTop: "30px" }}>
                <Button onClick={makeRegistration}>{globalTranslate(currentLang, 'Регистрация')}</Button>
                <p className={styles.redirectQuestion}>
                {globalTranslate(currentLang, 'Уже зарегистрированы?')} <Link to="/login">{globalTranslate(currentLang, 'Войти')}</Link>{" "}
                </p>
              </div>
        </>
      ) : (
        <>
          <p>
          {globalTranslate(currentLang, 'В данный момент регистрация закрыта. Вы не сможете создать личный кабинет.')}
          </p>
          <p className={styles.redirectQuestion}>
          {globalTranslate(currentLang, 'Уже зарегистрированы?')} <Link to="/login">{globalTranslate(currentLang, 'Войти')}</Link>{" "}
          </p>
        </>
      )}
    </div>
  );
};

export default RegQuestion;
