import React from 'react';
import PageTemplate from '../../PageTemplate/PageTemplate';
import Container from '../../elements/Container/Container';

import styles from './Partners.module.scss';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getPartners from '../../../functions/getPartners';
import { BASE_URL } from '../../../BASE_URL';
import { useRef } from 'react';
import { globalTranslate } from '../../../functions/languages';

import { ReactComponent as CloseIcon } from '../../../image/icons/close-black.svg';
import iconLink from '../../../image/icons/link-icon.png';
import ContentContainer from '../../elements/Container/ContentContainer';
const Partners = () => {
  const [partners, setPartners] = useState()
  const [partnerImgHeight, setPartnerImgHeight] = useState(170)
  const [currentPartner, setCurrentPartner] = useState()

  const dispatch = useDispatch()

  const partnersRedux = useSelector(state => state.partners.partners)
  const currentLang = useSelector(state => state.lang.currentLang)



  const resize = () => {
    setTimeout(() => {
      const list = document.getElementById('partnersListId');

      if (list === null) return;
      if (window.innerWidth > 768) setPartnerImgHeight(list.clientWidth / 4 - 40)
      if (window.innerWidth <= 768) setPartnerImgHeight(list.clientWidth / 2 - 15)
    }, 500)
  }

  useEffect(() => {
    resize()
  }, [partnerImgHeight])

  useEffect(() => {
    resize()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  // useEffect(() => {
  //   resize()
  // }, [])

  

  useEffect(() => {
    if (!partnersRedux) return;
    setPartners(partnersRedux.results)
  }, [partnersRedux])

  useEffect(() => {
    dispatch(getPartners())
  }, [dispatch])


  const createMarkup = (text) => {
    return { __html: text };
  };
  return (
    <PageTemplate>
        <Container>
            <h3>{globalTranslate(currentLang, 'Партнеры')}</h3>
            {/* <h3>В настоящий момент страница находится в разработке</h3> */}
            
            <div className={styles.partnersWrapper}></div>
            {partners && partners.map((category, index) => (
                <div key={index} className={styles.category}>
                    <h4>{currentLang === 'ru' ? category.title : category.title_en}</h4>
                    <div className={styles.partnersList} id='partnersListId'>
                        {category.partners.map((partner, index) => (
                            <div key={index} className={styles.partner} onClick={() => setCurrentPartner(partner)}>
                                <div style={currentLang === 'ru' ? {height: partnerImgHeight, backgroundImage: 'url("' + BASE_URL + partner.photo + '")'} : {height: partnerImgHeight, backgroundImage: 'url("' + BASE_URL + partner.photo_en + '")'}}></div>
                                <span>{currentLang === 'ru' ? partner.title : partner.title_en}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            <div className={currentPartner ? styles.modalPartnerActive : styles.modalPartner}>
                {currentPartner && (
                    <Container>
                        <ContentContainer>
                        <div className={styles.modalContent}>
                            <div className={styles.photo}>
                                <img src={currentLang === 'ru' ? BASE_URL + currentPartner.photo : BASE_URL + currentPartner.photo_en} alt="" />
                                <CloseIcon onClick={() => setCurrentPartner()} />
                            </div>
                            <div className={styles.info}>
                                <div className={styles.text}>
                                    <p>{currentLang === 'ru' ? currentPartner.title : currentPartner.title_en}</p>
                                    <a href={currentPartner.link} target="_blank" rel="noopener noreferrer"><img src={iconLink} alt="" /> {currentPartner.link}</a>
                                </div>
                                <div className={styles.description} dangerouslySetInnerHTML={createMarkup(currentLang === 'ru' ? currentPartner.description : currentPartner.description_en)}></div>
                            </div>
                        </div>
                    </ContentContainer>
                    </Container>
                )}
            </div>
        </Container>
    </PageTemplate>
  )
}

export default Partners