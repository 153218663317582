const initialState = {
    mediaPartners: false,
  };
  
  const SET_MEDIA_PARTNERS = 'SET_MEDIA_PARTNERS';
  
  export const mediaPartnersReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_MEDIA_PARTNERS:
        return {...state,
          mediaPartners: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const setMediaPartnersAction = (payload) => ({ type: SET_MEDIA_PARTNERS, payload});