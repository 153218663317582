const initialState = {
    index: false,
  };
  
  const GET_INDEX_CONSTANCE = 'GET_INDEX_CONSTANCE';
  
  export const indexConstanceReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_INDEX_CONSTANCE:
        return {...state,
          index: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getIndexConstanceAction = (payload) => ({ type: GET_INDEX_CONSTANCE, payload});
  