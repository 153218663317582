import { BASE_URL_API } from '../BASE_URL.js';
import { setMediaPartnersAction } from '../redux/Reducers/mediaPartnersReducer.js';

const getMediaPartners = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'partners/media/');
        const data = await response.json();

        dispatch(setMediaPartnersAction(data));
    }
}

export default getMediaPartners;