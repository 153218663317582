import React from 'react';

import { ReactComponent as ArrowBack } from '../../../image/icons/arrow-right.svg';

import styles from './ReadMoreLink.module.scss';
import { Link } from 'react-router-dom';

const ReadMoreLink = ({ link, text }) => {
  return (
    <div className={styles.linkStyles}>
        <Link to={ link }>{ text }</Link>
        <ArrowBack />
    </div>
  )
}

export default ReadMoreLink;
