const initialState = {
    forms: [],
  };
  
  const GET_FORMS = 'GET_FORMS';
  
  export const organisationFormsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_FORMS:
        return {
          forms: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getOrganizationFormsAction = (payload) => ({ type: GET_FORMS, payload});