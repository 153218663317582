import React from 'react';
import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import styles from './RegDocs.module.scss';
import { globalTranslate } from '../../../functions/languages';

const Policy = () => {
  const policyText = useSelector(state => state.regDocs.privacy_policy)
  const policyTextEn = useSelector(state => state.regDocs.privacy_policy_en)
  const currentLang = useSelector((state) => state.lang.currentLang);

  const createMarkup = (text) => {
    return { __html: text };
  };
  return (
    <PageTemplate>
        <Container>
            <h3>{globalTranslate(currentLang, "Политика конфиденциальности")}</h3>

            <div className={styles.textWrapper} dangerouslySetInnerHTML={createMarkup(currentLang === 'ru' ? policyText : policyTextEn)}
            ></div>
        </Container>
    </PageTemplate>
  );
};

export default Policy;