const initialState = {
    firstAlbums: false,
    currentAlbums: false,
    photos: false,
    gallery: false,
  };
  
  const GET_FIRST_ALBUMS = 'GET_FIRST_ALBUMS';
  const GET_ALBUMS_BY_SLUG = 'GET_ALBUMS_BY_SLUG';
  const GET_ALL_GALLERIES = 'GET_ALL_GALLERIES';
  
  export const photosReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_FIRST_ALBUMS:
        return {...state,
          firstAlbums: action.payload.results,
        };
      case GET_ALL_GALLERIES: 
        return {...state, 
          gallery: action.payload,
        }
      case GET_ALBUMS_BY_SLUG:
        return {...state,
          currentAlbums: action.payload,
        }; 
      default:
        return state;
    }
  };
  
  export const getFirstAlbumsAction = (payload) => ({ type: GET_FIRST_ALBUMS, payload});
  export const getAlbumBySlugAction = (payload) => ({ type: GET_ALBUMS_BY_SLUG, payload});
  export const getAllGalleriesAction = (payload) => ({ type: GET_ALL_GALLERIES, payload});