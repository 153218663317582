import React, { useState } from "react";
import Title from "../../../../elements/Title/Title";

import styles from "../../MainPage.module.scss";
import LastNewsItem from "./LastNewsItem";
import ReadMoreLink from "../../../../elements/ReadMoreLink/ReadMoreLink";
import { useDispatch, useSelector } from "react-redux";
import { dateFormatter } from "../../../../../functions/dateFormatter";
import { useEffect } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import sliderStyles from './sliderStyles.scss';
import { useRef } from "react";
import { globalTranslate } from "../../../../../functions/languages";
import { getFirstAlbums } from '../../../../../functions/getPhotos';
import { getNewsMainPage } from '../../../../../functions/getNews';

const LastNews = () => {
  const dispatch = useDispatch()
  const [itemWidth, setItemWidth] = useState(0)
  const news = useSelector((state) => state.news.mainPageNews);
  const currentLang = useSelector((state) => state.lang.currentLang);

  useEffect(() => {
    dispatch(getNewsMainPage(currentLang === 'en'))
  }, [currentLang])

  const newsWrapper = useRef();

  const setSlidesToShow = () => {
    let slidesToShow = 2
    
    if (window.innerWidth > 1200) slidesToShow = 3
    if (window.innerWidth > 1350) slidesToShow = 4

    return slidesToShow;
  }

  useEffect(() => {
    if (!newsWrapper) return;
    setItemWidth(newsWrapper.current.clientWidth / setSlidesToShow())
  }, [newsWrapper, setSlidesToShow])  

  

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: setSlidesToShow(),
    slidesToScroll: setSlidesToShow(),
  };

  return (
    <div className={styles.lastNewsWrapper} style={{ marginBottom: 40 }}>
      {/* <Title text={"Новости"} /> */}
      <h3 style={{marginBottom: 40}}>{globalTranslate(currentLang, 'Новости')}</h3>
      {window.innerWidth < 768 ? (
        <div className={styles.LastNewsItem__container}>
        <div ref={newsWrapper}>
        {news.length > 0 &&
          news.map(
            (item, index) =>
              index < 4 && (
                <LastNewsItem
                  key={index}
                  item={item}
                />
              )
          )}
          </div>
      </div> 
      ) : (
        <div style={{marginBottom: 40}} ref={newsWrapper}>
        <Slider {...settings}>
            {news.length > 0 &&
              news.map(
                (item, index) =>
                  (
                    <LastNewsItem
                      key={index}
                      item={item}
                      width={itemWidth}
                    />
                  )
              )}
        </Slider>
      </div>
      )}
      
      <ReadMoreLink text={globalTranslate(currentLang, 'Все новости')} link="/news/1" />
    </div>
  );
};

export default LastNews;
