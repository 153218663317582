import React, { useRef } from "react";

import styles from "./CabinetProfile.module.scss";
import { Link } from "react-router-dom";
import { ReactComponent as ConfirmedIcon } from "../../../image/icons/confirmed.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyRegistrations } from '../../../functions/getCompanyRegistrations';
import { BASE_URL } from "../../../BASE_URL";
import { globalTranslate } from "../../../functions/languages";
import ChangeCategorySelect from "./ChangeCategorySelect/ChangeCategorySelect";
import setNewCategoryofGroupProfile from '../../../functions/setNewCategoryofGroupProfile';


const CabinetProfile = ({
  item,
  slots,
  getNewSlots,
  deletePersone
}) => {
  const [mobileImageWidth, setMobileImageWidth] = useState(0);

  const mobileImage = useRef();

  const is_manager = useSelector(state => state.user.is_manager)

  const dispatch = useDispatch()
  const currentLang = useSelector(state => state.lang.currentLang)

  useEffect(() => {
    getCompanyRegistrations(dispatch);
  }, [dispatch])

  useEffect(() => {
    setMobileImageWidth(mobileImage.current.clientWidth)
  }, [mobileImage])

  const changeProfileCategory = async (category) => {
    await setNewCategoryofGroupProfile(item.id, category.id)
    getNewSlots()
  }

  return (
    
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Link to={'/cabinet/my_registrations/' + item.id}>
      <div className={styles.item}>
        <div
          className={styles.image}
          style={window.innerWidth < 1000 ? {
            backgroundImage: `url(${BASE_URL}${item.photo})`,
            height: mobileImageWidth,
          } : {
            backgroundImage: `url(${BASE_URL}${item.photo})`,
          }}
          ref={mobileImage}
        >
          {item.is_confirmed && <div className={styles.confirmed}><ConfirmedIcon /></div>}
          
        </div>
        {currentLang === 'ru' ? <p>{item.last_name + " " + item.first_name + " " + item.middle_name}</p> : <p>{item.last_name_en + " " + item.first_name_en + " " + item.middle_name_en}</p>}
      </div>
      
    </Link>
    {slots && is_manager && 
      <ChangeCategorySelect onChange={changeProfileCategory} options={slots} isValue={item.participant_category} />
    }
    <span className={styles.deletePersone}
      onClick={() => deletePersone({first_name: item.first_name, last_name: item.last_name, id: item.id})}
      >{globalTranslate(currentLang, 'Удалить анкету')}</span>
    </div>
    
  );
};

export default CabinetProfile;
