import React, { useState } from "react";

import styles from "../GalleryPage.module.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import videoIcon from "../../../../image/icons/video-icon.svg";
import posterVk_ru from "../../../../image/5.png";
import posterVk_en from "../../../../image/6.png";

const VideosItem = ({ item, width, onClick }) => {
  const [currentGallery, setCurrentGallery] = useState();

  const currentLang = useSelector((state) => state.lang.currentLang);
  const galleryName = useSelector((state) => state.videos.videos);

  useEffect(() => {
    if (galleryName && galleryName.results.length > 0) {
      const currentGalleryName =
        currentLang === "ru"
          ? galleryName.results.filter((point) => point.id === item.gallery)[0]
              .title
          : galleryName.results.filter((point) => point.id === item.gallery)[0]
              .title_en;
      setCurrentGallery(currentGalleryName);
    }
  }, [galleryName, item]);

  const makePreviewImg = (link) => {
    if (item.source === "youtube") {
      const beginID = link.indexOf("v=");
      const endID = link.indexOf("&", beginID);

      return endID === -1
        ? link.substring(beginID + 2)
        : link.substring(beginID + 2, endID);
    } else {
      return link;
    }
  };

  return (
    <div
      className={styles.item}
      style={{ width: width }}
      onClick={() =>
        onClick({ link: makePreviewImg(item.link), type: item.source })
      }
    >
      <div className={styles.imgWrap}>
        {item.source === "youtube" ? (
          <img
            src={
              "https://img.youtube.com/vi/" +
              makePreviewImg(item.link) +
              "/0.jpg"
            }
            alt={item.title}
          />
        ) : (
          <img src={currentLang === 'ru' ? posterVk_ru : posterVk_en} alt={item.title} />
        )}
      </div>
      {currentGallery && (
        <div className={styles.control}>
          <p>{currentGallery}</p>
          <img src={videoIcon} alt="video" />
        </div>
      )}
      <p className={styles.title}>
        {currentLang === "ru" ? item.title : item.title_en}
      </p>
    </div>
  );
};

export default VideosItem;
