import React, { useEffect, useState } from "react";
import NavMenuItem from "../../NavMenu/NavMenuItem";

import styles from "../Footer.module.scss";
import { useSelector } from "react-redux";

const FooterNav = () => {
  const [menuList, setMenuList] = useState([
    { title: "Главная", link: "/main", order_index: 100 },
    // Динамические страницы

    // {title: 'Новости', link: '/news/1'},
    // // {title: 'Программа', link: '/forum_program'},
    // {title: 'Галерея', link: '/gallery'},
    // {title: 'Контакты', link: '/contacts'},
  ]);

  const menuListRedux = useSelector((state) => state.menuList.pages);

  const isArrayHasFunc = (object) => {
    let flag = false;
    menuList.forEach((item) => {
      if (item.title === object.title) flag = true;
    });

    return !flag;
  };

  const array = [];

  if (
    menuListRedux.menu_news &&
    isArrayHasFunc({ title: "Новости", link: "/news/1" })
  )
    array.push({ title: "Новости", link: "/news/1", order_index: menuListRedux.menu_news_order_index });
    if (
      menuListRedux.menu_translations &&
      isArrayHasFunc({ title: "Трансляции", link: "/live" })
    )
      array.push({
        title: "Трансляции",
        link: "/live",
        order_index: menuListRedux.menu_translations_order_index,
      });
    //
  if (
    menuListRedux.menu_usual_partners &&
    isArrayHasFunc({ title: "Партнеры", link: "/partners" })
  )
    array.push({ title: "Партнеры", link: "/partners", order_index: menuListRedux.menu_usual_partners_order_index });

  if (
    menuListRedux.menu_media_partners &&
    isArrayHasFunc({ title: "Медиапартнеры", link: "/mediapartners" })
  )
    array.push({ title: "Медиапартнеры", link: "/mediapartners", order_index: menuListRedux.menu_media_partners_order_index });
    //
  if (
    menuListRedux.menu_program &&
    isArrayHasFunc({ title: "Архитектура", link: "/forum_program" })
  )
    array.push({ title: "Архитектура", link: "/forum_program", order_index: menuListRedux.menu_program_order_index });
  if (
    menuListRedux.menu_gallery &&
    isArrayHasFunc({ title: "Галерея", link: "/gallery" })
  )
    array.push({ title: "Галерея", link: "/gallery", order_index: menuListRedux.menu_gallery_order_index });
  if (
    menuListRedux.menu_contacts &&
    isArrayHasFunc({ title: "Контакты", link: "/contacts" })
  )
    array.push({ title: "Контакты", link: "/contacts", order_index: menuListRedux.menu_contacts_order_index });

  if (array.length > 0) {;
    const arrayToState = [].concat(menuList, array);
    arrayToState.sort((a, b) => a.order_index > b.order_index ? 1 : -1)
    setMenuList(arrayToState);
  }
  // }, [menuListRedux])

  const pages = useSelector((state) => state.pages.pages);
  useEffect(() => {
    if (pages && pages.length > 0 && menuList) {
      pages.reverse();
      pages.map((item) => {
        if (item.link === '') {

        } else {
          if (item.parent === null) {
            let hasItem = false;
            menuList.forEach((point) => {
              if (item.title === point.title) {
                hasItem = true;
              }
            });
            if (hasItem === false) {
              const array = menuList;
    
              array.splice(1, 0, item);
              setMenuList(array);
            }
          }
        }
      });
    }
  }, [pages]);

  return (
    <ul className={styles.footerNav}>
      {menuList &&
        menuList.length > 0 &&
        menuList.map((item, index) => (
          <NavMenuItem
            key={index}
            text={item.title}
            is_external_link={item.is_external_link}
                url={
                  item.id
                    ? !item.is_external_link
                      ? "/pages/" + item.link
                      : item.link
                    : item.link
                }
          />
        ))}
    </ul>
  );
};

export default FooterNav;
