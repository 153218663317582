import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SelectInput from "../../../elements/SelectInput/SelectInput";
import TextInput from "../../../elements/TextInput/TextInput";

import styles from "./UserDataForms.module.scss";
import { setCompanyRegDataAction } from "../../../../redux/Reducers/regInfoReducers";
import TranslitTextInput from "../../../elements/TextInput/TranslitTextInput";
import SelectWithoutLabel from "../../../elements/SelectInput/SelectWithoutLabel";
import { useLocation } from "react-router-dom";
import { globalTranslate } from "../../../../functions/languages";

const CompanyInfo = ({ isValue, invite, isSMI, isPaidDefault, errorList, isDisabled, clearError, change }) => {
  const dispatch = useDispatch();
  const [isPaid, setIsPaid] = useState(false);
  const [forms, setForms] = useState([]);
  const [spheres, setSpheres] = useState([]);
  const { pathname } = useLocation();

  const spheresRedux = useSelector((state) => state.spheres.spheres);
  const formsRedux = useSelector((state) => state.organisation_forms.forms);
  const currentLang = useSelector(state => state.lang.currentLang)
  const status = useSelector(state => state.regInfo.leg_type)

  useEffect(() => {
    setForms(formsRedux)
  }, [formsRedux])
  useEffect(() => {
    setSpheres(spheresRedux)
  }, [spheresRedux])

  const [companyData, setCompanyData] = useState({
    company_organization_form: isValue
      ? isValue.company_organization_form
      : 1,
    company_name: isValue ? isValue.company_name : "",
    company_position: "",
    company_position_en: "",
    company_sphere: isValue ? isValue.company_sphere : "",
    company_inn: isValue ? isValue.company_inn : "",
    company_kpp: isValue ? isValue.company_kpp : "",
    company_bik: isValue ? isValue.company_bik : "",
    company_ogrn: isValue ? isValue.company_ogrn : "",
    company_bank_account: isValue ? isValue.company_bank_account : "",
    company_mail_address: isValue ? isValue.company_mail_address : "",
    company_leg_address: isValue ? isValue.company_leg_address : "",
  });

  const [changedData, setChangedData] = useState({})

  useEffect(() => {
    if (!change) return;
    change(changedData);
  }, [changedData])

  useEffect(() => {
    if (!isValue) return;
    setCompanyData({
      company_organization_form: isValue.company_organization_form,
      company_name: isValue.company_name,
      company_position: isValue.company_position,
    company_position_en: isValue.company_position_en,
      company_sphere:
        (spheres && isValue.company_sphere)
          ? spheres.filter((item) => item.id === isValue.company_sphere)[0].id
          : "",
      company_inn: isValue.company_inn,
      company_kpp: isValue.company_kpp,
      company_bik: isValue.company_bik,
      company_ogrn: isValue.company_ogrn,
      company_bank_account: isValue.company_bank_account,
      company_mail_address: isValue.company_mail_address,
      company_leg_address: isValue.company_leg_address,
    });
  }, [spheres, isValue]);

  const currentTypeOfRegistration = useSelector(
    (state) => state.regInfo.registration_type
  );

  const setName = (data) => {
    setCompanyData({ ...companyData, company_name: data });
    setChangedData({ ...companyData, company_name: data })
    clearError("Укажите название организации");
  };
  const setOrganisationForm = (data) => {
    setCompanyData({ ...companyData, company_organization_form: data.id });
    setChangedData({ ...companyData, company_organization_form: data.id })

    clearError("Выберите форму организации");
  };
  const setPosition = (data) => {
    setCompanyData({ ...companyData, company_position: data });
    setChangedData({ ...companyData, company_position: data })

    clearError("Укажите вашу должность");
  };
  const setPositionEn = (data) => {
    setCompanyData({ ...companyData, company_position_en: data });
    setChangedData({ ...companyData, company_position_en: data })

    clearError("Укажите вашу должность на английском");
  };
  const setProvince = (data) => {
    setCompanyData({ ...companyData, company_sphere: data.id });
    setChangedData({ ...companyData, company_sphere: data.id })

    clearError("Выберите сферу деятельности компании");
  };
  const setINN = (data) => {
    setCompanyData({ ...companyData, company_inn: data });
    setChangedData({ ...companyData, company_inn: data })

    clearError("Введите ИНН");
    clearError("ИНН включает только цифры");
  };
  const setKPP = (data) => {
    setCompanyData({ ...companyData, company_kpp: data });
    setChangedData({ ...companyData, company_kpp: data })

    clearError("Введите КПП");
    clearError("КПП включает только цифры");
  };
  const setBIK = (data) => {
    setCompanyData({ ...companyData, company_bik: data });
    setChangedData({ ...companyData, company_bik: data })

    clearError("Введите БИК");
    clearError("БИК включает только цифры");
  };
  const setOGRN = (data) => {
    setCompanyData({ ...companyData, company_ogrn: data });
    setChangedData({ ...companyData, company_ogrn: data })

    clearError("Введите ОГРН");
    clearError("ОГРН включает только цифры");
  };
  const setBankName = (data) => {
    setCompanyData({ ...companyData, company_bank_account: data });
    setChangedData({ ...companyData, company_bank_account: data })

    clearError("Введите наименование банка");
  };
  const setPostalAdress = (data) => {
    setCompanyData({ ...companyData, company_mail_address: data });
    setChangedData({ ...companyData, company_mail_address: data })

    clearError("Укажите почтовый адрес компании");
  };
  const setLowerAdress = (data) => {
    setCompanyData({ ...companyData, company_leg_address: data });
    setChangedData({ ...companyData, company_leg_address: data })

    clearError("Укажите юридический адрес компании");
  };
  const setMediaAdress = (data) => {
    setCompanyData({ ...companyData, company_mail_address: data });
    setChangedData({ ...companyData, company_mail_address: data })

    clearError("Укажите почтовый адрес компании");
  };

  useEffect(() => {
    dispatch(setCompanyRegDataAction(companyData));
  }, [companyData]);

  useEffect(() => {
    (currentTypeOfRegistration !== "paid") ? setIsPaid(false) : setIsPaid(true);
  }, [currentTypeOfRegistration]);

  useEffect(() => {
    if (!isPaidDefault) return;
    setIsPaid(true)
  }, [isPaidDefault])

  return (
    <div>
      <h3>{globalTranslate(currentLang, 'Информация об организации')}</h3>

      <div className={styles.one_all_template}>
        <div
          style={{ boxSizing: "border-box" }}
          className={styles.one_of_all_item}
        >
          <SelectWithoutLabel
            isDisabled={isDisabled}
            isValue={companyData.company_organization_form}
            options={forms}
            onChange={setOrganisationForm}
            twoKeys={true}
            error={
              errorList && errorList.includes("Выберите форму организации")
                ? true
                : false
            }
          />
        </div>
        <div className={styles.all_item}>
          <TextInput
            isDisabled={isDisabled}
            isValue={companyData.company_name}
            labelText={isSMI ? "Название медиа" : "Название организации"}
            onChange={setName}
            error={
              errorList && errorList.includes("Укажите название организации")
                ? true
                : false
            }
          />
        </div>
      </div>
        <>
          <TextInput
            isDisabled={isDisabled}
            isValue={companyData.company_position}
            labelText={"Ваша должность"}
            onChange={setPosition}
            error={
              errorList && errorList.includes("Укажите вашу должность")
                ? true
                : false
            }
          />
          <TextInput
            isDisabled={isDisabled}
            isValue={companyData.company_position_en}
            labelText={"Ваша должность на английском"}
            onChange={setPositionEn}
            error={
              errorList &&
              errorList.includes("Укажите вашу должность на английском")
                ? true
                : false
            }
          />
        </>

      {(!isSMI) ? (
        <SelectInput
          isDisabled={isDisabled}
          twoKeys={true}
          isValue={companyData.company_sphere}
          labelText={"Сфера деятельности"}
          
          options={spheres}
          onChange={setProvince}
          error={
            errorList &&
            errorList.includes("Выберите сферу деятельности компании")
              ? true
              : false
          }
        />
      ) : (
        <TextInput
          isDisabled={isDisabled}
          labelText={"Почтовый адрес медиа"}
          isValue={companyData.company_mail_address}
          onChange={setMediaAdress}
          error={
            errorList && errorList.includes("Укажите почтовый адрес компании")
              ? true
              : false
          }
        />
      )}

      {isPaid && status && (status === "Юридическое лицо" || status === "Legal person") && (
        <>
          <TextInput
            isDisabled={isDisabled}
            isValue={companyData.company_inn}
            labelText={"ИНН"}
            onChange={setINN}
            error={
              errorList &&
              (errorList.includes("Введите ИНН") ||
                errorList.includes("ИНН включает только цифры"))
                ? true
                : false
            }
          />
          <TextInput
            isDisabled={isDisabled}
            isValue={companyData.company_kpp}
            labelText={"КПП"}
            onChange={setKPP}
            error={
              errorList &&
              (errorList.includes("Введите КПП") ||
                errorList.includes("КПП включает только цифры"))
                ? true
                : false
            }
          />
          <TextInput
            isDisabled={isDisabled}
            isValue={companyData.company_bik}
            labelText={"БИК"}
            onChange={setBIK}
            error={
              errorList &&
              (errorList.includes("Введите БИК") ||
                errorList.includes("БИК включает только цифры"))
                ? true
                : false
            }
          />
          <TextInput
            isDisabled={isDisabled}
            isValue={companyData.company_ogrn}
            labelText={"ОГРН"}
            onChange={setOGRN}
            error={
              errorList &&
              (errorList.includes("Введите ОГРН") ||
                errorList.includes("ОГРН включает только цифры"))
                ? true
                : false
            }
          />
          <TextInput
            isDisabled={isDisabled}
            isValue={companyData.company_bank_account}
            labelText={"Название банка организации"}
            onChange={setBankName}
            error={
              errorList && errorList.includes("Введите наименование банка")
                ? true
                : false
            }
          />

          <TextInput
            isDisabled={isDisabled}
            isValue={companyData.company_mail_address}
            labelText={"Почтовый адрес организации"}
            onChange={setPostalAdress}
            error={
              errorList && errorList.includes("Укажите почтовый адрес компании")
                ? true
                : false
            }
          />
          <TextInput
            isDisabled={isDisabled}
            isValue={companyData.company_leg_address}
            labelText={"Юридический адрес организации"}
            onChange={setLowerAdress}
            error={
              errorList &&
              errorList.includes("Укажите юридический адрес компании")
                ? true
                : false
            }
          />
        </>
      )}
    </div>
  );
};

export default CompanyInfo;
