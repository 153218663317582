export const tableFormatter = (text, color) => {
    
    let timeTagFlag = false
    let indexOfSymbol = text.indexOf('#');
    while (indexOfSymbol !== -1) {
        if (timeTagFlag) {
            text = text.slice(0, indexOfSymbol) + '</p>' + text.slice(indexOfSymbol + 1)
            timeTagFlag = !timeTagFlag
        } else {
            text = text.slice(0, indexOfSymbol) + '<p class="table_item time">' + text.slice(indexOfSymbol + 1)
            timeTagFlag = !timeTagFlag
        }
        indexOfSymbol = text.indexOf('#')
    }

    let smallTagFlag = false
    indexOfSymbol = text.indexOf('$');
    while (indexOfSymbol !== -1) {
        if (timeTagFlag) {
            text = text.slice(0, indexOfSymbol) + '</p>' + text.slice(indexOfSymbol + 1)
            smallTagFlag = !smallTagFlag
        } else {
            text = text.slice(0, indexOfSymbol) + '<p class="table_item small">' + text.slice(indexOfSymbol + 1)
            smallTagFlag = !smallTagFlag
        }
        indexOfSymbol = text.indexOf('$')
    }

    indexOfSymbol = text.indexOf('^')
    while (indexOfSymbol !== -1) {
        text = text.slice(0, indexOfSymbol) + '<br>' + text.slice(indexOfSymbol + 1);
        indexOfSymbol = text.indexOf('^')
    }

    let titleTagFlag = false
    indexOfSymbol = text.indexOf('*');
    while (indexOfSymbol !== -1) {
        if (titleTagFlag) {
            text = text.slice(0, indexOfSymbol) + '</p>' + text.slice(indexOfSymbol + 1)
            titleTagFlag = !titleTagFlag
        } else {
            text = text.slice(0, indexOfSymbol) + '<p class="table_item track" style="color: ' + color + '">' + text.slice(indexOfSymbol + 1)
            titleTagFlag = !titleTagFlag
        }
        indexOfSymbol = text.indexOf('*')
    }

    return {__html: text};
}




