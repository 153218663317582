import React, { useState } from 'react';
import { useEffect } from 'react';
import Container from '../../elements/Container/Container';
import { ReactComponent as Logo } from '../../../image/new-logo_color_mobile.svg';
import { ReactComponent as CloseIcon } from '../../../image/icons/close-menu.svg';
import styles from './ProgramPage.module.scss';
import ContentContainer from '../../elements/Container/ContentContainer';

const ProgramModal = ({ text, close, classOpen, classClose }) => {
  const createMarkup = (text) => {
    return { __html: text };
  };
  return (
    <div className={classOpen ? styles.ProgramModalOpen : styles.ProgramModal} style={classOpen ? {top: 0} : {top: '100%'}}>
        <Container>
            <div className={styles.modalHeader}>
                <Logo width={282} height={90} />
                <span className={styles.closeModal} onClick={close}>Вернуться к программе</span>
            </div>
            <div className={styles.modalBody}>
                <h4>Программа события</h4>
                <div dangerouslySetInnerHTML={createMarkup(text)}></div>
            </div>
        </Container>
    </div>
  )
}

export default ProgramModal