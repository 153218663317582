import { BASE_URL_API } from '../BASE_URL.js';
import { getCountriesAction } from '../redux/Reducers/locationsReducers.js';
import { getSocialsAction } from '../redux/Reducers/socialsReducer.js';

const getSocials = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'socials/');
        const data = await response.json();

        dispatch(getSocialsAction(data.results));
    }
}

export default getSocials;