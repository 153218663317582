import React, { useState, useEffect } from "react";
import Container from "../../elements/Container/Container";
import PageTemplate from "../../PageTemplate/PageTemplate";
import { useDispatch, useSelector } from "react-redux";

import styles from "./ProgramPage.module.scss";
import defaultStyles from "./ProgramPage.scss";
import { tableFormatter } from "../../../functions/tableFormatter";
import getProgram from "../../../functions/getProgram";
import ProgramModal from "./ProgramModal";

// import pdfGIF from "../../../image/lotties/pdf.gif";

// import html2pdf from "html2pdf.js";

import readMore from "../../../image/icons/info.png";
// import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { globalTranslate } from "../../../functions/languages";

import getProgramFiles from "../../../functions/getProgramFiles";

const ProgramPage = () => {
  const [programData, setProgramData] = useState();
  const [viewWidth, setViewWidth] = useState(false);
  const [modalText, setModalText] = useState(false);
  const [colors, setColors] = useState([]);
  // const [isPreparingPDF, setPreparingPDF] = useState(false);
  // const [element, setElement] = useState(false);
  // const [elementToPrint, setelementToPrint] = useState(false);
  // const [head, sethead] = useState(false)
  const [localFiles, setLocalFiles] = useState(false);

  const currentLang = useSelector((state) => state.lang.currentLang);

  // const elementToPrint = document.getElementById("element-to-print");
  // const element = document.querySelector(".current")
  // const head = document.getElementById("head")

  const visibleElement = useRef();

  // useEffect(() => {
  //   setElement(document.querySelector(".current"))
  //   setelementToPrint(document.getElementById("element-to-print"))
  //   sethead(document.getElementById("head"))
  // }, [programData])

  // console.dir(element)

  // const navigate = useNavigate()

  // const downloadArchitechturePDF = async () => {
  //   const options = {
  //     margin: [0, 0, 0, 0],
  //     filename: "Архитектура Красноярского экономического форума.pdf",
  //     image: { type: "jpeg", quality: 0.95 },
  //     pagebreak: { mode: ["avoid-all"] },
  //     // html2canvas:  { scale: 1 },
  //     jsPDF: {
  //       unit: "px",
  //       format: [
  //         elementToPrint.clientWidth + 600,
  //         element.clientHeight + head.clientHeight,
  //       ],
  //       orientation: "landscape",
  //     },
  //   };
  //   setPreparingPDF(true);
  //   await html2pdf(elementToPrint, options);
  //   setPreparingPDF(false);
  // };

  // const downloadProgramPDF = () => {
  //   const textArray = [];

  //   programData.cells.forEach(item => {
  //     if (item.description && item.description !== '') {
  //       textArray.push(item.description)
  //     }
  //   })

  //   if (textArray.length > 0) navigate('/preparing_program_for_PDF', {state: textArray})
  // }

  const program = useSelector((state) => state.program.program);
  const programFiles = useSelector((state) => state.programFiles.files);

  useEffect(() => {
    if (!programFiles) return;
    setLocalFiles(programFiles);
  }, [programFiles]);

  const dispatch = useDispatch();

  const resize = () => {
    if (window.innerWidth >= 1350) setViewWidth(true);
    if (window.innerWidth < 1350) setViewWidth(false);
  };

  useEffect(() => {
    if (program.length === 0) return;
    setProgramData(program);
    const legendColors = [];
    program.colors.forEach((item) => {
      if (item.is_visible) {
        legendColors.push({
          name: item.name,
          name_en: item.name_en,
          color: item.bg_color,
        });
      }
    });
    setColors(legendColors);
  }, [program]);

  useEffect(() => {
    dispatch(getProgram());
    dispatch(getProgramFiles());
  }, [dispatch]);

  useEffect(() => {
    resize();
  }, []);

  window.addEventListener("resize", () => {
    resize();
  });

  return (
    <PageTemplate>
      <Container>
        {/* {isPreparingPDF && (
            <div className={styles.lottieWrap}>
              <img src={pdfGIF} alt="" />
              <p>Подготавливаем pdf-файл...</p>
            </div>
          )} */}
        <div id="element-to-print">
          <div className={styles.head} id="head">
            <h3>
              {globalTranslate(currentLang, "Архитектура программы")}
              <span>
                * {globalTranslate(currentLang, "проект, возможны изменения")}
              </span>
            </h3>
            {viewWidth && (
              <div className={styles.legendContainer}>
                <div className={styles.legendWrap}>
                  {colors &&
                    colors.length > 0 &&
                    colors.map((item, index) => (
                      <div className={styles.legend} key={index}>
                        <p>{currentLang === "ru" ? item.name : item.name_en}</p>
                        <div
                          style={{
                            minWidth: 30,
                            minHeight: 30,
                            backgroundColor: item.color,
                          }}
                        ></div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>

          {viewWidth && (
            <div>
              {programData &&
                programData.programs.map((item, index) => (
                  <div
                    key={index}
                    id={"single-program"}
                    className="current"
                    ref={visibleElement}
                  >
                    <div
                      key={index}
                      style={{
                        borderRadius: 10,
                        display: "grid",
                        gridTemplateColumns: item.template_columns + "",
                        gridTemplateRows: item.template_rows + "",
                        gridGap: "2px",
                        maxWidth: "100%",
                        marginBottom: 80,
                        overflowY: "hidden",
                        overflowX: "scroll",
                        transition: "1s",
                        // transform: isPreparingPDF ? 'scale(1.1)' : 'scale(1)',
                        // zIndex: isPreparingPDF ? 10 : '',
                      }}
                    >
                      {programData.cells.map(
                        (subItem, subindex) =>
                          subItem.program === item.id && (
                            <div
                              id="cells1"
                              key={subindex}
                              className={styles.cell}
                              onClick={() =>
                                subItem.description !== "" &&
                                setModalText(subItem.description)
                              }
                              style={{
                                minWidth:
                                  subItem.column_start === 1 ? "none" : "130px",
                                justifyContent:
                                  Number(subItem.row_start) + 2 ===
                                  Number(subItem.row_end)
                                    ? "center"
                                    : "start",
                                gridArea:
                                  subItem.row_start +
                                  " / " +
                                  subItem.column_start +
                                  " / " +
                                  subItem.row_end +
                                  " / " +
                                  subItem.column_end,
                                backgroundColor:
                                  subItem.color === "null"
                                    ? "#c1dffc"
                                    : programData.colors.filter(
                                        (point) => point.id === subItem.color
                                      )[0].bg_color,
                                color:
                                  subItem.color === "null"
                                    ? "#000000"
                                    : programData.colors.filter(
                                        (point) => point.id === subItem.color
                                      )[0].text_color,
                                cursor:
                                  subItem.description !== ""
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={tableFormatter(
                                  currentLang === "ru"
                                    ? subItem.text
                                    : subItem.text_en,
                                  programData.colors.filter(
                                    (point) => point.id === subItem.color
                                  )[0].title_color
                                )}
                              ></div>
                              {subItem.description !== "" && (
                                <img src={readMore} alt="" />
                              )}
                            </div>
                          )
                      )}
                      <ProgramModal
                        text={modalText}
                        close={() => setModalText(false)}
                        classOpen={modalText !== false ? true : false}
                      />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div
          style={{
            marginBottom: 40,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          {currentLang === "ru" ? (
            <>
              {localFiles &&
                localFiles.length > 0 &&
                localFiles.map((item, index) => (
                  <a
                    key={index}
                    href={item.file}
                    className={styles.downloadButton}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                ))}
            </>
          ) : (
            <>
              {localFiles &&
                localFiles.length > 0 &&
                localFiles.map(
                  (item, index) =>
                    item.title_en && (
                      <a
                        key={index}
                        href={item.file_en}
                        className={styles.downloadButton}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.title_en}
                      </a>
                    )
                )}
            </>
          )}
        </div>
      </Container>
    </PageTemplate>
  );
};

export default ProgramPage;
