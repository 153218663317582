import { languages } from "../../functions/languages";

const initialState = {
    currentLang: languages(),
  };
  
  const SET_NEW_LANG = 'SET_NEW_LANG';
  
  export const langReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_NEW_LANG:
        return {
          currentLang: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const setNewLangAction = (payload) => ({ type: SET_NEW_LANG, payload});