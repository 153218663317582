import React from "react";

import styles from "./NavMenu.module.scss";
import NavMenuItem from "./NavMenuItem";
import { useSelector, useDispatch } from "react-redux";
import UserMiniature from "../UserMiniature/UserMiniature";
import NavMenuMobile from "./NavMenuMobile";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as BurgerIcon } from "../../../image/icons/burger.svg";
import { ReactComponent as CloseMenuIcon } from "../../../image/icons/close-menu.svg";
import { globalTranslate } from "../../../functions/languages";

const NavMenu = () => {
  const [menuList, setMenuList] = useState([
    { title: "Главная", link: "/main", order_index: 100 },
  ]);
  const [viewWidth, setViewWidth] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [openRegistration, setOpenRegistration] = useState(false);

  const { pathname } = useLocation();

  const isAuth = useSelector((state) => state.isAuth.isAuth);
  const is_manager = useSelector((state) => state.user.is_manager);
  const pages = useSelector((state) => state.pages.pages);
  const menuListRedux = useSelector((state) => state.menuList.pages);
  const typeOfRegistration = useSelector(
    (state) => state.registrationType.types
  );
  const currentLang = useSelector((state) => state.lang.currentLang);

  useEffect(() => {
    if (!typeOfRegistration) return;

    for (let key in typeOfRegistration) {
      if (
        (key === "reg_allow_group" || key === "reg_allow_person") &&
        typeOfRegistration[key]
      )
        setOpenRegistration(true);
    }
  }, [typeOfRegistration]);

  const isArrayHasFunc = (object) => {
    let flag = false;
    menuList.forEach((item) => {
      if (item.title === object.title) flag = true;
    });

    return !flag;
  };
  const array = [];
  if (
    menuListRedux.menu_news &&
    isArrayHasFunc({ title: "Новости", link: "/news/1" })
  )
    array.push({
      title: "Новости",
      link: "/news/1",
      order_index: menuListRedux.menu_news_order_index,
    });

  //
  if (
    menuListRedux.menu_translations &&
    isArrayHasFunc({ title: "Трансляции", link: "/live" })
  )
    array.push({
      title: "Трансляции",
      link: "/live",
      order_index: menuListRedux.menu_translations_order_index,
    });

  if (
    menuListRedux.menu_usual_partners &&
    isArrayHasFunc({ title: "Партнеры", link: "/partners" })
  )
    array.push({
      title: "Партнеры",
      link: "/partners",
      order_index: menuListRedux.menu_usual_partners_order_index,
    });
  if (
    menuListRedux.menu_media_partners &&
    isArrayHasFunc({ title: "Медиапартнеры", link: "/mediapartners" })
  )
    array.push({
      title: "Медиапартнеры",
      link: "/mediapartners",
      order_index: menuListRedux.menu_media_partners_order_index,
    });
  //
  if (
    menuListRedux.menu_program &&
    isArrayHasFunc({ title: "Архитектура", link: "/forum_program" })
  )
    array.push({
      title: "Архитектура",
      link: "/forum_program",
      order_index: menuListRedux.menu_program_order_index,
    });
  if (
    menuListRedux.menu_gallery &&
    isArrayHasFunc({ title: "Галерея", link: "/gallery" })
  )
    array.push({
      title: "Галерея",
      link: "/gallery/photos",
      order_index: menuListRedux.menu_gallery_order_index,
    });
  if (
    menuListRedux.menu_contacts &&
    isArrayHasFunc({ title: "Контакты", link: "/contacts" })
  )
    array.push({
      title: "Контакты",
      link: "/contacts",
      order_index: menuListRedux.menu_contacts_order_index,
    });

  if (array.length > 0) {
    const arrayToState = [].concat(menuList, array);
    arrayToState.sort((a, b) => (a.order_index > b.order_index ? 1 : -1));
    setMenuList(arrayToState);
  }

  // }, [menuListRedux])

  const resize = () => {
    if (window.innerWidth < 1000) setViewWidth(true);
    if (window.innerWidth >= 1000) setViewWidth(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowMenu(false);
    }, 50);
    resize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  // useEffect(() => {
  if (pages && pages.length > 0 && menuList) {
    pages.reverse();
    pages.map((item) => {
      if (item.link === "") {
      } else {
        if (item.parent === null) {
          let hasItem = false;
          menuList.forEach((point) => {
            if (item.title === point.title) {
              hasItem = true;
            }
          });
          if (hasItem === false) {
            const array = menuList;

            array.splice(1, 0, item);
            setMenuList(array);
          }
        }
      }
    });
  }
  // }, [pages, menuList])
  console.log(menuList)
  return viewWidth ? (
    <NavMenuMobile />
  ) : (
    <>
      {(pathname === "/forum_program" || pathname.includes("/live/")) && (
        <div className={styles.programControl}>
          {showMenu ? (
            <CloseMenuIcon onClick={() => setShowMenu(false)} />
          ) : (
            <BurgerIcon onClick={() => setShowMenu(true)} />
          )}
        </div>
      )}
      <div
        className={styles.NavMenu}
        id="sidebar"
        style={
          (pathname === "/forum_program" || pathname.includes("/live/")) &&
          !showMenu
            ? {
                transform: "translateX(260px)",
                paddingLeft: 70,
                backgroundColor: "#0080ff",
              }
            : pathname === "/forum_program" && showMenu
            ? { transform: "translateX(0px)", backgroundColor: "#0080ff" }
            : {}
        }
      >
        <ul className={styles.navlist}>
          {menuList &&
            menuList.length > 0 &&
            menuList.map((item, index) => (
              <NavMenuItem
                key={index}
                childrenList={
                  item.children && item.children.length > 0 && item.children
                }
                text={item.title}
                is_external_link={item.is_external_link}
                url={
                  item.id
                    ? !item.is_external_link
                      ? "/pages/" + item.link
                      : item.link
                    : item.link
                }
              />
            ))}
        </ul>
        <ul>
          {isAuth ? (
            !is_manager ? (
              <UserMiniature
                name={globalTranslate(currentLang, "Личный кабинет")}
                url={"/cabinet/my_profile"}
              />
            ) : (
              <UserMiniature
                name={globalTranslate(currentLang, "Личный кабинет")}
                url={"/cabinet/my_registrations"}
              />
            )
          ) : // <NavMenuItem text={"Регистрация"} url={"/registration"} />
          openRegistration ? (
            <NavMenuItem text={"Регистрация"} url={"/registration"} />
          ) : (
            <NavMenuItem text={"Войти"} url={"/login"} />
          )}
        </ul>
      </div>
    </>
  );
};

export default NavMenu;
