const initialState = {
    firstVideos: false,
    videos: false,
    galleryInfo: false
  };
  
  const GET_FIRST_VIDEOS = 'GET_FIRST_VIDEOS';
  const GET_ALL_VIDEOS = 'GET_ALL_VIDEOS';
  
  export const videosReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_FIRST_VIDEOS:
        const array = []
        action.payload.results.forEach(item => {
          item.videos.forEach(point => array.push(point))
        })
        array.splice(8)
        return {...state,
          firstVideos: array,
          videos: action.payload,
        };
      case GET_ALL_VIDEOS:
        return {...state,
          videos: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const getFirstVideosAction = (payload) => ({ type: GET_FIRST_VIDEOS, payload});
  export const getAllVideosAction = (payload) => ({ type: GET_ALL_VIDEOS, payload});