export const validateSingleReg = (data, type) => {
  let warnings = [];

  if (type === "free") {
    if (data.participant_category === "") {
      warnings.push("Выберите категорию участника");
    }
    if (data.invite_signer === "") {
      warnings.push("Выберите приглашение");
    }
    if (data.last_name === "") {
      //
      warnings.push("Введите фамилию участника");
    }
    if (data.last_name_en === "") {
      //
      warnings.push("Введите фамилию участника на английском");
    }
    if (data.last_name.length < 2) {
      warnings.push('Поле "Фамилия" должно быть длинее 2 символов');
    }
    if (data.first_name === "") {
      warnings.push("Введите имя участника");
    }
    if (data.first_name.length < 2) {
      warnings.push('Поле "Имя" должно быть длинее 2 символов');
    }
    if (data.first_name_en === "") {
      warnings.push("Введите имя участника на английском");
    }
    if (/[^A-Za-z ]/g.test(data.first_name_en)) {
      warnings.push(
        '"Имя на английском" не может содержать кирилицу, цифры и спецсимволы'
      );
    }
    if (data.country === "") {
      warnings.push("Укажите страну");
    }
    if (data.region === "") {
      warnings.push("Укажите регион");
    }
    if (data.city === "") {
      warnings.push("Укажите населенный пункт");
    }
    if (data.passport_series === "") {
      warnings.push("Укажите серию паспорта");
    }
    if (data.passport_number === "") {
      warnings.push("Укажите номер паспорта");
    }
    if (data.passport_issuer === "") {
      warnings.push("Укажите орган, выдавший паспорт");
    }
    if (data.birthday_date === "") {
      warnings.push("Укажите дату рождения");
    }
    if (data.passport_date === "") {
      warnings.push("Укажите дату выдачи паспорта");
    }
    if (data.birthday_address === "") {
      warnings.push("Укажите место рождения");
    }
    if (data.phone === "") {
      warnings.push("Укажите свой номер");
    }
    if (data.phone[0] !== "+") {
      warnings.push(
        'Номер должен быть в международном формате и начинаться с символа "+"'
      );
    }
    if (data.is_contact_via_manager === true) {
      if (data.contact_person_fio === "") {
        warnings.push("Введите ФИО контактного лица");
      }
      if (data.contact_person_fio.length < 3) {
        warnings.push("Введите корректные ФИО");
      }
      if (data.contact_person_phone === "") {
        warnings.push("Укажите номер контактного лица");
      }
      if (data.contact_person_phone[0] !== "+") {
        warnings.push(
          'Номер телефона должен быть в международном формате и начинаться с символа "+"'
        );
      }
    }
    if (
      data.photo.size === undefined ||
      (data.photo.size && data.photo.size < 1)
    ) {
      warnings.push("Загрузите фото");
    }
    if (data.company_organization_form === "") {
      warnings.push("Выберите форму организации");
    }
    if (data.company_name === "") {
      warnings.push("Укажите название организации");
    }
    if (data.company_position === "") {
      warnings.push("Укажите вашу должность");
    }
    if (data.company_position_en === "") {
      warnings.push("Укажите вашу должность на английском");
    }
    if (data.company_sphere === "") {
      warnings.push("Выберите сферу деятельности компании");
    }
    if (!data.is_confirm_data) {
      warnings.push("Подтвердите корректность введенных данных");
    }
    if (!data.is_agree_policy) {
      warnings.push("Согласитесь с политикой конфиденциальности");
    }
    return warnings.length < 1 ? true : warnings;
  }

  if (type === "onln") {
    if (data.last_name === "") {
      //
      warnings.push("Введите фамилию участника");
    }
    if (data.last_name_en === "") {
      //
      warnings.push("Введите фамилию участника на английском");
    }
    if (data.last_name.length < 2) {
      warnings.push('Поле "Фамилия" должно быть длинее 2 символов');
    }
    if (data.first_name === "") {
      warnings.push("Введите имя участника");
    }
    if (data.first_name.length < 2) {
      warnings.push('Поле "Имя" должно быть длинее 2 символов');
    }
    if (data.first_name_en === "") {
      warnings.push("Введите имя участника на английском");
    }
    if (/[^A-Za-z ]/g.test(data.first_name_en)) {
      warnings.push(
        '"Имя на английском" не может содержать кирилицу, цифры и спецсимволы'
      );
    }
    if (data.country === "") {
      warnings.push("Укажите страну");
    }
    if (data.region === "") {
      warnings.push("Укажите регион");
    }
    if (data.city === "") {
      warnings.push("Укажите населенный пункт");
    }
    if (data.birthday_date === "") {
      warnings.push("Укажите дату рождения");
    }
    if (data.birthday_address === "") {
      warnings.push("Укажите место рождения");
    }
    if (data.phone === "") {
      warnings.push("Укажите свой номер");
    }
    if (data.phone[0] !== "+") {
      warnings.push(
        'Номер должен быть в международном формате и начинаться с символа "+"'
      );
    }
    if (data.is_contact_via_manager === true) {
      if (data.contact_person_fio === "") {
        warnings.push("Введите ФИО контактного лица");
      }
      if (data.contact_person_fio.length < 3) {
        warnings.push("Введите корректные ФИО");
      }
      if (data.contact_person_phone === "") {
        warnings.push("Укажите номер контактного лица");
      }
      if (data.contact_person_phone[0] !== "+") {
        warnings.push(
          'Номер телефона должен быть в международном формате и начинаться с символа "+"'
        );
      }
    }
    if (
      data.photo.size === undefined ||
      (data.photo.size && data.photo.size < 1)
    ) {
      warnings.push("Загрузите фото");
    }
    if (!data.is_confirm_data) {
      warnings.push("Подтвердите корректность введенных данных");
    }
    if (!data.is_agree_policy) {
      warnings.push("Согласитесь с политикой конфиденциальности");
    }
    return warnings.length < 1 ? true : warnings;
  }

  if (type === "paid") {
    if (data.leg_type === "") {
      warnings.push("Выберите юридический статус участника");
    }
    if (data.last_name === "") {
      //
      warnings.push("Введите фамилию участника");
    }
    if (data.last_name_en === "") {
      //
      warnings.push("Введите фамилию участника на английском");
    }
    if (/[^A-Za-z ]/g.test(data.last_name_en)) {
      warnings.push(
        '"Фамилия на английском" не может содержать кирилицу, цифры и спецсимволы'
      );
    }
    if (data.last_name.length < 2) {
      warnings.push('Поле "Фамилия" должно быть длинее 1 символа');
    }
    if (data.first_name === "") {
      warnings.push("Введите имя участника");
    }
    if (data.first_name.length < 2) {
      warnings.push('Поле "Имя" должно быть длинее 2 символов');
    }
    if (data.first_name_en === "") {
      warnings.push("Введите имя участника на английском");
    }
    if (/[^A-Za-z ]/g.test(data.first_name_en)) {
      warnings.push(
        '"Имя на английском" не может содержать кирилицу, цифры и спецсимволы'
      );
    }
    if (data.country === "") {
      warnings.push("Укажите страну");
    }
    if (data.region === "") {
      warnings.push("Укажите регион");
    }
    if (data.city === "") {
      warnings.push("Укажите населенный пункт");
    }

    if (data.leg_type === "Физическое лицо") {
      if (data.person_inn === "") {
        warnings.push("Укажите личный ИНН");
      }
    }

    if (data.passport_series === "") {
      warnings.push("Укажите серию паспорта");
    }
    if (data.passport_number === "") {
      warnings.push("Укажите номер паспорта");
    }
    if (data.passport_issuer === "") {
      warnings.push("Укажите орган, выдавший паспорт");
    }
    if (data.birthday_date === "") {
      warnings.push("Укажите дату рождения");
    }
    if (data.passport_date === "") {
      warnings.push("Укажите дату выдачи паспорта");
    }
    if (data.birthday_address === "") {
      warnings.push("Укажите место рождения");
    }
    if (data.phone === "") {
      warnings.push("Укажите свой номер");
    }
    if (data.phone[0] !== "+") {
      warnings.push(
        'Номер должен быть в международном формате и начинаться с символа "+"'
      );
    }
    if (data.is_contact_via_manager === true) {
      if (data.contact_person_fio === "") {
        warnings.push("Введите ФИО контактного лица");
      }
      if (data.contact_person_fio.length < 3) {
        warnings.push("Введите корректные ФИО");
      }
      if (data.contact_person_phone === "") {
        warnings.push("Укажите номер контактного лица");
      }
      if (data.contact_person_phone[0] !== "+") {
        warnings.push(
          'Номер телефона должен быть в международном формате и начинаться с символа "+"'
        );
      }
    }
    if (
      data.photo.size === undefined ||
      (data.photo.size && data.photo.size < 1)
    ) {
      warnings.push("Загрузите фото");
    }
    if (data.company_organization_form === "") {
      warnings.push("Выберите форму организации");
    }
    if (data.company_name === "") {
      warnings.push("Укажите название организации");
    }
    if (data.company_position === "") {
      warnings.push("Укажите вашу должность");
    }
    if (data.company_position_en === "") {
      warnings.push("Укажите вашу должность на английском");
    }
    if (data.company_sphere === "") {
      warnings.push("Выберите сферу деятельности компании");
    }

    if (data.leg_type === "Юридическое лицо") {
      /////////////////////

      if (data.company_inn === "") {
        warnings.push("Введите ИНН");
      }
      if (!/^[0-9]+$/.test(data.company_inn)) {
        warnings.push("ИНН включает только цифры");
      }
      if (data.company_kpp === "") {
        warnings.push("Введите КПП");
      }
      if (!/^[0-9]+$/.test(data.company_kpp)) {
        warnings.push("КПП включает только цифры");
      }
      if (data.company_bik === "") {
        warnings.push("Введите БИК");
      }
      if (!/^[0-9]+$/.test(data.company_bik)) {
        warnings.push("БИК включает только цифры");
      }
      if (data.company_ogrn === "") {
        warnings.push("Введите ОГРН");
      }
      if (!/^[0-9]+$/.test(data.company_ogrn)) {
        warnings.push("ОГРН включает только цифры");
      }
      if (data.company_bank_account === "") {
        warnings.push("Введите наименование банка");
      }
      if (data.company_mail_address === "") {
        warnings.push("Укажите почтовый адрес компании");
      }
      if (data.company_leg_address === "") {
        warnings.push("Укажите юридический адрес компании");
      }
    }

    if (!data.is_confirm_data) {
      warnings.push("Подтвердите корректность введенных данных");
    }
    if (!data.is_agree_policy) {
      warnings.push("Согласитесь с политикой конфиденциальности");
    }
    if (!data.is_agree_offer) {
      warnings.push("Согласитесь с договором оферты");
    }
    return warnings.length < 1 ? true : warnings;
  }

  if (type === "smi") {
    if (data.last_name === "") {
      //
      warnings.push("Введите фамилию участника");
    }
    if (data.last_name_en === "") {
      //
      warnings.push("Введите фамилию участника на английском");
    }
    if (data.last_name.length < 2) {
      warnings.push('Поле "Фамилия" должно быть длинее 2 символов');
    }
    if (data.first_name === "") {
      warnings.push("Введите имя участника");
    }
    if (data.first_name.length < 2) {
      warnings.push('Поле "Имя" должно быть длинее 2 символов');
    }
    if (data.first_name_en === "") {
      warnings.push("Введите имя участника на английском");
    }
    if (data.country === "") {
      warnings.push("Укажите страну");
    }
    if (data.region === "") {
      warnings.push("Укажите регион");
    }
    if (data.city === "") {
      warnings.push("Укажите населенный пункт");
    }
    if (data.passport_series === "") {
      warnings.push("Укажите серию паспорта");
    }
    if (data.passport_number === "") {
      warnings.push("Укажите номер паспорта");
    }
    if (data.passport_issuer === "") {
      warnings.push("Укажите орган, выдавший паспорт");
    }
    if (data.birthday_date === "") {
      warnings.push("Укажите дату рождения");
    }
    if (data.passport_date === "") {
      warnings.push("Укажите дату выдачи паспорта");
    }
    if (data.birthday_address === "") {
      warnings.push("Укажите место рождения");
    }
    if (data.phone === "") {
      warnings.push("Укажите свой номер");
    }
    if (data.phone[0] !== "+") {
      warnings.push(
        'Номер должен быть в международном формате и начинаться с символа "+"'
      );
    }
    if (data.is_contact_via_manager === true) {
      if (data.contact_person_fio === "") {
        warnings.push("Введите ФИО контактного лица");
      }
      if (data.contact_person_fio.length < 3) {
        warnings.push("Введите корректные ФИО");
      }
      if (data.contact_person_phone === "") {
        warnings.push("Укажите номер контактного лица");
      }
      if (data.contact_person_phone[0] !== "+") {
        warnings.push(
          'Номер телефона должен быть в международном формате и начинаться с символа "+"'
        );
      }
    }
    if (
      data.photo.size === undefined ||
      (data.photo.size && data.photo.size < 1)
    ) {
      warnings.push("Загрузите фото");
    }
    if (data.company_organization_form === "") {
      warnings.push("Выберите форму организации");
    }
    if (data.company_name === "") {
      warnings.push("Укажите название организации");
    }
    if (data.company_position === "") {
      warnings.push("Укажите вашу должность");
    }
    if (data.company_position_en === "") {
      warnings.push("Укажите вашу должность на английском");
    }
    if (data.company_mail_address === "") {
      warnings.push("Укажите почтовый адрес компании");
    }
    if (!data.is_confirm_data) {
      warnings.push("Подтвердите корректность введенных данных");
    }
    if (!data.is_agree_policy) {
      warnings.push("Согласитесь с политикой конфиденциальности");
    }
    return warnings.length < 1 ? true : warnings;
  }
};

export const validateCompanyReg = (data, type, isSecondWorker) => {
  let warnings = [];
  if (type === "paid") {
    if (isSecondWorker) {
      if (data.last_name === "") {
        //
        warnings.push("Введите фамилию участника");
      }
      if (data.last_name_en === "") {
        //
        warnings.push("Введите фамилию участника на английском");
      }
      if (/[^A-Za-z ]/g.test(data.last_name_en)) {
        warnings.push(
          '"Фамилия на английском" не может содержать кирилицу, цифры и спецсимволы'
        );
      }
      if (data.last_name.length < 2) {
        warnings.push('Поле "Фамилия" должно быть длинее 1 символа');
      }
      if (data.first_name === "") {
        warnings.push("Введите имя участника");
      }
      if (data.first_name.length < 2) {
        warnings.push('Поле "Имя" должно быть длинее 2 символов');
      }
      if (data.first_name_en === "") {
        warnings.push("Введите имя участника на английском");
      }
      if (/[^A-Za-z ]/g.test(data.first_name_en)) {
        warnings.push(
          '"Имя на английском" не может содержать кирилицу, цифры и спецсимволы'
        );
      }
      if (data.country === "") {
        warnings.push("Укажите страну");
      }
      if (data.region === "") {
        warnings.push("Укажите регион");
      }
      if (data.city === "") {
        warnings.push("Укажите населенный пункт");
      }
      if (data.passport_series === "") {
        warnings.push("Укажите серию паспорта");
      }
      if (data.passport_number === "") {
        warnings.push("Укажите номер паспорта");
      }
      if (data.passport_issuer === "") {
        warnings.push("Укажите орган, выдавший паспорт");
      }
      if (data.birthday_date === "") {
        warnings.push("Укажите дату рождения");
      }
      if (data.passport_date === "") {
        warnings.push("Укажите дату выдачи паспорта");
      }
      if (data.birthday_address === "") {
        warnings.push("Укажите место рождения");
      }
      if (data.phone === "") {
        warnings.push("Укажите свой номер");
      }
      if (data.phone[0] !== "+") {
        warnings.push(
          'Номер должен быть в международном формате и начинаться с символа "+"'
        );
      }
      if (data.is_contact_via_manager === true) {
        if (data.contact_person_fio === "") {
          warnings.push("Введите ФИО контактного лица");
        }
        if (data.contact_person_fio.length < 3) {
          warnings.push("Введите корректные ФИО");
        }
        if (data.contact_person_phone === "") {
          warnings.push("Укажите номер контактного лица");
        }
        if (data.contact_person_phone[0] !== "+") {
          warnings.push(
            'Номер телефона должен быть в международном формате и начинаться с символа "+"'
          );
        }
      }
      if (
        data.photo.size === undefined ||
        (data.photo.size && data.photo.size < 1)
      ) {
        warnings.push("Загрузите фото");
      }
      if (data.company_organization_form === "") {
        warnings.push("Выберите форму организации");
      }
      if (data.company_name === "") {
        warnings.push("Укажите название организации");
      }
      if (data.company_position === "") {
        warnings.push("Укажите вашу должность");
      }
      if (data.company_position_en === "") {
        warnings.push("Укажите вашу должность на английском");
      }
      if (data.company_sphere === "") {
        warnings.push("Выберите сферу деятельности компании");
      }

      // if (data.company_inn === "") {
      //   warnings.push("Введите ИНН");
      // }
      // if (!/^[0-9]+$/.test(data.company_inn)) {
      //   warnings.push("ИНН включает только цифры");
      // }
      // if (data.company_kpp === "") {
      //   warnings.push("Введите КПП");
      // }
      // if (!/^[0-9]+$/.test(data.company_kpp)) {
      //   warnings.push("КПП включает только цифры");
      // }
      // if (data.company_bik === "") {
      //   warnings.push("Введите БИК");
      // }
      // if (!/^[0-9]+$/.test(data.company_bik)) {
      //   warnings.push("БИК включает только цифры");
      // }
      // if (data.company_ogrn === "") {
      //   warnings.push("Введите ОГРН");
      // }
      // if (!/^[0-9]+$/.test(data.company_ogrn)) {
      //   warnings.push("ОГРН включает только цифры");
      // }
      // if (data.company_bank_account === "") {
      //   warnings.push("Введите наименование банка");
      // }
      // if (data.company_mail_address === "") {
      //   warnings.push("Укажите почтовый адрес компании");
      // }
      // if (data.company_leg_address === "") {
      //   warnings.push("Укажите юридический адрес компании");
      // }

      if (!data.is_confirm_data) {
        warnings.push("Подтвердите корректность введенных данных");
      }
      if (!data.is_agree_policy) {
        warnings.push("Согласитесь с политикой конфиденциальности");
      }
      if (!data.is_agree_offer) {
        warnings.push("Согласитесь с договором оферты");
      }
    } else {
      if (data.leg_type === "") {
        warnings.push("Выберите юридический статус участника");
      }
      if (data.last_name === "") {
        //
        warnings.push("Введите фамилию участника");
      }
      if (data.last_name_en === "") {
        //
        warnings.push("Введите фамилию участника на английском");
      }
      if (/[^A-Za-z ]/g.test(data.last_name_en)) {
        warnings.push(
          '"Фамилия на английском" не может содержать кирилицу, цифры и спецсимволы'
        );
      }
      if (data.last_name.length < 2) {
        warnings.push('Поле "Фамилия" должно быть длинее 1 символа');
      }
      if (data.first_name === "") {
        warnings.push("Введите имя участника");
      }
      if (data.first_name.length < 2) {
        warnings.push('Поле "Имя" должно быть длинее 2 символов');
      }
      if (data.first_name_en === "") {
        warnings.push("Введите имя участника на английском");
      }
      if (/[^A-Za-z ]/g.test(data.first_name_en)) {
        warnings.push(
          '"Имя на английском" не может содержать кирилицу, цифры и спецсимволы'
        );
      }
      if (data.country === "") {
        warnings.push("Укажите страну");
      }
      if (data.region === "") {
        warnings.push("Укажите регион");
      }
      if (data.city === "") {
        warnings.push("Укажите населенный пункт");
      }
      if (data.passport_series === "") {
        warnings.push("Укажите серию паспорта");
      }
      if (data.passport_number === "") {
        warnings.push("Укажите номер паспорта");
      }
      if (data.passport_issuer === "") {
        warnings.push("Укажите орган, выдавший паспорт");
      }
      if (data.birthday_date === "") {
        warnings.push("Укажите дату рождения");
      }
      if (data.passport_date === "") {
        warnings.push("Укажите дату выдачи паспорта");
      }
      if (data.birthday_address === "") {
        warnings.push("Укажите место рождения");
      }
      if (data.phone === "") {
        warnings.push("Укажите свой номер");
      }
      if (data.phone[0] !== "+") {
        warnings.push(
          'Номер должен быть в международном формате и начинаться с символа "+"'
        );
      }
      if (data.is_contact_via_manager === true) {
        if (data.contact_person_fio === "") {
          warnings.push("Введите ФИО контактного лица");
        }
        if (data.contact_person_fio.length < 3) {
          warnings.push("Введите корректные ФИО");
        }
        if (data.contact_person_phone === "") {
          warnings.push("Укажите номер контактного лица");
        }
        if (data.contact_person_phone[0] !== "+") {
          warnings.push(
            'Номер телефона должен быть в международном формате и начинаться с символа "+"'
          );
        }
      }
      if (
        data.photo.size === undefined ||
        (data.photo.size && data.photo.size < 1)
      ) {
        warnings.push("Загрузите фото");
      }
      if (data.leg_type === "Физическое лицо") {
        if (data.person_inn === "") {
          warnings.push("Укажите личный ИНН");
        }
      }
      if (data.leg_type === "Юридическое лицо") {
        /////////////////////
  
        if (data.company_inn === "") {
          warnings.push("Введите ИНН");
        }
        if (!/^[0-9]+$/.test(data.company_inn)) {
          warnings.push("ИНН включает только цифры");
        }
        if (data.company_kpp === "") {
          warnings.push("Введите КПП");
        }
        if (!/^[0-9]+$/.test(data.company_kpp)) {
          warnings.push("КПП включает только цифры");
        }
        if (data.company_bik === "") {
          warnings.push("Введите БИК");
        }
        if (!/^[0-9]+$/.test(data.company_bik)) {
          warnings.push("БИК включает только цифры");
        }
        if (data.company_ogrn === "") {
          warnings.push("Введите ОГРН");
        }
        if (!/^[0-9]+$/.test(data.company_ogrn)) {
          warnings.push("ОГРН включает только цифры");
        }
        if (data.company_bank_account === "") {
          warnings.push("Введите наименование банка");
        }
        if (data.company_mail_address === "") {
          warnings.push("Укажите почтовый адрес компании");
        }
        if (data.company_leg_address === "") {
          warnings.push("Укажите юридический адрес компании");
        }
      }
      // if (data.company_organization_form === "") {
      //   warnings.push("Выберите форму организации");
      // }
      // if (data.company_name === "") {
      //   warnings.push("Укажите название организации");
      // }
      // if (data.company_position === "") {
      //   warnings.push("Укажите вашу должность");
      // }
      // if (data.company_position_en === "") {
      //   warnings.push("Укажите вашу должность на английском");
      // }
      // if (data.company_sphere === "") {
      //   warnings.push("Выберите сферу деятельности компании");
      // }

      // if (data.company_inn === "") {
      //   warnings.push("Введите ИНН");
      // }
      // if (!/^[0-9]+$/.test(data.company_inn)) {
      //   warnings.push("ИНН включает только цифры");
      // }
      // if (data.company_kpp === "") {
      //   warnings.push("Введите КПП");
      // }
      // if (!/^[0-9]+$/.test(data.company_kpp)) {
      //   warnings.push("КПП включает только цифры");
      // }
      // if (data.company_bik === "") {
      //   warnings.push("Введите БИК");
      // }
      // if (!/^[0-9]+$/.test(data.company_bik)) {
      //   warnings.push("БИК включает только цифры");
      // }
      // if (data.company_ogrn === "") {
      //   warnings.push("Введите ОГРН");
      // }
      // if (!/^[0-9]+$/.test(data.company_ogrn)) {
      //   warnings.push("ОГРН включает только цифры");
      // }
      // if (data.company_bank_account === "") {
      //   warnings.push("Введите наименование банка");
      // }
      // if (data.company_mail_address === "") {
      //   warnings.push("Укажите почтовый адрес компании");
      // }
      // if (data.company_leg_address === "") {
      //   warnings.push("Укажите юридический адрес компании");
      // }

      if (!data.is_confirm_data) {
        warnings.push("Подтвердите корректность введенных данных");
      }
      if (!data.is_agree_policy) {
        warnings.push("Согласитесь с политикой конфиденциальности");
      }
      if (!data.is_agree_offer) {
        warnings.push("Согласитесь с договором оферты");
      }
    }

    return warnings.length < 1 ? true : warnings;
  }

  if (type === "free" || type === '' || type === null) {
    if (isSecondWorker) {
      if (data.last_name === "") {
        warnings.push("Введите фамилию участника");
      }
      if (data.last_name.length < 2) {
        warnings.push('Поле "Фамилия" должно быть длинее 1 символа');
      }
      if (data.last_name_en === "") {
        warnings.push("Введите фамилию участника на английском");
      }
      if (/[^A-Za-z ]/g.test(data.last_name_en)) {
        warnings.push(
          '"Фамилия на английском" не может содержать кирилицу, цифры и спецсимволы'
        );
      }
      if (data.first_name === "") {
        warnings.push("Введите имя участника");
      }
      if (data.first_name.length < 2) {
        warnings.push('Поле "Имя" должно быть длинее 2 символов');
      }
      if (data.first_name_en === "") {
        warnings.push("Введите имя участника на английском");
      }
      if (/[^A-Za-z ]/g.test(data.first_name_en)) {
        warnings.push(
          '"Имя на английском" не может содержать кирилицу, цифры и спецсимволы'
        );
      }
      if (data.country === "") {
        warnings.push("Укажите страну");
      }
      if (data.region === "") {
        warnings.push("Укажите регион");
      }
      if (data.city === "") {
        warnings.push("Укажите населенный пункт");
      }
      if (data.passport_series === "") {
        warnings.push("Укажите серию паспорта");
      }
      if (data.passport_number === "") {
        warnings.push("Укажите номер паспорта");
      }
      if (data.passport_issuer === "") {
        warnings.push("Укажите орган, выдавший паспорт");
      }
      if (data.birthday_date === "") {
        warnings.push("Укажите дату рождения");
      }
      if (data.passport_date === "") {
        warnings.push("Укажите дату выдачи паспорта");
      }
      if (data.birthday_address === "") {
        warnings.push("Укажите место рождения");
      }
      if (data.phone === "") {
        warnings.push("Укажите свой номер");
      }
      if (data.phone[0] !== "+") {
        warnings.push(
          'Номер должен быть в международном формате и начинаться с символа "+"'
        );
      }
      if (data.is_contact_via_manager === true) {
        if (data.contact_person_fio === "") {
          warnings.push("Введите ФИО контактного лица");
        }
        if (data.contact_person_fio.length < 3) {
          warnings.push("Введите корректные ФИО");
        }
        if (data.contact_person_phone === "") {
          warnings.push("Укажите номер контактного лица");
        }
        if (data.contact_person_phone[0] !== "+") {
          warnings.push(
            'Номер телефона должен быть в международном формате и начинаться с символа "+"'
          );
        }
      }
      if (
        data.photo.size === undefined ||
        (data.photo.size && data.photo.size < 1)
      ) {
        warnings.push("Загрузите фото");
      }
      if (data.company_organization_form === "") {
        warnings.push("Выберите форму организации");
      }
      if (data.company_name === "") {
        warnings.push("Укажите название организации");
      }
      if (data.company_position === "") {
        warnings.push("Укажите вашу должность");
      }
      if (data.company_position_en === "") {
        warnings.push("Укажите вашу должность на английском");
      }
      if (data.company_sphere === "") {
        warnings.push("Выберите сферу деятельности компании");
      }
      if (!data.is_confirm_data) {
        warnings.push("Подтвердите корректность введенных данных");
      }
      if (!data.is_agree_policy) {
        warnings.push("Согласитесь с политикой конфиденциальности");
      }
    } else {
      if (data.last_name === "") {
        //
        warnings.push("Введите фамилию участника");
      }
      if (data.last_name_en === "") {
        //
        warnings.push("Введите фамилию участника на английском");
      }
      if (/[^A-Za-z ]/g.test(data.last_name_en)) {
        warnings.push(
          '"Фамилия на английском" не может содержать кирилицу, цифры и спецсимволы'
        );
      }
      if (data.last_name.length < 2) {
        warnings.push('Поле "Фамилия" должно быть длинее 1 символа');
      }
      if (data.first_name === "") {
        warnings.push("Введите имя участника");
      }
      if (data.first_name.length < 2) {
        warnings.push('Поле "Имя" должно быть длинее 2 символов');
      }
      if (data.first_name_en === "") {
        warnings.push("Введите имя участника на английском");
      }
      if (/[^A-Za-z ]/g.test(data.first_name_en)) {
        warnings.push(
          '"Имя на английском" не может содержать кирилицу, цифры и спецсимволы'
        );
      }
      if (data.country === "") {
        warnings.push("Укажите страну");
      }
      if (data.region === "") {
        warnings.push("Укажите регион");
      }
      if (data.city === "") {
        warnings.push("Укажите населенный пункт");
      }
      if (data.passport_series === "") {
        warnings.push("Укажите серию паспорта");
      }
      if (data.passport_number === "") {
        warnings.push("Укажите номер паспорта");
      }
      if (data.passport_issuer === "") {
        warnings.push("Укажите орган, выдавший паспорт");
      }
      if (data.birthday_date === "") {
        warnings.push("Укажите дату рождения");
      }
      if (data.passport_date === "") {
        warnings.push("Укажите дату выдачи паспорта");
      }
      if (data.birthday_address === "") {
        warnings.push("Укажите место рождения");
      }
      if (data.phone === "") {
        warnings.push("Укажите свой номер");
      }
      if (data.phone[0] !== "+") {
        warnings.push(
          'Номер должен быть в международном формате и начинаться с символа "+"'
        );
      }
      if (data.is_contact_via_manager === true) {
        if (data.contact_person_fio === "") {
          warnings.push("Введите ФИО контактного лица");
        }
        if (data.contact_person_fio.length < 3) {
          warnings.push("Введите корректные ФИО");
        }
        if (data.contact_person_phone === "") {
          warnings.push("Укажите номер контактного лица");
        }
        if (data.contact_person_phone[0] !== "+") {
          warnings.push(
            'Номер телефона должен быть в международном формате и начинаться с символа "+"'
          );
        }
      }
      if (
        data.photo.size === undefined ||
        (data.photo.size && data.photo.size < 1)
      ) {
        warnings.push("Загрузите фото");
      }
      if (data.company_organization_form === "") {
        warnings.push("Выберите форму организации");
      }
      if (data.company_name === "") {
        warnings.push("Укажите название организации");
      }
      if (data.company_position === "") {
        warnings.push("Укажите вашу должность");
      }
      if (data.company_position_en === "") {
        warnings.push("Укажите вашу должность на английском");
      }
      if (data.company_sphere === "") {
        warnings.push("Выберите сферу деятельности компании");
      }

      if (!data.is_confirm_data) {
        warnings.push("Подтвердите корректность введенных данных");
      }
      if (!data.is_agree_policy) {
        warnings.push("Согласитесь с политикой конфиденциальности");
      }
    }

    return warnings.length < 1 ? true : warnings;
  }
};
