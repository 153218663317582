import { BASE_URL_API } from '../BASE_URL.js';
import { getRegionsAction } from '../redux/Reducers/locationsReducers.js';

const getRegions = (countryId) => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'geo/regions/' + countryId);
        const data = await response.json();

        dispatch(getRegionsAction(data));
    }
}

export default getRegions;