import { BASE_WEBSOCKET_URL } from "../BASE_URL";

let ws;

const sortArray = (array) => {
  array.sort(function(a, b){
    let dateA=new Date(a.retiredate)
    let dateB=new Date(b.retiredate)
    return dateA-dateB //сортировка по возрастающей дате
    })
    return array.reverse()
}

export const chat = (
  roomName,
  setPermissions,
  setMessagesToModerate,
  setOldMessages,
  setChatMessages
) => {
  const token = localStorage.getItem("token");
  ws = new WebSocket(
    "wss://" + BASE_WEBSOCKET_URL + "/ws/chat/" + roomName + "/?token=" + token
  );
  ws.onopen = () => {
    console.log("ws opened");
  };
  ws.onclose = () => {
    console.log("ws closed");
  };
  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);
    switch (data.type) {
      case "messages_to_moderate":
        setMessagesToModerate((prev) => [...prev, ...data.messages]);
        break;
      case "old_messages":
        setOldMessages(sortArray(data.messages));
        break;
      case "chat_message_to_moderate":
        setMessagesToModerate((prevMessages) => [...prevMessages, data]);
        break;
      case "chat_message":
        setChatMessages((prevMessages) => [...prevMessages, data]);
        break;
      case "message_managed":
        setMessagesToModerate((prev) =>
          prev.filter((item) => item.id !== data.message_id)
        );
        break;
      case "permissions":
        let permissions = {
          create: false,
          read: false,
          update: false,
          delete: false,
        };
        if (data.value.includes("C")) permissions.create = true;
        if (data.value.includes("R")) permissions.read = true;
        if (data.value.includes("U")) permissions.update = true;
        if (data.value.includes("D")) permissions.delete = true;
        setPermissions(permissions);
        break;
      default:
        return;
    }
  };
};

export const closeConnect = () => {
  ws.close()
}

export const sendMessage = (message) => {
  ws.send(
    JSON.stringify({
      type: "chat_message",
      message: message,
    })
  );
};

export const aproveMessage = (messageId) => {
  ws.send(
    JSON.stringify({
      type: "approve_message",
      message_id: messageId,
    })
  );
};

export const deniedMessage = (messageId) => {
  ws.send(
    JSON.stringify({
      type: "deny_message",
      message_id: messageId,
    })
  );
};



// {isCommentator ? (
//   <div className={styles.nonCommentatorNote}>
//     <p>
//       <Link to={"/login"}>Войдите</Link> или{" "}
//       <Link to={"/register"}>зарегистрируйтесь</Link>, чтобы
//       читать и оставлять комментарии
//     </p>
//   </div>
// ) : (
//   <div className={styles.scrolledList}>
//     <div className={styles.commentatorNote}>
//       {oldMessages &&
//         oldMessages.length > 0 &&
//         oldMessages.map((item, index) => (
//           <Message key={index} item={item} />
//         ))}
//       {chatMessages &&
//         chatMessages.length > 0 &&
//         chatMessages.map((item, index) => (
//           <ChatMessage key={index} item={item} />
//         ))}
//       {messagesToModerate &&
//         messagesToModerate.length > 0 &&
//         messagesToModerate.map((item, index) => (
//           <MessageToModerate key={index} item={item} />
//         ))}
//       {isSuccess && <SuccessfulSending />}
//       {/* {chatMessageToModerate &&
//             chatMessageToModerate.length > 0 &&
//             chatMessageToModerate.map((item, index) => (
//               <ChatMessageToModerate key={index} item={item} />
//             ))} */}
//     </div>
//     <div className={styles.createMessages}>
//       <textarea
//         type="text"
//         value={message}
//         onChange={(e) => messageChangeHandler(e)}
//         placeholder="Напишите сообщение"
//         onFocus={() => setInputFocused(true)}
//         onBlur={() => setInputFocused(false)}
//       ></textarea>
//       <p style={maxLength ? { color: "red" } : {}}>
//         {message.length} / 2000
//       </p>
//       <img src={SendIcon} alt="" onClick={sendAndClear} />
//     </div>
//   </div>
// )}