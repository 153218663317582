import { BASE_URL_API } from '../BASE_URL.js';
import { getStreamsAction, getStreamsByIDAction } from '../redux/Reducers/steamsReducer.js';

export const getStreams = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'translations/');
        const data = await response.json();

        dispatch(getStreamsAction(data));
    }
}

export const getStreamsByID = (id) => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'translations/' + id);
        const data = await response.json();

        dispatch(getStreamsByIDAction(data));
    }
}

