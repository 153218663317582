const initialState = {
    program: [],
  };
  
  const SET_PROGRAM = 'SET_PROGRAM';
  
  export const programReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PROGRAM:
        return {
            program: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const setProgramAction = (payload) => ({ type: SET_PROGRAM, payload});