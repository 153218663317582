import React from "react";
import moment from "moment/moment";
import "moment/locale/ru";

import { ReactComponent as Clock } from "../../../../../image/icons/clock.svg";

import styles from "../../MainPage.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import { getNewsById } from "../../../../../functions/getNews";
import { useNavigate } from 'react-router-dom';
import { translitUrls } from '../../../../../functions/translitUrls';
import { Link } from 'react-router-dom';

const LastNewsItem = ({ item, width, }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentLang = useSelector((state) => state.lang.currentLang);
  
  const formateDate = (date) => {
    const localDate = new Date(date)
    moment.locale(currentLang === 'ru' ? 'ru' : 'en')
    const getmoment = moment(localDate, 'MMM').format()
    const dateTo = moment(getmoment).format('D MMM YYYY / hh:mm')
    return dateTo
  }

  const newsItemClickHandler = () => {
    dispatch(getNewsById(item.slug, navigate, 1));
  }

  return (
    <Link to={"/news/1/" + item.slug} className={styles.LastNewsItem} style={{width: width}} >
      {/* <Link to={"/news/1/" + slug}> */}
        <p>{ currentLang === 'ru' ? item.title : item.title_en }</p>
        <div>
          <Clock width={17} height={17} fill={"#696969"} viewBox="0 0 18 18" />
          <span>{ formateDate(item.publish_time) }</span>
        </div>
      {/* </Link> */}
    </Link>
  );
};

export default LastNewsItem;
