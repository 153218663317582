import React from 'react';
import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate';
import ContentContainer from '../../elements/Container/ContentContainer';
import TextInput from '../../elements/TextInput/TextInput';
import { useState } from 'react';
import Button from '../../elements/Button/Button';
import resetPassword from '../../../functions/resetPassword';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [errorList, setErrorList] = useState([])
  const [isLoad, setIsLoad] = useState(false);
  const [isSuccess, setSuccess] = useState(false)

  const navigate = useNavigate()

  const setNewEmailAddres = (data) => setEmail(data);


  const sendResetQuery = () => {
    setErrorList([])
    setIsLoad(true)
    if (email === '') {
        setErrorList(['Введите адрес электронной почты'])
        setIsLoad(false)
        return;
    } else {
        resetPassword({email: email}, setErrorList, setIsLoad, setSuccess)
    }
  }

  useEffect(() => {
    if (!isSuccess) return
    setTimeout(() => {
        navigate('/login')
    }, 5000)
  }, [isSuccess])

  return (
    <PageTemplate>
        <Container>
            <ContentContainer>
                <h3>{isSuccess ? 'Заявка успешно отправлена на указанную почту' : 'Восстановить пароль'}</h3>
                <p>{isSuccess ? 'Проверьте почтовый ящик. Вы будете перенаправлены на страницу входа через 5 секунд...' : 'Чтобы восстановить пароль, укажите электронную почту, на которую зарегистрирован аккаунт. На нее будет отправлена инструкция по восстановлению пароля.'}</p>
                {!isSuccess && <TextInput labelText={"Электронная почта"} isValue={email} onChange={setNewEmailAddres} />}
                {errorList.length > 0 && errorList.map((item, index) => <p key={index} style={{color: 'red'}}>{item}</p>)}
                {!isSuccess && <Button disabled={isLoad} onClick={sendResetQuery}>{isLoad ? 'Отправляем запрос...' : 'Восстановить пароль'}</Button>}
            </ContentContainer>
        </Container>
    </PageTemplate>
  )
}

export default ResetPassword