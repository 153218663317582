import React, { useEffect, useRef } from 'react';
import Title from '../../../../elements/Title/Title';

import styles from '../../MainPage.module.scss';
import ReadMoreLink from '../../../../elements/ReadMoreLink/ReadMoreLink';
import { useSelector } from 'react-redux';
import LastMediaItem from './LastMediaItem';
import PhotosSlider from '../../../GalleryPage/Photos/PhotosSlider';
import PhotosItem from '../../../GalleryPage/Photos/PhotosItem';
import { globalTranslate } from '../../../../../functions/languages';

const LastMedia = () => {
  // const albums = useSelector(state => state.albums.albumsList);
  const firstAlbums = useSelector(state => state.photos.firstAlbums)
  const currentLang = useSelector((state) => state.lang.currentLang);
  const containerWidth = useRef()
  return (

    <>
    <h3 ref={containerWidth} style={{marginBottom: 40}}>{globalTranslate(currentLang, 'Галерея')}</h3>
        <div className={styles.head}>
          {/* <h4>Последние фотографии</h4> */}
        </div>
        {window.innerWidth > 768 ? (
          <div className='photo'>
          {firstAlbums && firstAlbums.length > 0 && (<PhotosSlider items={firstAlbums} containerWidth={containerWidth} />)} 
        </div>
        ) : (
          firstAlbums && firstAlbums.length > 0 && firstAlbums.map((item, index) => index < 4 && <PhotosItem key={index} item={item} />)
        )}
        <div style={{marginBottom: 40}}>
          <ReadMoreLink text={globalTranslate(currentLang, 'Галерея')} link={'/gallery'} />
        </div>
        </>
    // <div className={styles.LastMediaWrapper}>
    //     <Title text={'Галерея'}     />
    //     <div className={styles.LastMediaItem__container}>
    //       {albums.map((item, index) => <LastMediaItem key={index} img_url={item.photos[0].file} text={item.title} url={"#"} />)}
    //     </div>
    //     <ReadMoreLink text={'Галерея'} link={'/main'} />
    // </div>
  )
}

export default LastMedia