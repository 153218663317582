import { BASE_URL_API } from '../BASE_URL.js';
import { setSpheresAction } from '../redux/Reducers/companySpheresReducer.js';

const getCompanySphere = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'registrations/company-spheres/');
        const data = await response.json();

        dispatch(setSpheresAction(data));
    }
}

export default getCompanySphere;