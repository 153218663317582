import React, { useEffect, useState } from "react";
import getStreams, { getStreamsByID } from "../../../functions/getStreams";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageTemplate from "../../PageTemplate/PageTemplate";
import Container from "../../elements/Container/Container";

import styles from "./StreamsPage.module.scss";
import { chat, closeConnect, sendMessage } from "../../../functions/chatSockets";
import ReadMoreLink from "../../elements/ReadMoreLink/ReadMoreLink";
import Message from "./Messages/Message";
import MessageToModerate from "./Messages/MessageToModerate";
import ChatMessageToModerate from "./Messages/ChatMessageToModerate";
import ChatMessage from "./Messages/ChatMessage";
import SuccessfulSending from "./Messages/SuccessfulSending";
import SendIcon from "../../../image/icons/send.svg";
import Description from "./Description";
import { globalTranslate } from "../../../functions/languages";

const SingleStreamPage = () => {
  const [currentStream, setCurrentStream] = useState({ title: "", id: 4 });
  const [isSuccess, setSuccess] = useState(false);
  const [margin, setMargin] = useState(80);
  const [videoHeight, setVideoHeight] = useState(300);
  const [isCommentator, setComentator] = useState(false);
  const [isRegister, setRegister] = useState(false)
  const [isInputFocused, setInputFocused] = useState(false);
  const [permissions, setPermissions] = useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  });
  const [maxLength, setMaxLength] = useState(false);
  const [message, setMessage] = useState("");
  const [oldMessages, setOldMessages] = useState([]);
  const [isNoneLocalisation, setNoneLocalisation] = useState(false)
  const [messagesToModerate, setMessagesToModerate] = useState([]);
  const [chatMessageToModerate, setChatMessageToModerate] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const dispatch = useDispatch();

  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
    if (e.target.value.length > 2000) {
      setMaxLength(true);
    } else {
      setMaxLength(false);
    }
  };

  const resize = () => {
    if (window.innerWidth <= 1000) setMargin(30)
    if (window.innerWidth > 1000) setMargin(80)
  }

  useEffect(() => {
    resize()
    window.addEventListener('resize', resize)

    return () => window.removeEventListener('resize', resize)
  }, [])

  const params = useParams();
  const currentStreamRedux = useSelector((state) => state.streams.singleStream);
  const user = useSelector((state) => state.isAuth.isAuth);
  const hasRegisterAnket = useSelector((state) => state.registrations.registrationCounter);
  const currentLang = useSelector((state) => state.lang.currentLang);


  useEffect(() => {
    if (user) setComentator(true);
  }, [user]);

  useEffect(() => {
    if (hasRegisterAnket > 0) setRegister(true)
    else setRegister(false)
  }, [hasRegisterAnket]);

  useEffect(() => {
    if (!currentStreamRedux) return;
    setCurrentStream(currentStreamRedux);
  }, [currentStreamRedux]);

  useEffect(() => {
    dispatch(getStreamsByID(params.id));
  }, [dispatch]);

  const createMarkup = (text) => {
    return { __html: text };
  };

  const container = document.getElementById("container");

  const resizeforVideo = () => {
    const timer = setTimeout(() => {
      const singleVideo = document.getElementById("singleVideo");
      if (!singleVideo) return;
      setVideoHeight(singleVideo.clientWidth / 1.27 / 1.77);
    }, 1000);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    resizeforVideo()
  }, [margin, currentLang]);

  useEffect(() => {
    resizeforVideo()
    window.addEventListener('resize', resizeforVideo);

    return () => window.removeEventListener('resize', resizeforVideo)
  }, [])

  useEffect(() => {
    if (!currentStream) return;
    if (currentLang === 'en' && currentStream.link_en === "") {
      setNoneLocalisation(true)
    }
    if (currentLang === 'ru') setNoneLocalisation(false)
  }, [currentStream, currentLang])

  useEffect(() => {
    chat(
      params.id,
      setPermissions,
      setMessagesToModerate,
      setOldMessages,
      setChatMessages,
    );

    return () => closeConnect()
  }, []);

  useEffect(() => {
    if (!container) return;
    setMargin(container.offsetLeft);
  }, [container]);

  const navigate = useNavigate()

  useEffect(() => {
    if (!isNoneLocalisation) return;
    const timer = setTimeout(() => {
      navigate('/live')
    }, 5000)

    return () => clearTimeout(timer);
  }, [isNoneLocalisation])

  useEffect(() => {
    if (!isSuccess) return;
    let timerSuccess = setTimeout(() => {
      setSuccess(false);
    }, 3000);

    return () => clearTimeout(timerSuccess);
  }, [isSuccess]);

  const sendAndClear = () => {
    if (message === "") return;
    if (maxLength) return;
    sendMessage(message);
    setMessage("");
    setSuccess(true);
  };

  const frame = document.querySelector('iframe')

  
  useEffect(() => {
    if (!frame) return
    frame.height = videoHeight;
  }, [videoHeight, frame])
  

  return (
    <PageTemplate>
      {currentStream ? (
        !isNoneLocalisation ? 
        <div
          className={styles.singleStreamContainer}
          style={{ marginLeft: margin, marginRight: margin + 20 }}
          id="singleVideo"
        >
          <div className={styles.head}>
            <h3>{currentLang === 'ru' ? currentStream.title : currentStream.title_en}</h3>
            <ReadMoreLink link={"/live"} text={globalTranslate(currentLang, "Назад")} />
          </div>
          <div className={styles.singleStreamWrap}>
            <div className={styles.videoWrap} style={{ minHeight: videoHeight }}>
              <div
                className={styles.singleVideo}
                style={{ minHeight: videoHeight }}
                dangerouslySetInnerHTML={createMarkup(currentLang === 'ru' ? currentStream.link : currentStream.link_en)}
              ></div>
            </div>
            
            {margin === 30 && <h4 style={{marginBottom: 0}}>{globalTranslate(currentLang, "Комментарии")}</h4>}
            <div
              className={styles.commentsWrap}
              style={margin > 30 ? { height: videoHeight } : {minHeight: 500}}
            >
              {isCommentator ? (!isRegister ? (
                <div className={styles.nonCommentatorNote}>
                <p style={{textAlign: 'center'}}>{globalTranslate(currentLang, "Заполните анкету в ")}<Link to={'/cabinet/my_profile'}>{globalTranslate(currentLang, "Личном кабинете")}</Link>{globalTranslate(currentLang, ", чтобы стать участником чата")}</p>
              </div>
                
                ) : (
                <div className={styles.scrolledList} style={margin > 30 ? {  } : {height: 500}}>
                <div className={styles.commentatorNote}>
                  {oldMessages &&
                    oldMessages.length > 0 &&
                    oldMessages.map((item, index) => (
                      <Message key={index} item={item} />
                    ))}
                  {chatMessages &&
                    chatMessages.length > 0 &&
                    chatMessages.map((item, index) => (
                      <ChatMessage key={index} item={item} />
                    ))}
                  {messagesToModerate &&
                    messagesToModerate.length > 0 &&
                    messagesToModerate.map((item, index) => (
                      <MessageToModerate key={index} item={item} />
                    ))}
                  {isSuccess && <SuccessfulSending />}
                  {/* {chatMessageToModerate &&
                        chatMessageToModerate.length > 0 &&
                        chatMessageToModerate.map((item, index) => (
                          <ChatMessageToModerate key={index} item={item} />
                        ))} */}
                </div>
                <div className={styles.createMessages}>
                  <textarea
                    type="text"
                    value={message}
                    onChange={(e) => messageChangeHandler(e)}
                    placeholder={globalTranslate(currentLang, "Напишите сообщение")}
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                  ></textarea>
                  <p style={maxLength ? { color: "red" } : {}}>
                    {message.length} / 2000
                  </p>
                  <img src={SendIcon} alt="" onClick={sendAndClear} />
                </div>
              </div>
              )) : (
                <div className={styles.nonCommentatorNote}>
                <p>
                  <Link to={"/login"}>{globalTranslate(currentLang, "Войдите")}</Link> {globalTranslate(currentLang, "или")}{" "}
                  <Link to={"/registration"}>{globalTranslate(currentLang, "зарегистрируйтесь")}</Link>{globalTranslate(currentLang, ", чтобы читать и оставлять комментарии")}
                </p>
              </div>
              )}

              
              
            </div>
          </div>
          {currentLang === 'ru' && currentStream && currentStream.programs && currentStream.programs.length > 0 && (
            <div className={styles.descriptionContainer}>
                <h3>Программа трансляции</h3>
                {currentStream.programs.map((item, index) => <Description key={index} item={item} />)}
            </div>
          )}
        </div>
        : (
          <Container>
          <h3>We are sorry, broadcasting of this event is currently unavailable in english. You will be redirected to the page with available broadcastings with english interpretation in 5 seconds</h3>
        </Container>
        )
      ) : (
        <Container>
          <h2>Трансляция недоступна</h2>
        </Container>
      )}
    </PageTemplate>
  );
};

export default SingleStreamPage;
