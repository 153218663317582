import React, { useState, useEffect, useRef } from "react";

import styles from "./UserDataForms.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setAgreementsRegDataAction } from "../../../../redux/Reducers/regInfoReducers";
import { Link } from "react-router-dom";

import activeCheckbox from '../../../../image/icons/active-checkbox.svg';
import disactiveCheckbox from '../../../../image/icons/disactive-checkbox.svg';
import errorCheckbox from '../../../../image/icons/error-checkbox.svg';

import { globalTranslate } from "../../../../functions/languages";

const AgreementsData = ({ isPayed, errorList, clearError }) => {
  const dispatch = useDispatch();

  const offer_pdf = useSelector(state => state.regDocs.offer_pdf)

  const currentLang = useSelector((state) => state.lang.currentLang);


  const [agreements, setAgreements] = useState({
    is_agree_info: false,
    is_confirm_data: false,
    is_agree_policy: false,
    is_agree_offer: false,
  });

  const countRegistration = useSelector(
    (state) => state.registrations.registrationCounter
  );

  const promoRef = useRef()
  const confirmRef = useRef()
  const policyRef = useRef()
  const offerRef = useRef()

  const setPromo = (isCheck) =>
    setAgreements({ ...agreements, is_agree_info: isCheck });
  const setCorrect = (isCheck) => {
    setAgreements({ ...agreements, is_confirm_data: isCheck });
    clearError('Подтвердите корректность введенных данных');
  }
  const setConfidence = (isCheck) => {
    setAgreements({ ...agreements, is_agree_policy: isCheck });
    clearError('Согласитесь с политикой конфиденциальности');
  }
  const setOffer = (isCheck) => {
    setAgreements({ ...agreements, is_agree_offer: isCheck });
    clearError('Согласитесь с договором оферты')
  }
  useEffect(() => {
    dispatch(setAgreementsRegDataAction(agreements));
  }, [agreements]);

  return (
    <div className={styles.agreementsWrapper}>
      {countRegistration === 0 && (
        <div>
          <div className={styles.fakeInput} onClick={() => promoRef.current.click()}>
            {agreements.is_agree_info ? <img src={activeCheckbox} alt={'check'} /> : <img src={disactiveCheckbox} alt={'false'} />}
          </div>
          <input
            type="checkbox"
            name=""
            id="promo"
            value={agreements.is_agree_info}
            onChange={(event) => setPromo(event.target.checked)}
          />
          <label htmlFor="promo" ref={promoRef}>
          {globalTranslate(currentLang, "Я согласен получать уведомления и промо материалы (на электронную почту, по смс, в мессенджерах).")}
            
          </label>
        </div>
      )}

      <div>
      <div className={styles.fakeInput} onClick={() => confirmRef.current.click()}>
            {agreements.is_confirm_data ? <img src={activeCheckbox} alt={'check'}  /> : errorList && errorList.includes('Подтвердите корректность введенных данных') ? <img src={errorCheckbox} alt={'false'} /> : <img src={disactiveCheckbox} alt={'false'} />}
          </div>
        <input
          type="checkbox"
          name=""
          id="correct"
          onChange={(event) => setCorrect(event.target.checked)}
        />
        <label htmlFor="correct" ref={confirmRef}>
        {globalTranslate(currentLang, "Я подтверждаю правильность введенных данных.")}
        </label>
      </div>
      <div>
      <div className={styles.fakeInput} onClick={() => policyRef.current.click()}>
            {agreements.is_agree_policy ? <img src={activeCheckbox} alt={'check'}  /> : errorList && errorList.includes('Согласитесь с политикой конфиденциальности') ? <img src={errorCheckbox} alt={'false'} /> : <img src={disactiveCheckbox} alt={'false'} />}
          </div>
        <input
          type="checkbox"
          name=""
          id="confidence"
          onChange={(event) => setConfidence(event.target.checked)}
        />
        <label htmlFor="confidence" ref={policyRef}>
          {globalTranslate(currentLang, "Я принимаю")} <a href={'/politika-konfidencialnosti'} target="_blank">{globalTranslate(currentLang, "политику конфиденциальности")}</a>.
        </label>
      </div>
      {isPayed && (
        <div>
          <div className={styles.fakeInput} onClick={() => offerRef.current.click()}>
            {agreements.is_agree_offer ? <img src={activeCheckbox} alt={'check'}  /> : errorList && errorList.includes('Согласитесь с договором оферты') ? <img src={errorCheckbox} alt={'false'} /> : <img src={disactiveCheckbox} alt={'false'} />}
          </div>
          <input
            type="checkbox"
            name=""
            id="offer"
            onChange={(event) => setOffer(event.target.checked)}
          />
          <label htmlFor="offer" ref={offerRef}>{globalTranslate(currentLang, 'Я согласен с ')}<a href={offer_pdf} target="_blank">{globalTranslate(currentLang, 'договором оферты')}</a>.</label>
        </div>
      )}
    </div>
  );
};

export default AgreementsData;
