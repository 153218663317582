import React, { useState } from 'react';
import Button from '../Button/Button';
import TextInput from '../TextInput/TextInput';
import styles from './PofileDeleteModal.module.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getCompanyRegistrations } from '../../../functions/getCompanyRegistrations';
import deleteUser from '../../../functions/deleteUser';
import { useSelector } from 'react-redux';
import { globalTranslate } from '../../../functions/languages';

const PofileDeleteModal = ({ cancelAction, successAction, onChange, data }) => {
  const [errorList, setErrorList] = useState()
  const [isLoadNewUsers, setLoadNewUsers] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [deletedUserData, setDeletedUserData] = useState({
    last_name: '',
    first_name: '',
  });
  const currentLang = useSelector(state => state.lang.currentLang)

  const setLastName = (data) => setDeletedUserData({...deletedUserData, last_name: data})
  const setFirstName = (data) => setDeletedUserData({...deletedUserData, first_name: data})

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoadNewUsers) return;
    getCompanyRegistrations(dispatch);
    setLoadNewUsers(false)
    cancelAction()
  }, [isLoadNewUsers])

  const deleteUserLocal = () => {
    setIsLoad(true)
    const errors = []
    if (deletedUserData.last_name !== data.last_name || deletedUserData.last_name === '') {
        errors.push('Введенная фамилия не совпадает с фамилией участника')
    }
    if (deletedUserData.first_name !== data.first_name || deletedUserData.first_name === '') {
        errors.push('Введенное имя не совпадает с именем участника')
    }
    if (errors.length > 0) {
        setErrorList(errors);
        setIsLoad(false)
        return;
    } else {
        deleteUser(data.id, setIsLoad, setLoadNewUsers)
    }
  }

  return (
    <div className={styles.modalWrapper}>
      <div style={isLoad ? {opacity: 0.5} : {}} className={styles.modalContainer}>
        <p>{globalTranslate(currentLang, "Вы собираетесь")} <span style={{color: '#ED1941', fontWeight: 600}}>{globalTranslate(currentLang, "удалить пользователя")}</span>. {globalTranslate(currentLang, "Это действие нельзя будет отменить.")}</p>
        <p> {globalTranslate(currentLang, "Если вы ошиблись в личных или корпоративных данных участника, вы можете поменять их, нажав на фотографию человека.")}</p>
        <p>{globalTranslate(currentLang, "Для подтверждения удаления введите фамилию и имя участника.")}</p>
        <div className={styles.errorsBlock}>
            {errorList && errorList.map((item, index) => <p key={index}>{item}</p>)}
        </div>
        <TextInput labelText={'Фамилия'} onChange={setLastName}/>
        <TextInput labelText={'Имя'} onChange={setFirstName}/>
        <div className={styles.buttonGroup}>
            <div style={{marginRight: 13}}>
              <Button disabled={isLoad} onClick={cancelAction}>{globalTranslate(currentLang, "Вернуться")}</Button>
            </div>
            <Button disabled={isLoad} backColor={'red'} onClick={deleteUserLocal}>{globalTranslate(currentLang, "Удалить")}</Button>
        </div>
      </div>
    </div>
  )
}

export default PofileDeleteModal;
