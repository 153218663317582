const initialState = {
    id: 0,
    email: '',
    is_manager: false,
  };
  
  const SET_USER = 'SET_USER';
  
  export const currentUserReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_USER:
        return {
            id: action.payload.id,
            email: action.payload.email,
            is_manager: action.payload.is_manager,
        };
      default:
        return state;
    }
  };
  
  export const setCurrentUserAction = (payload) => ({ type: SET_USER, payload});