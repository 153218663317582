import { BASE_URL_API } from '../BASE_URL.js';
import { setPartnersAction } from '../redux/Reducers/partnersReducer.js';

const getPartners = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'partners/usual/');
        const data = await response.json();

        dispatch(setPartnersAction(data));
    }
}

export default getPartners;