import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "../../elements/Box/Box";
import OnePersoneRegPage from "./OnePersoneRegPage";
import Button from "../../elements/Button/Button";

import testFile from "../../../functions/testFile";
import { useNavigate, useLocation } from "react-router-dom";
import ChooseTypeRegCompany from "./ChooseTypeRegCompany";

import styles from "./RegPage.module.scss";
import { validateCompanyReg } from "../../../functions/validate";
import { setNewTypeOfRegistrationAction } from "../../../redux/Reducers/regInfoReducers";
import PageTemplate from "../../PageTemplate/PageTemplate";
import Container from "../../elements/Container/Container";
import CabinetNav from "../../elements/CabinetNav/CabinetNav";
import { logout } from '../../../functions/logout';
import { globalTranslate } from "../../../functions/languages";

const CompanyRegPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorList, setErrorList] = useState();
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(true)

  const navigation = useNavigate();
  const dispatch = useDispatch();
  const regData = useSelector((state) => state.regInfo);
  const user = useSelector((state) => state.user);
  const listRegistrationLength = useSelector(
    (state) => state.registrations.registrationCounter
  );

  const currentLang = useSelector((state) => state.lang.currentLang);

  const managerCompanyInfo = useSelector(
    (state) => state.registrations.currentRegistration
  );

  const currentTypeOfRegistration = useSelector(
    (state) => state.regInfo.registration_type
  );

  const typeOfRegistration = useSelector(state => state.registrationType.types)

  useEffect(() => {
    if (!typeOfRegistration || typeOfRegistration.length < 1) return;
    if (!typeOfRegistration.reg_allow_group) {
      setIsRegistrationOpen(false)
    }
  }, [typeOfRegistration]);

  const defaultType = useSelector(
    (state) => state.registrations.currentRegistration
  );
  

  useEffect(() => {
    if (!defaultType || Object.keys(defaultType).length < 1) return;
    dispatch(setNewTypeOfRegistrationAction(defaultType[0].registration_type));
  }, [defaultType]);

  const navigate = useNavigate();

  useEffect(() => {
    if (managerCompanyInfo && managerCompanyInfo.length > 0) {
      setDefaultCompanyInfo(managerCompanyInfo[managerCompanyInfo.length - 1]);
    }
  }, [managerCompanyInfo]);

  const location = useLocation();
  // const apllyRegAndAddOneMorePersone = async () => {
  //   setIsLoading(true);
  //   const validateResult = validateCompanyReg(
  //     regData,
  //     currentTypeOfRegistration,
  //     listRegistrationLength > 0
  //   );
  //   if (validateResult === true) {
  //     const navigateURL = "/success";
  //     await testFile(regData, dispatch, navigate, navigateURL, setIsLoading);
  //   } else {
  //     setErrorList(validateResult);
  //   }
  //   setIsLoading(false);
  // };

  const logoutHanler = async () => {
    await logout(dispatch, navigate);
  };

  const apllyRegAndCloseRegistration = async () => {
    setIsLoading(true);

    const validateResult = validateCompanyReg(
      regData,
      currentTypeOfRegistration,
      listRegistrationLength > 0
    );

    if (validateResult === true) {
      const navigateURL = "/cabinet/my_registrations";
      if (regData.registration_type === null || regData.registration_type === '') regData.registration_type = 'free';
      await testFile(regData, dispatch, navigation, navigateURL, setIsLoading, setErrorList);
    } else {
      setErrorList(validateResult);
    }
    setIsLoading(false);
  };

  const [defaultCompanyInfo, setDefaultCompanyInfo] = useState({
    company_organization_form: 1,
    company_name: "",
    company_sphere: "",
    company_inn: "",
    company_kpp: "",
    company_bik: "",
    company_ogrn: "",
    company_bank_account: "",
    company_mail_address: "",
    company_leg_address: "",
    contact_person_fio: "",
    contact_person_phone: "",
  });

  const clearError = (error) => {
    if (!errorList) return;
    let newErrorList = []
    errorList.map(item => {
      if (!error.includes(item)) {
        newErrorList.push(item)
      }
    })
    // const newErrorList = errorList.filter((item) => item !== error);
    setErrorList(newErrorList);
  };

  return (
    <PageTemplate>
      <Container>
        {isRegistrationOpen ? (
          <div>
            {listRegistrationLength > 0 && isRegistrationOpen && <CabinetNav />}
        
        <>
          {listRegistrationLength === 0 ? (
            <>
              <h3 style={{fontSize: 40}}>{globalTranslate(currentLang, 'Групповая регистрация')}</h3>

              <h3 className={styles.helloUserName}>{globalTranslate(currentLang, 'Добро пожаловать в личный кабинет')}</h3>
              <p style={{maxWidth: 700}}>
                {globalTranslate(currentLang, 'Вы создали Личный кабинет, как менеджер компании. Сначала введите свои учетные данные. Ваш почтовый ящик будет являться приоритетным. На него будут отправляться уведомления и важные сообщения. Данные компании, указанные Вами, будут автоматически применены ко всем сотрудникам вашей компании.')}
              </p>
              <p style={{maxWidth: 700}}>
                {" "}
                {globalTranslate(currentLang, 'Чтобы зарегистрировать сотрудников компании, заполните поля ниже и нажмите "Зарегистрировать и завершить", а затем создайте новую анкету.')}
              </p>
              <p style={{maxWidth: 700}}>
                {" "}
                {globalTranslate(currentLang, 'Когда администартор подтвердит вашу заявку, вы получите письмо на указанный почтовый ящик.')}
              </p>
              <OnePersoneRegPage
                clearError={clearError}
                errorList={errorList}
                isPayed={currentTypeOfRegistration === "paid"}
              />
              <ul className={styles.errorListWrapper}>
                {errorList &&
                  errorList.map((item, index) => (
                    <li key={index} className={styles.error}>
                      {item}
                    </li>
                  ))}
              </ul>
              <div style={{ marginTop: "60px", marginBottom: "60px" }}>
                {/* <div style={{ marginBottom: "20px" }}>
                  <Button
                    disabled={isLoading}
                    onClick={apllyRegAndAddOneMorePersone}
                  >
                    {isLoading
                      ? "Отправляем запрос"
                      : "Отправить и зарегистрировать другого сотрудника"}
                  </Button>
                </div> */}
                <Box>
                  <Button
                    disabled={isLoading}
                    onClick={apllyRegAndCloseRegistration}
                  >
                    {isLoading
                      ? globalTranslate(currentLang, "Отправляем запрос")
                      : globalTranslate(currentLang, "Отправить и завершить регистрацию")}
                  </Button>
                </Box>

                <div style={{marginTop: 20}}>
                  <Button onClick={logoutHanler}>{globalTranslate(currentLang, "Выйти")}</Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <h3>{globalTranslate(currentLang, 'Добро пожаловать в личный кабинет')}</h3>
              <p>
                {globalTranslate(currentLang, "Вы уже зарегистрированы, как менеджер компании. Ваш почтовый ящик будет являться приоритетным. На него будут отправляться уведомления и важные сообщения. Данные компании, указанные Вами, будут автоматически применены ко всем сотрудникам вашей компании.")}
              </p>
              <h4 style={{fontFamily: '"Gotham Pro Bold", sans-serif'}}>
                {globalTranslate(currentLang, "Вы уже успешно зарегистрировали ")}{listRegistrationLength}{" "}
                {globalTranslate(currentLang, "человек.")}
              </h4>
              <p>
              {globalTranslate(currentLang, 'Чтобы зарегистрировать сотрудников компании, заполните поля ниже и нажмите "Зарегистрировать и завершить", а затем создайте новую анкету.')}
              </p>
              <p>
                {" "}
                {globalTranslate(currentLang, 'Когда администартор подтвердит вашу заявку, вы получите письмо на указанный почтовый ящик.')}
              </p>
              <OnePersoneRegPage
                clearError={clearError}
                errorList={errorList}
                isPayed={currentTypeOfRegistration === "paid"}
                isValue={defaultCompanyInfo}
                header={"Персональные данные сотрудника"}
              />
              <ul className={styles.errorListWrapper}>
                {errorList &&
                  errorList.map((item, index) => (
                    <li key={index} className={styles.error}>
                      {item}
                    </li>
                  ))}
              </ul>
              <div style={{ marginTop: "60px", marginBottom: "60px" }}>
                {/* <div style={{ marginBottom: "20px" }}>
                  <Button
                    disabled={isLoading}
                    onClick={apllyRegAndAddOneMorePersone}
                  >
                    {isLoading
                      ? "Отправляем запрос"
                      : "Отправить и зарегистрировать другого сотрудника"}
                  </Button>
                </div> */}
                <Box>
                  <Button
                    disabled={isLoading}
                    onClick={apllyRegAndCloseRegistration}
                  >
                    {isLoading
                      ? globalTranslate(currentLang, "Отправляем запрос")
                      : globalTranslate(currentLang, "Отправить и завершить регистрацию")}
                  </Button>
                </Box>
                <div style={{marginTop: 20}}>
                  <Button onClick={logoutHanler}>{globalTranslate(currentLang, "Выйти")}</Button>
                </div>
              </div>
            </>
          )}
        </>
          </div>
        ) : (
          <>
            <p>
              В данный момент регистрация закрыта. Вы не сможете создать личный
              кабинет.
            </p>
            <div style={{ marginTop: 0, marginBottom: 20 }}>
              <Button onClick={logoutHanler}>Выйти</Button>
            </div>
          </>
        )}
      </Container>
    </PageTemplate>
  );
};

export default CompanyRegPage;
