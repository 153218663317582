import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import styles from './Container.module.scss';

const Container = ({ children, height }) => {
  const [paddingRight, setPaddingRight] = useState(306);
  const [paddingLeft, setPaddingLeft] = useState(118);

  const { pathname } = useLocation();
    

  useEffect(() => {
    if (pathname === '/forum_program') {
      setPaddingRight(50)
      return
    };
  }, []);

  const resize = () => {
    if (pathname === '/forum_program') return
    window.innerWidth > 1000 ? setPaddingRight(document.getElementById('sidebar').clientWidth) : setPaddingRight(document.getElementById('sidebar').clientWidth)
  }

  

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])


  



  return <div id="container" className={styles.container} style={height ? {height: '100%', marginLeft: 90, marginRight: paddingRight + 10} : {marginLeft: 90 - 10, marginRight: paddingRight + 10}}>{children}</div>;
};

export default Container;
