import React, { useEffect, useState } from "react";
import styles from "./StreamsPage.module.scss";

import plus from "../../../image/icons/blue_plus.svg";

const Description = ({ item }) => {
  const [isShowDescription, setShowDescription] = useState(false);
  const [isOnAir, setOnAir] = useState(false);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    if (!item.partners) return;
    const array = item.partners.split("\n");
    setPartners(array);

    const start = Date.parse(item.start_datetime);
    const end = Date.parse(item.end_datetime);
    const now = Date.now();

    if (now <= end && now >= start) {
      setOnAir(true);
      setShowDescription(true);
    } else {
      setOnAir(false);
    }
  }, [item]);

  const dateToHours = (string) => {
    const date = new Date(string);

    let hours =
      date.getHours().toString().length === 1
        ? "0" + date.getHours()
        : date.getHours();
    let minutes =
      date.getMinutes().toString().length === 1
        ? "0" + date.getMinutes()
        : date.getMinutes();

    return hours + ":" + minutes;
  };

  const createMarkup = (text) => {
    return { __html: text };
  };

  return (
    <div className={styles.description_wrap}>
      <div
        className={styles.description_head}
        onClick={() => setShowDescription(!isShowDescription)}
      >
        <div className={isOnAir ? styles.activeStatus : styles.status}></div>
        <p>{item.track}</p>
        <img
          src={plus}
          alt=""
          style={isShowDescription ? { transform: "rotate(45deg)" } : {}}
        />
      </div>
      {isShowDescription && (
        <div className={styles.description_body}>
          <p className={styles.title}>{item.title}</p>
          <div className={styles.text_group}>
            <strong>Время трансляции:</strong>
            <span>
              {dateToHours(item.start_datetime)} -{" "}
              {dateToHours(item.end_datetime)}
            </span>
          </div>
          {partners.length > 0 && (
            <div className={styles.text_group}>
              <strong>
                {partners.length === 1
                  ? "Интеллектуальный партнер:"
                  : "Интеллектуальные партнеры:"}{" "}
              </strong>
              <span>{partners.join(", ")}</span>
            </div>
          )}
          {item.description && (
            <div className={styles.text_group}>
              <div
                className={styles.description_html}
                dangerouslySetInnerHTML={createMarkup(item.description)}
              ></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Description;
