import React, { useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useEffect } from "react";
import PhotosItem from "./PhotosItem";
import ModalVideo from '../Videos/ModalVideo';

const PhotosSlider = ({ items, containerWidth }) => {
  const [currentLink, setCurrentLink] = useState('')
  const [isShowModal, setShowModal] = useState(false);
  const [itemWidth, setItemWidth] = useState(0);

  const setSlidesToShow = () => {
    let slidesToShow = 1;

    if (window.innerWidth > 768) slidesToShow = 2;
    if (window.innerWidth > 1200) slidesToShow = 3;
    if (window.innerWidth > 1350) slidesToShow = 4;

    return slidesToShow;
  };

  useEffect(() => {
    setItemWidth(containerWidth.current.clientWidth / setSlidesToShow());
  }, [containerWidth, setSlidesToShow]);

  let settings = {
    speed: 500,
    infinite: false,
    slidesToShow: setSlidesToShow(),
    slidesToScroll: setSlidesToShow(),
    
  };

  const showModal = (link) => {
    setCurrentLink(link)
    setShowModal(true)
  }

  return (
    <>
    {isShowModal && <ModalVideo link={currentLink} close={() => setShowModal(false)} />}
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index} className="photo">
            <PhotosItem item={item} width={itemWidth - 10} onClick={showModal} />
          </div>
        ))}
      </Slider>
    </>
  );
};

export default PhotosSlider;
