import React from 'react';
import Container from '../../elements/Container/Container';
import PageTemplate from '../../PageTemplate/PageTemplate';
import { useSelector } from 'react-redux';

import styles from './RegDocs.module.scss';
import { globalTranslate } from '../../../functions/languages';

const PolicySMI = () => {
  const mention_policy = useSelector(state => state.regDocs.mention_policy)
  const mention_policy_en = useSelector(state => state.regDocs.mention_policy_en)
  const currentLang = useSelector((state) => state.lang.currentLang)

  const createMarkup = (text) => {
    return { __html: text };
  };
  return (
    <PageTemplate>
        <Container>
            <h3>{globalTranslate(currentLang, 'Политика упоминаний')}</h3>

            <div className={styles.textWrapper} dangerouslySetInnerHTML={createMarkup(currentLang === 'ru' ? mention_policy_en : mention_policy)}
            ></div>
        </Container>
    </PageTemplate>
  );
};

export default PolicySMI;