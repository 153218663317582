import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

import MainPage from "./components/pages/MainPage/MainPage";
import RegPage from "./components/pages/RegPage/RegPage";

import { getNewsMainPage } from "./functions/getNews";
import { getAlbums } from "./functions/getAlbums";
import getIndexConstance from "./functions/getIndexConstance";
import PersonalRegPage from "./components/pages/RegPage/PersonalRegPage";
import CompanyRegPage from "./components/pages/RegPage/CompanyRegPage";
import LoginPage from "./components/pages/LoginPage/LoginPage";
import InitializationRoute from "./components/InitializationRoute";
import { isAuth } from "./functions/isAuth";
import NewsPage from "./components/pages/NewsPage/NewsPage";
import VariablePage from "./components/pages/VariablePage/VariablePage";
import ProgramPage from "./components/pages/ProgramPage/ProgramPage";
import GalleryPage from "./components/pages/GalleryPage/GalleryPage";
import ContactsPage from "./components/pages/ContactsPage/ContactsPage";
import CabinetPage from "./components/pages/CabinetPage/CabinetPage";
import { getCompanyRegistrations } from './functions/getCompanyRegistrations';
import SucceessRegPage from "./components/pages/SucceessRegPage/SucceessRegPage";
import ContractPage from './components/pages/ContractPage/ContractPage';
import CabinetProfileForm from './components/elements/CabinetProfile/CabinetProfileForm';
import CabinetProfileCars from './components/elements/CabinetProfile/CabinetProfileCars';
import getCars from './functions/getCars';
import SuccessAddCar from './components/elements/CabinetProfile/SuccessAddCar';
import CabinetProfileDocs from './components/elements/CabinetProfile/CabinetProfileDocs';
import getDocuments from './functions/getDocuments';
import CabinetProfileSettings from './components/elements/CabinetProfile/CabinetProfileSettings';
import NewsSinglePage from './components/pages/NewsSinglePage/NewsSinglePage';
import Policy from "./components/pages/AnotherInfoPages/Policy";
import PaidPackadge from "./components/pages/AnotherInfoPages/PaidPackadge";
import AccreditationRules from './components/pages/AnotherInfoPages/AccreditationRules';
import PolicySMI from "./components/pages/AnotherInfoPages/PolicySMI";
import getRegDocs from './functions/getRegDocs';
import getWelcomeWords from './functions/getWelcomeWords';
import getSocials from './functions/getSocials';
import getPages from './functions/getPages';
import AllVideos from './components/pages/AllVideos/AllVideos';
import AlbumsPage from './components/pages/AlbumsPage/AlbumsPage';
import AllPhotos from './components/pages/AllPhotos/AllPhotos';
import ResetPassword from "./components/pages/ResetPassword/ResetPassword";
import ResetPasswordConfirm from './components/pages/ResetPasswordConfirm/ResetPasswordConfirm';
import WelcomeWords from "./components/pages/WelcomeWords/WelcomeWords";
import NotFound from './components/pages/NotFound/NotFound';
import PreparingProgram from './components/pages/PreparingProgram/PreparingProgram';
import getMenu from "./functions/getMenu";
import getRegistraionType from './functions/getRegistraionType';
import { languages } from './functions/languages';
import CabinetProfileHotels from "./components/elements/CabinetProfile/CabinetProfileHotels";
import CabinetProfileHotelProfile from './components/elements/CabinetProfile/CabinetProfileHotelProfile';
import Partners from './components/pages/Partners/Partners';
import MediaPartners from './components/pages/Partners/MediaPartners';
import HotelsProfile from './components/elements/Hotels/HotelsProfile';
import getInviters from './functions/getInviters';

import SearchNewsPage from "./components/pages/NewsPage/SearchNewsPage";

import StreamsPage from './components/pages/StreamsPage/StreamsPage';
import SingleStreamPage from "./components/pages/StreamsPage/SingleStreamPage";

const App = () => {
  const dispatch = useDispatch();

  const currentLangRedux = useSelector(state => state.lang.currentLang)
  const menuList = useSelector(state => state.menuList.pages)

  useEffect(() => {
    if (window.localStorage.token) {
      isAuth(dispatch);
      getCompanyRegistrations(dispatch);
    }
    dispatch(getInviters())
    dispatch(getRegistraionType())
    dispatch(getPages())
    dispatch(getWelcomeWords());
    dispatch(getSocials());
    dispatch(getRegDocs());
    // dispatch(getNewsMainPage(currentLangRedux === 'en'));
    dispatch(getAlbums());
    menuList.length < 1 && dispatch(getMenu());
    dispatch(getIndexConstance());

  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route path="/" element={<InitializationRoute /> } >
          <Route path="/cabinet/personal" element={<PersonalRegPage />} />
          <Route path="/cabinet/company" element={<CompanyRegPage />} />
          <Route path="/cabinet/my_registrations" element={<CabinetPage />} />
          <Route path="/cabinet/my_profile" element={<CabinetPage />} />
          <Route path="/cabinet/my_settings" element={<CabinetProfileSettings />} />

          <Route path="/cabinet/my_cars" element={<CabinetProfileCars />} />
          <Route path="/cabinet/my_hotels" element={<CabinetProfileHotels />} />
          <Route path="/cabinet/my_hotels/profile" element={<CabinetProfileHotelProfile />} />
          <Route path="/cabinet/my_documents" element={<CabinetProfileDocs />} />
          <Route path="/cabinet/my_registrations/:id" element={<CabinetProfileForm />} />
          <Route path="/success" element={<SucceessRegPage />} />
          <Route path="/success_add_car" element={<SuccessAddCar />} />
          <Route path="/offer" element={<ContractPage />} />  
        </Route>

        <Route path="/main" element={<MainPage />} />


        <Route path="/news/search/:page" element={<SearchNewsPage />} />

        <Route path="/live" element={<StreamsPage />} />
        <Route path="/live/:id" element={<SingleStreamPage />} />
        
        <Route path="/news/:page" element={<NewsPage />} />
        <Route path="/news/:page/:name" element={<NewsSinglePage />} />

        <Route path="/pages/:variable_link" element={<VariablePage />} />
        <Route path="/hotels_profile" element={<HotelsProfile />} />
        <Route path="/forum_program" element={<ProgramPage />} />
        <Route path="/preparing_program_for_PDF" element={<PreparingProgram />} />

        <Route path="/welcome/:id" element={<WelcomeWords />} />

        <Route path="/partners" element={<Partners />} />
        <Route path="/mediapartners" element={<MediaPartners />} />

        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/gallery/videos" element={<AllVideos />} />
        <Route path="/gallery/photos/" element={<AllPhotos />} />
        <Route path="/gallery/photos/:album" element={<AlbumsPage />} />

        <Route path="/contacts" element={<ContactsPage />} />

        <Route path="/politika-konfidencialnosti" element={<Policy />} />
        <Route path="/pakety-uchastiya-v-forume"  element={<PaidPackadge />} />
        <Route path="/smi/rules" element={<AccreditationRules />} />
        <Route path="/smi/policy" element={<PolicySMI />} />

        <Route path="/registration" element={<RegPage />} />
        <Route path="/group-registration" element={<RegPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/password/reset/confirm/:id/:token" element={<ResetPasswordConfirm />} />


      </Routes>
    </>
  );
};

export default App;
