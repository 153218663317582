import { BASE_URL_API } from '../BASE_URL.js';
import { getHotelsAction } from '../redux/Reducers/hotelsReducer.js';

const getHotels = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL_API + 'hotels/');
        const data = await response.json();

        dispatch(getHotelsAction(data));
    }
}

export default getHotels;