import React from 'react';

import styles from './AlbumsPage.module.scss';

import arrowRight from '../../../image/icons/slider-arrow.svg';
import arrowLeft from '../../../image/icons/slider-arrow-prev.svg';
import closeIcon from '../../../image/icons/close-modal.svg'
import { useState } from 'react';

import loader from '../../../image/load.gif';
import { globalTranslate } from '../../../functions/languages';
import { useSelector } from 'react-redux';

const ModalPhoto = ({ file, close, next, prev, hidePrev, hideNext }) => {
  const [isLoad, setLoad] = useState(true);
  const currentLang = useSelector(state => state.lang.currentLang);

  const clickNext = () => {
    setLoad(true)
    next()
  }
  const clickPrev = () => {
    setLoad(true)
    prev()
  }

  return (
    <div className={styles.modalWrapper}>
        <div className={styles.modalContainer}>
            {!isLoad && <img src={closeIcon} className={styles.close} onClick={close} />}
            
            {!hidePrev && !isLoad && <img className={styles.prev} src={arrowLeft} alt="" onClick={clickPrev} />}
            {isLoad && (
              <div className={styles.loaderIcon}>
                <img src={loader} />
                <span onClick={close}>{globalTranslate(currentLang, 'отменить загрузку')}</span>
              </div>
            )}
            <img src={file} alt="" onLoad={() => setLoad(false)} style={isLoad ? {opacity: 0, width: 0} : {}} />
            {!hideNext && !isLoad  && <img className={styles.next} src={arrowRight} alt="" onClick={clickNext} />}
        </div>
    </div>
  )
}

export default ModalPhoto