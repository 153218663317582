import React, { useEffect, useState } from "react";
import styles from "../CabinetProfile/CabinetProfile.module.scss";
import { useRef } from "react";
import HotelsSlider from "../HotelsSlider/HotelsSlider";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import getHotels from "../../../functions/getHotels";

import websiteIcon from "../../../image/icons/globe_blue.png";
import mailIconBlue from "../../../image/icons/mailIconBlue.svg";
import HotelsMap from "../HotelsMap/HotelsMap";
import { useNavigate } from "react-router-dom";
import CalendarInput from "../CalendarInput/CalendarInput";
import Button from "../Button/Button";
import sendHotelReservation from "../../../functions/sendHotelReservation";
import { globalTranslate } from "../../../functions/languages";

const Hotels = () => {
  const [hotelList, setHotelList] = useState();
  const [currentHotel, setCurrentHotel] = useState();
  const [succsess, setSuccess] = useState(false);
  const [textSideLeft, setTextSideLeft] = useState(false);
  const [isShowModal, setShowModal] = useState(false);
  const [arrivalDate, setArrivalDate] = useState();
  const [departuresDate, setDeparturesDate] = useState();
  const [textWidth, setTextWidth] = useState(600);
  const hotels = useSelector((state) => state.hotels.hotels);
  const user = useSelector((state) => state.user);
  const currentUser = useSelector(
    (state) => state.registrations.currentRegistration
  );
  const currentLang = useSelector((state) => state.lang.currentLang);

  const [errors, setErrors] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const containerWidth = useRef();
  const sliderRef = useRef();

  const resize = () => {
    if (window.innerWidth > 1920) {
      setTextSideLeft(true);
    } else {
      setTextSideLeft(false);
    }
  };

  const hotelItemClickHandler = (item) => {
    setCurrentHotel(item);
    if (window.innerWidth < 768) {
      if (params.variable_link !== "hotels") {
        navigate("/cabinet/my_hotels/profile", { state: { item } });
      } else if (params.variable_link === "hotels") {
        navigate("/hotels_profile", { state: { item } });
      }
    }
  };

  useEffect(() => {
    if (!succsess) return;
    setTimeout(() => {
      setArrivalDate();
      setDeparturesDate();
      setErrors();
      setShowModal(false);
      setSuccess(false);
    }, 5000);
  }, [succsess]);

  useEffect(() => {
    if (!hotels) return;
    setHotelList(hotels.results);
    setCurrentHotel(hotels.results[0]);
  }, [hotels]);

  useEffect(() => {
    resize();
  }, [containerWidth]);

  const sliderWidthLocal = document.querySelector(".sliderWrapper");

  useEffect(() => {
    if (!sliderWidthLocal) return;
    setTextWidth(sliderWidthLocal.clientWidth);
  }, [sliderWidthLocal]);

  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  useEffect(() => {
    dispatch(getHotels());
  }, [dispatch]);

  const sendReservation = () => {
    setErrors();
    const data = {
      start_date: arrivalDate,
      end_date: departuresDate,
      hotel_id: currentHotel.id,
      registration_id: currentUser[0].id,
    };
    if (arrivalDate && departuresDate && currentHotel.id && user.id) {
      sendHotelReservation(data, setErrors, setSuccess);
    }
  };

  return (
    <div className={styles.hotelsWrapper}>
      {isShowModal && (
        <div className={styles.modalWrapper}>
          <div className={styles.modal}>
            {!succsess ? (
              <>
                <p>
                  Ваша заявка будет отправлена на электронную почту отеля.
                  Сотрудники "{currentHotel.title}" свяжутся с вами по почте или
                  номеру телефона, указанным при регистрации.
                </p>
                <h4>Укажите даты бонирования</h4>
                {errors &&
                  errors.map((item, index) => (
                    <p key={index} className={styles.error}>
                      {item}
                    </p>
                  ))}
                <CalendarInput
                  labelText={"Дата заезда"}
                  onChange={setArrivalDate}
                />
                <CalendarInput
                  labelText={"Дата выезда"}
                  onChange={setDeparturesDate}
                />
                <div className={styles.button_group}>
                  <Button
                    backColor={"red"}
                    onClick={() => {
                      setArrivalDate();
                      setDeparturesDate();
                      setShowModal(false);
                    }}
                  >
                    Отменить
                  </Button>
                  <Button onClick={sendReservation}>Отправить заявку</Button>
                </div>
                <p className={styles.close} onClick={() => setShowModal(false)}>
                  close
                </p>
              </>
            ) : (
              <>
                <p>
                  Ваша заявка успешно отправлена на электронную почту отеля.
                  Сотрудники "{currentHotel.title}" свяжутся с вами по почте или
                  номеру телефона, указанным при регистрации.
                </p>
                <p>Окно закроется автоматически через 5 секунд...</p>
              </>
            )}
          </div>
        </div>
      )}
      <div className={styles.hotelsListWrapper}>
        {currentHotel && (
          <HotelsMap
            currentHotel={currentHotel}
            hotelList={hotelList}
            setCurrentHotel={setCurrentHotel}
          />
        )}
        <ul className={styles.hotelsList} id="hotelList">
          {hotelList &&
            hotelList.map((item, index) => (
              <li
                key={index}
                onClick={() => hotelItemClickHandler(item)}
                className={styles.listItem}
                id={item.id}
                style={
                  item.id === currentHotel.id
                    ? { backgroundColor: "rgba(221, 235, 248, 0.40)" }
                    : {}
                }
              >
                <p>{currentLang === "ru" ? item.title : item.title_en}</p>
                <span>
                  {currentLang === "ru" ? item.address : item.address_en}
                </span>
              </li>
            ))}
        </ul>
      </div>
      {currentHotel && window.innerWidth > 768 && (
        <div className={styles.hotelsItem} ref={containerWidth}>
          <div className={styles.hostelHead}>
            <h4>
              {currentLang === "ru"
                ? currentHotel.title
                : currentHotel.title_en}
            </h4>
            <p>
              {currentLang === "ru"
                ? currentHotel.address
                : currentHotel.address_en}
            </p>
            <div className={styles.itemContacts}>
              <div className={styles.site}>
                <img src={websiteIcon} alt="Сайт" />
                <a href={currentHotel.link}>
                  {globalTranslate(currentLang, "Веб-сайт")}
                </a>
              </div>
              <div className={styles.email}>
                {currentUser &&
                currentUser.length > 0 &&
                currentUser[0].is_reg_confirmed &&
                (currentUser[0].registration_type === "free" ||
                  currentUser[0].registration_type === "paid") ? (
                  <button onClick={() => setShowModal(true)}>
                    Забронировать отель
                  </button>
                ) : (
                  <div className={styles.email}>
                    <img src={mailIconBlue} alt="" />
                    <a href={currentHotel.email}>{currentHotel.email}</a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className={styles.slider}
              style={window.innerWidth > 1920 ? { marginRight: 30 } : {}}
              ref={sliderRef}
            >
              <HotelsSlider photos={currentHotel.photos} />
            </div>
            {textSideLeft && (
              <div className={styles.hotelInfo}>
                <h5 style={window.innerWidth > 1920 ? { marginTop: 0 } : {}}>
                  {globalTranslate(currentLang, "Описание")}
                </h5>
                <p style={{ textAlign: "justify" }}>
                  {currentLang === "ru"
                    ? currentHotel.description
                    : currentHotel.description_en}
                </p>
              </div>
            )}
          </div>
          {!textSideLeft && (
            <div className={styles.hotelInfo}>
              <h5 style={window.innerWidth > 1920 ? { marginTop: 0 } : {}}>
                {globalTranslate(currentLang, "Описание")}
              </h5>
              <p style={{ maxWidth: textWidth, textAlign: "justify" }}>
                {currentLang === "ru"
                  ? currentHotel.description
                  : currentHotel.description_en}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Hotels;
