import React, { useEffect } from "react";

import styles from "./NavMenu.module.scss";
import NavMenuItem from "./NavMenuItem";
import { useDispatch, useSelector } from "react-redux";
import { isAuth } from "../../../functions/isAuth";
import UserMiniature from "../UserMiniature/UserMiniature";

import { ReactComponent as BurgerIcon } from "../../../image/icons/burger.svg";
import { ReactComponent as CloseMenuIcon } from "../../../image/icons/close-menu.svg";
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { setNewLang } from "../../../functions/languages";
import { setNewLangAction } from "../../../redux/Reducers/langReducer";

import globe from "../../../image/icons/globe_white.png";

const NavMenuMobile = () => {
  const isAuth = useSelector((state) => state.isAuth.isAuth);
  const [currentLang, setCurrentLang] = useState();
  const [isShowMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState([
    { title: "Главная", link: "/main", order_index: 100 },
    // // Динамические страницы

    // {title: 'Новости', link: '/news/1'},
    // // {name: 'О форуме', link: '/about'},
    // // {title: 'Программа', link: '/forum_program'},
    // {title: 'Галерея', link: '/gallery'},
    // {title: 'Контакты', link: '/contacts'},
  ]);


  const { pathname } = useLocation()

  const menuListRedux = useSelector((state) => state.menuList.pages);

  const dispatch = useDispatch()

  const setNewLangClickHandler = (lang) => {
    setNewLang(lang)
    setCurrentLang(lang)
    dispatch(setNewLangAction(lang))
    setShowMenu(false)
  }

  const currentLangRedux = useSelector(state => state.lang.currentLang)

  useEffect(() => {
    setCurrentLang(currentLangRedux)
  }, [currentLangRedux])

  const isArrayHasFunc = (object) => {
    let flag = false;
    menuList.forEach((item) => {
      if (item.title === object.title) flag = true;
    });

    return !flag;
  };

  const array = [];

  if (
    menuListRedux.menu_news &&
    isArrayHasFunc({ title: "Новости", link: "/news/1" })
  )
    array.push({ title: "Новости", link: "/news/1", order_index: menuListRedux.menu_news_order_index });

    if (
    menuListRedux.menu_translations &&
    isArrayHasFunc({ title: "Трансляции", link: "/live" })
  )
    array.push({
      title: "Трансляции",
      link: "/live",
      order_index: menuListRedux.menu_translations_order_index,
    });
    //
  if (
    menuListRedux.menu_usual_partners &&
    isArrayHasFunc({ title: "Партнеры", link: "/partners" })
  )
    array.push({ title: "Партнеры", link: "/partners", order_index: menuListRedux.menu_usual_partners_order_index });

  if (
    menuListRedux.menu_media_partners &&
    isArrayHasFunc({ title: "Медиапартнеры", link: "/mediapartners" })
  )
    array.push({ title: "Медиапартнеры", link: "/mediapartners", order_index: menuListRedux.menu_media_partners_order_index });
    //
  if (
    menuListRedux.menu_program &&
    isArrayHasFunc({ title: "Архитектура", link: "/forum_program" })
  )
    array.push({ title: "Архитектура", link: "/forum_program", order_index: menuListRedux.menu_program_order_index });
  if (
    menuListRedux.menu_gallery &&
    isArrayHasFunc({ title: "Галерея", link: "/gallery" })
  )
    array.push({ title: "Галерея", link: "/gallery", order_index: menuListRedux.menu_gallery_order_index });
  if (
    menuListRedux.menu_contacts &&
    isArrayHasFunc({ title: "Контакты", link: "/contacts" })
  )
    array.push({ title: "Контакты", link: "/contacts", order_index: menuListRedux.menu_contacts_order_index });

    if (array.length > 0) {;
      const arrayToState = [].concat(menuList, array);
      arrayToState.sort((a, b) => a.order_index > b.order_index ? 1 : -1)
      setMenuList(arrayToState);
    }

  // }, [menuListRedux])

  const is_manager = useSelector((state) => state.user.is_manager);

  const date = new Date();
  const currentYear = date.getFullYear();

  const pages = useSelector((state) => state.pages.pages);
  useEffect(() => {
    if (pages && pages.length > 0 && menuList) {
      pages.reverse();
      pages.map((item) => {
        if (item.link === '') {

        } else {
          if (item.parent === null) {
            let hasItem = false;
            menuList.forEach((point) => {
              if (item.title === point.title) {
                hasItem = true;
              }
            });
            if (hasItem === false) {
              const array = menuList;
    
              array.splice(1, 0, item);
              array.sort((a, b) => a.order_index > b.order_index ? 1 : -1)
              setMenuList(array);
            }
          }
        }
      });
    }
  }, [pages]);

  useEffect(() => {
    setShowMenu(false)
  }, [pathname])
  return (
    <>
      <div className={styles.disactiveMobileMenu} id="sidebar">
        <div className={styles.burger} onClick={() => setShowMenu(!isShowMenu)}>
        
          {isShowMenu ? (
            <CloseMenuIcon width={18} height={18} />
          ) : (
            <BurgerIcon width={18} height={18} />
          )}
        </div>
      </div>
      <div
        className={styles.NavMenuMobile}
        style={
          !isShowMenu
            ? { transform: "translateX(100%)" }
            : { transform: "translateX(0)" }
        }
      >
        <ul className={styles.authList}>
          {isAuth ? (
            !is_manager ? (
              <UserMiniature
                name={"Личный кабинет"}
                url={"/cabinet/my_profile"}
              />
            ) : (
              <UserMiniature
                name={"Личный кабинет"}
                url={"/cabinet/my_registrations"}
              />
            )
          ) : (
            <NavMenuItem text={"Регистрация"} url={"/registration"} />
          )}
        </ul>
        <div className={styles.chooseLang}>
          <div className={styles.langGroup} style={{}}>
            <span style={currentLang === 'ru' ? {fontFamily: 'Gotham Pro Bold'} : {}} onClick={() => setNewLangClickHandler('ru')}>РУС</span>
            <span style={currentLang === 'en' ? {fontFamily: 'Gotham Pro Bold'} : {}} onClick={() => setNewLangClickHandler('en')}>ENG</span>
          </div>
          <img src={globe} alt="" />
        </div>
        <ul className={styles.navlist}>
          {menuList &&
            menuList.length > 0 &&
            menuList.map((item, index) => (
              <NavMenuItem
                key={index}
                childrenList={item.children && item.children.length > 0 && item.children}
                text={item.title}
                is_external_link={item.is_external_link}
                url={
                  item.id
                    ? !item.is_external_link
                      ? "/pages/" + item.link
                      : item.link
                    : item.link
                }
              />
            ))}
        </ul>
        <div className={styles.copy}>
          <span>© {currentYear} Все права защищены.</span>
        </div>
      </div>
    </>
  );
};

export default NavMenuMobile;
